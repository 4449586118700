import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {AccessoryCharacteristics} from '../../../../shared/model/accessory.model';
import {CommonModalComponent} from '../../../../shared/common-modal/common-modal.component';
import {TranslationService} from '../../../shared';
import {TranslateService} from '@ngx-translate/core';
import {SessionStorage} from 'ngx-webstorage';
import {User} from '../../../../shared/user/user';
import {Range} from '../../../../shared/model/range-model';
import {OdmAddModalComponent} from '../../shared/odm-add-modal/odm-add-modal.component';
import {CharacteristicValue} from '../../../../characteristics/shared/characteristics.model';
import {RangeType} from '../../../../shared/model/range-type';
import {AccessoriesService} from "../../../../shared/services/accessories.service";

export type EditAccessoryValueForm = {
  accessoryId: string;
  value: {
    [key: string]: string;
  };
  bslReference?: string;
  valueId?: string;
  rangeId?: string;
}

@Component({
  selector: 'app-odm-edit-accessory-value-modal',
  templateUrl: './odm-edit-accessory-value-modal.component.html',
  styleUrls: ['./odm-edit-accessory-value-modal.component.less']
})
export class OdmEditAccessoryValueModalComponent {

  @Output()
  saveAccessoryValue: EventEmitter<EditAccessoryValueForm> = new EventEmitter<EditAccessoryValueForm>();

  @ViewChild('editAccessoryValueModal', {static: false}) editAccessoryValueModal: CommonModalComponent;
  @ViewChild('addReferenceModal', {static: false}) addReferenceModal: OdmAddModalComponent;

  @SessionStorage()
  public user: User;

  selectedRange: Range;
  availableAccessories: any[];
  editAccessoryValueForm: EditAccessoryValueForm = {accessoryId: null, value: {}};
  allowedReferenceCategoriesForAccessories: string[];
  isLoading: boolean = false;

  constructor(private translationService: TranslationService,
              private translateService: TranslateService,
              private accessoriesService: AccessoriesService) {
  }

  showAddMode(availableAccessories: AccessoryCharacteristics[], selectedRange: Range) {
    this.editAccessoryValueForm = {accessoryId: null, value: {}};
    this.selectedRange = selectedRange;
    this.editAccessoryValueForm.rangeId = this.selectedRange.id;

    this.availableAccessories = this.selectedRange.rangeType === RangeType.BUDJETARY_QUOTE
      ? availableAccessories.filter(accessory => !accessory.importedAccessory)
      : availableAccessories;

    this.availableAccessories = this.availableAccessories
      .map(accessory => ({...accessory,
        nameTranslated: this.translateService.instant(accessory.name)
      })).sort((a, b) => a.nameTranslated.localeCompare(b.nameTranslated));

    this.translationService.getAllLanguagesByCountry(this.user.partnerCountry).subscribe(languages => {
      languages.forEach(lang => {
        this.editAccessoryValueForm.value[lang] = '';
      })
    });
    this.setAllowedCategories(selectedRange);

    this.editAccessoryValueModal.show();
  }

  showEditMode(selectedRange: Range, accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue) {
    this.editAccessoryValueForm = {accessoryId: accessory.id, value: {}, valueId: accessoryValue.id.toString()};
    this.selectedRange = selectedRange;

    if (accessoryValue.reference) {
      this.editAccessoryValueForm.bslReference = accessoryValue.reference.ref;
    }

    this.translationService.getLocalesOfCountryOfKey(accessoryValue.value.toString()).subscribe(locale => {
      locale.forEach(loc => {
        this.editAccessoryValueForm.value[loc.language] = loc.translations[0].translation;
      })
    });

    this.setAllowedCategories(selectedRange);

    this.editAccessoryValueModal.show();
  }

  hide() {
    this.editAccessoryValueModal.hide();
  }

  isValidForm(): boolean {
    if (!this.editAccessoryValueForm.accessoryId) {
      return false;
    }

    for (let key in this.editAccessoryValueForm.value) {
      if (!this.editAccessoryValueForm.value[key]) {
        return false;
      }
    }

    return true;
  }

  clickOnSaveAccessoryValue() {
    this.saveAccessoryValue.emit(this.editAccessoryValueForm);
  }

  clickAddReferenceButton() {
    this.addReferenceModal.show();
  }

  addReference(event: any) {
    this.editAccessoryValueForm.bslReference = event.bslReference.reference;
    this.isLoading = true;
    if (this.selectedRange.rangeType !== RangeType.BUDJETARY_QUOTE) {
      this.accessoriesService.getAccessoryValueTranslations(this.selectedRange.id, event.bslReference.reference).subscribe(payload => {
        this.editAccessoryValueForm.value = payload.translations;
        this.isLoading = false;
      })
    }
    else {
      this.isLoading = false;
    }
  }

  clickDeleteReferenceButton() {
    this.editAccessoryValueForm.bslReference = null;
    this.isLoading = true;
    this.translationService.getAllLanguagesByCountry(this.user.partnerCountry).subscribe(languages => {
      languages.forEach(lang => {
        this.editAccessoryValueForm.value[lang] = '';
        this.isLoading = false;
      })
    });
  }

  // function for the ngfor + ngmodel, so it doesn't lose focus
  trackByFn(index: number): number {
    return index;
  }

  private setAllowedCategories(selectedRange: Range) {
    if (selectedRange.rangeType === RangeType.FAKE) {
      this.allowedReferenceCategoriesForAccessories = ['Cubicle', 'Switchboard', 'Transformers', 'Accessory', 'Accessories', 'Kit'];
    } else {
      this.allowedReferenceCategoriesForAccessories = ['Accessory', 'Accessories', 'Kit']
    }
  }

}
