<!-- Application splash screen -->

<app-splash-screen *ngIf="isLoading"></app-splash-screen>

<div class="div-app" *ngIf="!isLoading">
  <app-header></app-header>
  <div *ngIf="!isOnAdministrationScreen">
    <app-navigation-bar></app-navigation-bar>

    <section class="app-main"
             [class.max-height-bar]="(!currentItemct && noItemNavigationStep > 1) || (currentItemct && currentItemct?.currentNavigationStep > 1)"
             [class.max-height-nobar]="((!currentItemct && noItemNavigationStep < 2) || (currentItemct?.currentNavigationStep === 1))">
      <div id="main-div" class="relative">
        <router-outlet></router-outlet>
      </div>
    </section>
  </div>
  <div *ngIf="isOnAdministrationScreen">
    <app-navigation-bar-v2 [items]="adminNavItems"></app-navigation-bar-v2>
    <router-outlet></router-outlet>
  </div>
</div>

<p-toast position="bottom-right"></p-toast>

<app-feedback *ngIf="!isLoading"></app-feedback>

<!-- terms of use dialog -->
<app-terms-of-use-modal #childModal [name]="'childModal'" (onClickAcceptButton)="acceptTermsOfUse()" (onClickRefusedButton)="refuseTermsOfUse()">
</app-terms-of-use-modal>


