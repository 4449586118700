import {Component, Input, OnInit} from '@angular/core';
import {SessionStorage} from 'ngx-webstorage';
import {NavigationStep} from '../../shared/guards/navigationStep-enum';
import {LoggerService} from '../../shared/logging/logger.service';
import {User} from '../../shared/user/user';
import {Item, Project, Status} from '../../project/shared/project-model';
import {TreeNode} from 'primeng/api';
import {BomHelper, BomService} from '../../bom/shared';
import {UtilService} from '../../shared/util/util.service';
import {BomTreeDataHelper} from '../../bom/shared/bom-tree-data-helper.service';
import {TranslateService} from '@ngx-translate/core';
import {ItemService} from '../../project/shared/item.service';
import * as moment from 'moment';
import {RightsService} from '../../shared/rights/rights.service';
import {ProjectService} from '../../project/shared/project.service';
import {ReferenceBom} from '../../bom/shared/bom-model';
import {Range, RangeStatus} from '../../shared/model/range-model';
import {Subject} from 'rxjs/Rx';
import {OfferService} from '../../offers/shared/offer.service';
import {Role} from '../../core/access-control/enum';
import {ViewCartBomEventDatalayerService} from '../../shared/google-analytics/view-cart-bom-event-datalayer.service';

@Component({
  selector: 'app-project-bom-page',
  templateUrl: './project-bom-page.component.html',
  styleUrls: ['./project-bom-page.component.less']
})
export class ProjectBomPageComponent implements OnInit {

  @SessionStorage()
  user: User;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  noItemNavigationStep;

  @SessionStorage()
  localization;

  @SessionStorage()
  isOnAdministrationScreen = false;

  @SessionStorage()
  isDemoMode: boolean;

  @SessionStorage()
  hasRegister: boolean;

  @SessionStorage()
  maxDeliveryTime;

  loadingBom = false;

  expandedNodes: string[] = [];

  cols: Array<any>;

  configuredQuotedTreeData: TreeNode[] = [];

  orderedTreeData: TreeNode[] = [];

  configuredQuotedStatusList: Status [] = [Status.configured, Status.quoted];
  configuredQuotedItemList: Item[];
  orderedStatusList: Status [] = [Status.ordered];
  orderedItemList: Item[];
  configuredQuotedTableTitle: string;
  orderedTableTitle: string;
  bomString: string = null;
  status = Status;
  ranges: Range[];

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private logger: LoggerService,
              private translateService: TranslateService,
              private itemService: ItemService,
              private rightsService: RightsService,
              private bomService: BomService,
              private bomHelper: BomHelper,
              private utilService: UtilService,
              private bomTreeDataHelper: BomTreeDataHelper,
              private projectService: ProjectService,
              private offerService: OfferService,
              private viewCartBomEventDatalayerService: ViewCartBomEventDatalayerService) {
  }

  private static createTreeNode(configuration, quotationDate, discount): TreeNode {
    return {
      data: {configuration: configuration, quotationDate: quotationDate, discount: discount},
      children: []
    };
  }

  ngOnInit() {
    this.logger.debug('Project Bom Page Component init()');
    this.loadingBom = true;

    this.offerService.getRangesBackOffice()
      .takeUntil(this.unsubscribe$)
      .subscribe(ranges => {
        this.ranges = ranges;

        this.maxDeliveryTime = 0;
        this.noItemNavigationStep = NavigationStep.PROJECT_BILL_OF_MATERIALS;

        this.itemService.getItems(this.currentProject.id).subscribe(items => {
          this.getProjectBOMColumns(items);
          this.getBomTreeDataFromProject(items);
        });
      });
  }

  /**
   *
   * @param itemList
   */

  getBomTreeDataFromProject(itemList: Item[]) {
    this.loadingBom = true;
    this.configuredQuotedItemList = itemList.filter(item =>
      this.configuredQuotedStatusList.includes(item.status) && (!!item.packageOfferId || item.range.status !== RangeStatus.DECOMMISSIONED));
    this.orderedItemList = itemList.filter(item => this.orderedStatusList.includes(item.status) && (!!item.packageOfferId || item.range.status !== RangeStatus.DECOMMISSIONED));
    if (this.configuredQuotedItemList !== null && this.configuredQuotedItemList.length > 0) {
      this.configuredQuotedTreeData = this.getBomTreeDataFromItem(this.configuredQuotedItemList);
      this.configuredQuotedTableTitle = this.translateService.instant('T_BOM_CONFIGURED_QUOTED_CONFIGURATIONS');
    }
    if (this.orderedItemList !== null && this.orderedItemList.length > 0) {
      this.orderedTreeData = this.getBomTreeDataFromItem(this.orderedItemList);
      this.orderedTableTitle = this.translateService.instant('T_BOM_ORDERED_CONFIGURATIONS');
    }
    this.loadingBom = false;
    this.logProjectBomInfo(itemList);
    this.projectService.getProject(this.currentProject.id).subscribe((updatedProject: Project) => {
      this.currentProject = updatedProject;
    });
  }

  /**
   * Return true if the user is a mySE user
   * @returns {boolean}
   */
  isMySEUser(): boolean {
    return !!this.user.currentMySEAccount;
  }

  /**
   * Return true if the project contains configured or quoted item
   */
  isProjectHasConfiguredQuotedItem(): boolean {
    return this.currentProject?.itemCT?.length > 0 &&
      this.currentProject.itemCT.some(item => this.configuredQuotedStatusList.includes(item.status));
  }

  /**
   * Return true if the project contains ordered item
   */
  isProjectHasOrderedItem(): boolean {
    return this.currentProject?.itemCT && this.currentProject.itemCT.length > 0 &&
      this.currentProject.itemCT.some(item => this.orderedStatusList.includes(item.status));
  }

  /**
   * Return true if the user is for a demo
   */
  isDemoUser(): boolean {
    return this.rightsService.isActivatedDemo();
  }

  /**
   * Return true if the user is a contractor
   */
  isContractor(): boolean {
    return this.rightsService.isContractor();
  }

  /**
   * Check if the project is for read-only
   */
  isReadOnlyProject(): boolean {
    return this.projectService.isReadOnlyProject(this.currentProject, this.user);
  }

  isCpqProject(): boolean {
    return this.projectService.isCpqProject(this.currentProject);
  }

  isCpqUser(): boolean {
    return this.user.role == Role.CPQ_USER;
  }

  bomContainsNullPublicPrices(){
    let bomContainsNullPrices = false;
    if(this.isProjectHasConfiguredQuotedItem()){
      this.configuredQuotedTreeData.forEach(treeNode => {
        treeNode.children.forEach(childTreeNode => {
          if(childTreeNode.data.totalPrice == 'containsNullPrices'){
            bomContainsNullPrices = true;
          }
        })
      })
    }
    if(this.isProjectHasOrderedItem()){
      this.orderedTreeData.forEach(treeNode => {
        treeNode.children.forEach(childTreeNode => {
          if(childTreeNode.data.totalPrice == 'containsNullPrices'){
            bomContainsNullPrices = true;
          }
        })
      })
    }
    return bomContainsNullPrices;
  }
  bomContainsNullNetPrices(){
    let bomContainsNullPrices = false;
    if(this.isProjectHasConfiguredQuotedItem()){
      this.configuredQuotedTreeData.forEach(treeNode => {
        treeNode.children.forEach(childTreeNode => {
          if(childTreeNode.data.totalNetPrice == null){
            bomContainsNullPrices = true;
          }
        })
      })
    }
    if(this.isProjectHasOrderedItem()){
      this.orderedTreeData.forEach(treeNode => {
        treeNode.children.forEach(childTreeNode => {
          if(childTreeNode.data.totalNetPrice == null){
            bomContainsNullPrices = true;
          }
        })
      })
    }
    return bomContainsNullPrices;
  }

  /**
   *
   * @param itemList
   * @private
   */
  private getBomTreeDataFromItem(itemList: Item[]): TreeNode[] {
    let treeData: TreeNode[] = [];
    itemList.forEach(item => {
      const myTreeData: TreeNode[] = this.bomTreeDataHelper.extractReferencesToTree(item, this.ranges);

      const rangeNode: TreeNode = ProjectBomPageComponent.createTreeNode(item.name, this.getQuotationDate(item), this.getDiscount(item));

      myTreeData.forEach(td => rangeNode.children.push(td));

      const myNewTreeData: TreeNode[] = [];
      myNewTreeData.push(rangeNode);

      myNewTreeData.forEach(value => {
        // restore expanded state
        value.expanded = true;

        this.bomHelper.formatTreeNode(value, this.isDemoMode);
        // Remove useless information
        value.children.forEach(child => child.data.netPrice = null);
      });
      treeData = treeData.concat(myNewTreeData);
    });
    return treeData;
  }

  /**
   * Get the project Bom columns
   */
  private getProjectBOMColumns(items: Item[]) {
    this.cols = this.bomHelper.getBOMColumns(this.isDemoMode,
      this.projectService.isCpqProject(this.currentProject),
      this.rightsService.canApplyDiscount(),
      this.isMySEUser(),
      this.someFoNetPricesValid(items),
      BomService.displayAvailability(items));
    this.cols = this.cols.filter(col => col.header !== 'T_BOM_VALUE');
    this.cols.unshift({field: 'configuration', header: 'T_BOM_CONFIGURATION', className: 'text-left'});
  }

  public someFoNetPricesValid(items: Item[]) {
    return items.some(item => item?.totalPrice.validFoNetPrice);
  }

  /**
   * Get the quotation date for item
   * @param item
   */
  private getQuotationDate(item: Item): string {
    // Set locale date format
    moment.locale(this.user.preferredLanguage);
    const localeDate = moment(item.date).format('LL');
    // Validity date
    const futureDate = moment(item.dateValidityQuotation);
    const validityDate = futureDate.format('LL');
    // Quotation date
    if (localeDate && validityDate && Status.quoted === item.status) {
      if (item.dateValidityQuotation > new Date().getTime()) {
        return this.translateService.instant('T_BOM_QUOTATION_DATE') + ': ' + localeDate + ', '
          + this.translateService.instant('T_BOM_QUOTATION_VALID') + ' ' + validityDate;
      } else {
        return this.translateService.instant('T_BOM_QUOTATION_EXPIRED') + ' ' + validityDate;
      }
    } else if (Status.configured === item.status) {
      return this.translateService.instant('T_BOM_NO_QUOTATION_ESTABLISHED');
    } else {
      return '';
    }
  }

  /**
   * Get the discount label for item
   * @param item
   */
  private getDiscount(item: Item): string {
    if (item.discount) {
      return this.translateService.instant('T_PROJECT_BOM_DISCOUNT_LABEL', {discount: item.discount});
    } else {
      return '';
    }
  }



  /**
   * Get project Bom infos
   * @param itemsProject
   */
  private logProjectBomInfo(itemsProject: Item []) {
    setTimeout(() => {
      let refsArray: ReferenceBom[] = [];
      itemsProject.forEach(item => {
        const refArray: ReferenceBom[] = this.bomService.extractReferencesToArray(item);
        refsArray = refsArray.concat(refArray);
      });
      const refDtoArray = refsArray.map(
        (referenceBom: ReferenceBom) =>
          'REFERENCE :' + referenceBom.ref + ' REFERENCE_DESCRIPTION : ' + referenceBom.description + ' QUANTITY ' + referenceBom.count);
      this.bomString = refDtoArray.toString();

      let totalPrice = 0;
      itemsProject.forEach(item => {
        totalPrice += UtilService.applyDiscountPrice(item.bom.totalPrice, item.discount);
      });
      this.viewCartBomEventDatalayerService.viewProjectBomEvent(totalPrice, this.currentProject, this.localization.currency, itemsProject, refsArray);
      this.logger.business('Finalise configuration',
        {
          total_price: UtilService.formatPrice(totalPrice),
          currency: this.localization.currency,
          bom_references: this.bomString
        });

    }, 1000);
  }
}
