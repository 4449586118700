<div class="container-fluid section-margin">
  <div class="row">
    <div class="col-xs-8 col-sm-offset-1 col-sm-5">
      <h2 translate>T_DOC_PAGE_TITLE</h2>
      <p *ngIf="isProjectEmpty()" translate>T_PROJECT_DOC_NO_ITEM</p>
      <p *ngIf="!isProjectEmpty()" translate>T_DOC_PAGE_DESCRIPTION</p>
    </div>
    <div class="col-xs-2 col-sm-2 text-right">

      <button *ngIf="showDocInfoModalButton()" class="btn btn-default-gray-background btn-name"
              (click)="showDocInfoModal()" translate>
        T_DOCUMENTS_INFORMATIONS
      </button>
    </div>
  </div>

  <div *ngIf="!isProjectEmpty()">

    <!-- Commercial and technical documents -->
    <app-documentation-list *ngIf="!isActivatedDemoMode() && !isCpqProject()"
                            [title]="'T_DOC_COMMERCIAL'" [documentations]="isProjectDocumentation?dynamicProjectDocumentation:dynamicDocumentation"
                            [documentChosenLanguage]="documentChosenLanguage">
    </app-documentation-list>

    <app-documentation-list *ngIf="!isActivatedDemoMode() && !isCpqProject()" [title]="'T_BOM_DOCUMENTATION_QUOTE'" [documentations]="quoteDocumentation"
                            [documentChosenLanguage]="documentChosenLanguage">
    </app-documentation-list>

    <app-documentation-list [title]="'T_DOC_PLANS'" [documentations]="staticPlans"
                            [documentChosenLanguage]="documentChosenLanguage">
    </app-documentation-list>

    <app-documentation-list [title]="'T_DOC_DATASHEETS'" [documentations]="staticDatasheets"
                            [documentChosenLanguage]="documentChosenLanguage">
    </app-documentation-list>

    <app-documentation-list [title]="'T_DOC_TECHNICAL_PUBLICATIONS'" [documentations]="technicalPublications"
                            [documentChosenLanguage]="documentChosenLanguage">
    </app-documentation-list>

    <app-documentation-list [title]="'T_DOC_USER_GUIDE'" [documentations]="staticManualDocuments"
                            [documentChosenLanguage]="">
    </app-documentation-list>

    <app-documentation-list [title]="'T_DOC_CAD_FILES'" [documentations]="cadFiles"
                            [documentChosenLanguage]="NO_LANGAGE">
    </app-documentation-list>
  </div>

  <app-documentation-list [title]="'T_DOC_ENVIRONMENT'" [documentations]="environment"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-documentation-list>

  <app-documentation-list [title]="'T_DOC_CATALOGS'" [documentations]="catalogs"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-documentation-list>

  <app-documentation-list [title]="'T_DOC_PROMOTIONAL_MATERIALS'" [documentations]="promotionMaterials"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-documentation-list>

  <app-documentation-list [title]="'T_DOC_APPLICATIONS_SOLUTIONS'" [documentations]="applicationSolutions"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-documentation-list>

  <app-documentation-list [title]="'T_DOC_WHITE_PAPERS'" [documentations]="whitePapers"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-documentation-list>

  <app-documentation-list [title]="'T_DOC_CERTIFICATES'" [documentations]="certificates"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-documentation-list>

  <app-documentation-list [title]="'T_DOC_SPECIFICATIONS'" [documentations]="specifications"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-documentation-list>

  <app-documentation-list [title]="'T_DOC_TRAINING'" [documentations]="training"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-documentation-list>

  <app-spinner *ngIf="isLoading" [sizeClass]="'large'"></app-spinner>
</div>
<!-- document informations modal -->
<app-common-modal #docInfoModal [name]="'docInfo'" [withFooter]="false"
                  [title]="'T_DOCUMENTS_INFORMATIONS' | translate" size="modal-sm">
  <div modal-body class="form-horizontal">
    <app-documentation-information-form (onClickSkipButton)="docInfoModal.hide()"
                                        (onClickValidateButton)="docInfoModal.hide()"
                                        [skippable]="true"
                                        [description]="'T_DOCUMENTATION_MODAL_DESCRIPTION' | translate">
    </app-documentation-information-form>
  </div>
</app-common-modal>
