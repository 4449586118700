import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Item, Project, Status} from '../../../project/shared/project-model';
import {SwitchBoardComponent} from '../../shared/model/component';
import {SessionStorage} from 'ngx-webstorage';
import {CommonModalComponent} from '../../../shared/common-modal/common-modal.component';
import {LoggerService} from '../../../shared/logging/logger.service';
import {ItemService} from '../../../project/shared/item.service';
import {OptionsPanelService} from '../../shared/services/options-panel.service';
import {PanelOptionWrapper} from '../../shared/model/panelOptionWrapper';
import {Opt, OptValue} from '../../shared/model/IProduct';
import {ComponentType} from '../../shared/model/component-type';
import {ConfigurationHelperService} from 'app/configuration/shared/services/configuration.helper.service';
import {ProjectService} from '../../../project/shared/project.service';
import {NavigationRoute} from '../../../shared/guards/route-enum';
import {Subject} from 'rxjs/Rx';
import {option} from "@se/web-ui/types/components/checkbox/constants";


@Component({
  selector: 'app-sld-options-component',
  templateUrl: './sld-options-component.component.html',
  styleUrls: ['./sld-options-component.component.less']
})
export class SldOptionsComponentComponent implements OnInit, OnDestroy, OnChanges {

  @SessionStorage()
  user;
  @SessionStorage()
  currentItemct: Item;
  @SessionStorage()
  currentProject: Project;
  savedValue: OptValue;
  savedOption: Opt;
  isAllowedToChange = false;
  panelOptionWrapper: PanelOptionWrapper;
  @Input() selectedComponent: SwitchBoardComponent;

  @Output() deleteComponent = new EventEmitter<PanelOptionWrapper>();
  @Output() moveCubicleEvent = new EventEmitter<{panelOptionWrapper: PanelOptionWrapper, direction: 'left' | 'right'}>();

  displayedOptions: Opt[];
  unsubscribe$: Subject<void> = new Subject<void>();

  @ViewChild('changeDataModel') confirmChangeDataModal: CommonModalComponent;

  constructor(private logger: LoggerService,
              private optionPanelService: OptionsPanelService,
              private itemService: ItemService,
              private projectService: ProjectService,
              private optionsPanelService: OptionsPanelService,
              private configurationHelperService: ConfigurationHelperService) {
  }

  ngOnInit() {
    if (this.currentItemct.status !== Status.ordered &&
      !this.projectService.isReadOnlyProject(this.currentProject, this.user)) {
      this.isAllowedToChange = true;
    }
    this.optionsPanelService.getSelectedComponentInfos()
      .takeUntil(this.unsubscribe$)
      .subscribe(
      infos => { this.panelOptionWrapper = infos; });


  }

  ngOnChanges(): void {
    if (this.selectedComponent) {
      this.displayedOptions = this.configurationHelperService.setVisibilityForDependentOptions(this.selectedComponent.options, this.selectedComponent);
      this.displayedOptions.sort((a, b) => {
        return a.orderIndex - b.orderIndex;
      });
    }
  }

  closeParameters() {
    this.optionPanelService.setSelectedComponent(null);
    this.optionPanelService.updateSelectedComponentInfos(new PanelOptionWrapper(null, null, null));
  }

  /**
   * Just for the onChange from select element
   * Call the modal if need to be reinitialised
   * @param value
   * @param option
   */
  async onChangeCubicleOption(value, option) {
    if (this.currentItemct.status === Status.configured) {
      this.changeCubicleOption(value, option);
    } else {
      this.savedValue = value;
      this.savedOption = option;
      this.confirmChangeDataModal.show();
    }
  }

  /**
   * Change cubicle option method
   */
  async changeCubicleOption(value, option) {
    // manage the dependencies between options
    if (value && option && option.onPopUp) {
      this.configurationHelperService.manageDependentOptionsVisibility(value, option, this.selectedComponent);
    }
    // set selected value
    this.selectedComponent.options.forEach(
      opt => {
        if (opt.name === option.name) {
          opt.values.forEach(val => {
            val.selected = val.value === value;
          });
        }
      });
    this.updateCubicleOption();
  }

  async onChangeToggleOption(isToAdd, option) {
    if ((isToAdd && option.values[0].reference) || (!isToAdd && !option.values[0].reference)) {
      option.values[0].selected = true;
      option.values[1].selected = false;
    } else {
      option.values[0].selected = false;
      option.values[1].selected = true;
    }
    const index = this.selectedComponent.options.findIndex(
      opt => opt.name === option.name);
    if (index !== -1) {
      this.selectedComponent.options.splice(index, 1, option);
    }
    this.updateCubicleOption();
  }

  async updateCubicleOption() {
    if (this.panelOptionWrapper.type === ComponentType.CUBICLE) {
      this.currentItemct.components[this.panelOptionWrapper.componentPosition] = this.selectedComponent;
    } else {
      this.currentItemct.components[this.panelOptionWrapper.componentPosition].functionalUnits
        [this.panelOptionWrapper.functionPosition].transformer = this.selectedComponent;
    }

    await new Promise(resolve =>
      this.itemService.updateItemWithStatusChange(this.currentItemct, Status.configured, this.user, true)
        .takeUntil(this.unsubscribe$)
        .subscribe(updatedItem => {
            updatedItem.selectedComponentIndex = this.currentItemct.selectedComponentIndex;
            this.currentItemct = updatedItem;
          }
        ));
    this.itemService.updateTotalPriceAndDimensions(this.unsubscribe$);
  }

  confirmLooseData() {
    this.currentItemct.maxNavigationStep = NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).id;
    this.currentItemct.status = Status.configured;
    this.logger.logStatus(this.currentItemct);
    this.changeCubicleOption(this.savedValue, this.savedOption);
  }

  public moveCubicle(direction: 'left' | 'right') {
    this.moveCubicleEvent.emit({panelOptionWrapper: this.panelOptionWrapper, direction});
  }

  public fireDeleteModal() {
    this.deleteComponent.emit(this.panelOptionWrapper);
  }

  public showDeleteIcon(): boolean {
    return this.currentItemct.status !== Status.ordered && this.currentItemct.components.length !== 0 &&
      !this.projectService.isReadOnlyProject(this.currentProject, this.user);
  }

  noOptionsSelected(option) {
    return (option.values.findIndex(opt => opt.selected === true) === -1 || option.defaultValue === undefined);
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.unsubscribe$.next();
    // unsubscribe from the subject itself:
    this.unsubscribe$.unsubscribe();

  }

  /**
   * Function to intercept key press to manage deletion of cubicle and transformer by keyboard
   * @param {KeyboardEvent} event (containing the key pressed)
   */
  @HostListener('document:keydown', ['$event'])
  private handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Delete' && this.showDeleteIcon()) {
      this.fireDeleteModal();
    }
  }

}
