import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectIdmsComponent } from './connect-idms/connect-idms.component';
import {EcorealCtSharedModule} from "../shared/shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {ConnectCpqComponent} from "./connect-cpq/connect-cpq.component";
import {ProgressbarModule} from "ngx-bootstrap";

@NgModule({
  declarations: [
    ConnectIdmsComponent,
    ConnectCpqComponent
  ],
    imports: [
        CommonModule,
        EcorealCtSharedModule,
        TranslateModule,
        ProgressbarModule
    ]
})
export class LoginModule { }
