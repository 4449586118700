import {Reference} from './reference';
import {Documentation} from '../../../shared/documentation/documentation.model';
import {Incompatibility} from './component';

/**
 * Generic class to represent the products of Ecoreal Express
 * This serve as an interface and does not have to be used directly
 * Implemented by :
 *  - SwitchboardComponent
 */
export class IProduct {
  id: string;
  name: string;
  productId: string;
  characteristics: [Characteristic];
  dimensions: Dimension;
  options: [Opt];
  reference: Reference;
  documents: Array<Documentation>;
  quantity: number;
  quantityId: string;

  constructor(id: string, name: string, productId: string, characteristics: [Characteristic], dimensions: Dimension, options: [Opt],
              reference: Reference, documents: Array<Documentation>, quantity: number, quantityId: string) {
    this.id = id;
    this.name = name;
    this.productId = productId;
    this.characteristics = characteristics;
    this.dimensions = dimensions;
    this.options = options;
    this.reference = reference;
    this.documents = documents;
    this.quantity = quantity;
    this.quantityId = quantityId;
  }
}

export class Characteristic {
  key: string;
  name: string;
  value: string | number | string[];
  category: string;
  measurementUnit: string;
}

export class Opt {
  id?: string;
  name: string;
  description: string;
  category?: string;
  values: Array<OptValue>;
  htmlElementType?: string;
  defaultValue: number;
  onPopUp: boolean;
  incompatibilities: Array<Incompatibility>;
  visible = true;
  orderIndex:number;
}

export class OptValue {
  value: string;
  selected: boolean;
  reference: Reference;
  referenceBom?;
  quantity?: number;
}

export class Dimension {
  height: number;
  depth: number;
  width: number;
  weight: number;
  layout: [Layout];
}

export class Layout {
  name: string;
  url: string;
}
