import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BslRangeCharacteristicsSummary,
  BslRangeCharacteristicsSummaryMap
} from '../../../../shared/bsl-reference/offer-data-management-model';
import {Range} from '../../../../../shared/model/range-model';
import * as _ from 'lodash';
import {OfferDataManagementHelper} from '../../../../shared/util/offer-data-management-helper';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {RangeType} from "../../../../../shared/model/range-type";

@Component({
  selector: 'app-odm-add-offer-filters',
  templateUrl: './odm-add-offer-filters.component.html',
  styleUrls: ['./odm-add-offer-filters.component.less']
})
export class OdmAddOfferFiltersComponent implements OnInit {

  @Input()
  bslCharacteristicsMap: BslRangeCharacteristicsSummaryMap;

  @Input()
  selectedRange: Range;

  @Output()
  nextActiveChange: EventEmitter<void> = new EventEmitter<void>();

  selectedCharacteristics: BslRangeCharacteristicsSummary[];

  electricalCharacteristicIds: string[];

  constructor() { }

  ngOnInit() {
    this.bslCharacteristicsMap = OfferDataManagementHelper.initBslCharacteristicsMap(this.bslCharacteristicsMap, this.selectedRange.visibleComponentsFilters);
    if (this.selectedRange.visibleComponentsFilters) {
      this.selectedCharacteristics = this.getFilterCharacteristics(this.selectedRange.visibleComponentsFilters);
    }
    if (this.selectedRange.electricalCharacteristicsFilters) {
      this.electricalCharacteristicIds = this.selectedRange.electricalCharacteristicsFilters
        .map(characKey => this.selectedRange.rangeType === RangeType.BUDJETARY_QUOTE ? characKey : characKey.substring(this.selectedRange.nameKey.length + 1));
    }
  }

  filterCharacteristics(characteristics) {
    this.updateCharacteristics(characteristics);
    if (this.selectedCharacteristics) {
      this.selectedRange.visibleComponentsFilters = this.selectedCharacteristics.map(sc =>
        this.selectedRange.rangeType === RangeType.BUDJETARY_QUOTE ? sc.key : this.selectedRange.nameKey + '_' + sc.key);
      this.nextActiveChange.emit();
    }
  }

  /**
   * Method to reorder a list when a user drops an item
   * @param event
   */
  drop(event: CdkDragDrop<BslRangeCharacteristicsSummary[]>) {
    moveItemInArray(this.selectedCharacteristics, event.previousIndex, event.currentIndex);
    this.selectedRange.visibleComponentsFilters = this.selectedCharacteristics.map(sc =>
      this.selectedRange.rangeType === RangeType.BUDJETARY_QUOTE ? sc.key : this.selectedRange.nameKey + '_' + sc.key);
  }

  private getFilterCharacteristics(filters: string[]): BslRangeCharacteristicsSummary[] {
    const characteristics = [];
    filters.forEach(filter => {
      const bslRangeCharacteristicsSummary = _.find(
        _.values(this.bslCharacteristicsMap),
        {
          key: this.selectedRange.rangeType === RangeType.BUDJETARY_QUOTE ? filter
            : filter.substring(this.selectedRange.nameKey.length + 1)
        }
      );
      if (bslRangeCharacteristicsSummary) {
        bslRangeCharacteristicsSummary.checked = true;
        characteristics.push(bslRangeCharacteristicsSummary);
      }
    });
    return characteristics;
  }

  /**
   * Update selected characteristics regarding new characteristics (keep old order)
   * @param characteristics
   */
  private updateCharacteristics(characteristics: BslRangeCharacteristicsSummary[]) {
    // Firs, we remove old characteristics
    this.selectedCharacteristics = this.selectedCharacteristics.filter(characteristic => this.isSelected(characteristic, characteristics));
    // Then we add at the end of the array, a new characteristic
    characteristics.filter(characteristic => characteristic.checked &&
      !this.isSelected(characteristic, this.selectedCharacteristics)).forEach(
      characteristic => this.selectedCharacteristics.push(characteristic)
    );
  }

  /**
   * Return true if characteristic is present and checked in characteristics' array
   * @param characteristic
   * @param characteristics
   */
  private isSelected(characteristic: BslRangeCharacteristicsSummary, characteristics: BslRangeCharacteristicsSummary[]): boolean {
    return characteristics.filter(charac => (charac.key === characteristic.key && charac.checked)).length > 0;
  }
}
