import {CharacteristicValue} from '../../characteristics/shared/characteristics.model';
import {Pair} from '../util/pair';

export class AccessoryCharacteristics {
  id: string;
  name: string;
  description: string;
  defaultValue: string;
  values: CharacteristicValue[];
  category: string;
  htmlElementType: 'select'|'multiSelect';
  required: boolean;
  information: string;
  orderIndex: number;
  importedAccessory: boolean;
}

export class AccessoryCategory {
  id: string;
  nameKey: string;
  rangeId: string;
  order: number;
}

export class EditAccessoryCategory {
  nameKey: string;
  translationByLanguage : Pair<string, string>[];
}
