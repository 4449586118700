export const environment = {
  production: true,
  threshold: 'info',
  feedbackCollector: false,
  frontendVersion: '@frontend.version@',
  backendLoggerUrl: '/frontLog/logToServer' ,
  envType: 'PROD',
  showUnknowTranslations: true,
  // Backend access
  baseUri:"https://ecostruxure-power-build-contractors.se.app/services/rest",
  // Application configuration
  ecorealToken:"SSO_PBCT2",
  platform: "ECOREALExpressPRD2",
  appBaseHref: "/ecorealct",

  // IDMS authentication
  authenticationSkipped: false,
  idmsAuthorizeUrl:"https://idp.se.com/authorize",
  idmsClientId:"tM24ktT66Wev4AQ3lw0E5FaguMjU7Kby",
  idmsRedirectUri:"https://ecostruxure-power-build-contractors.se.app/ecorealct/connectIdms",
  idmsDisconnectionUrl:"https://idp.se.com/oidc/logout",
  idmsDisconnectionRedirectionUrl:"https://ecostruxure-power-build-contractors.se.app/ecorealct/disconnectIdms",
  idmsScope:"openid offline_access profile email phone",
  // Comtool
  comToolFrontendHost: "https://ecostruxure-power-build-contractors.se.app",
  // Package
  packageActivation:"ES,GB,DE,BE,CH,PT,AU,NL,EG",
  // Feedback
  feedbackDelay: 10,
  // MySE
  mySENetPrice: true,
  // CPQ
  cpqCookieQuoteId:"CPQ_DEFAULT_ID",
  cpqRedirectUrlCookie:"CPQ_REDIRECT_URL",
  cpqCookieProjectId:"CPQ_PROJECT_ID",
  cpqCookieDocumentId:"CPQ_DOCUMENT_ID",
  cpqParameterQuoteId:"transactionId",
  cpqParameterProcessVar:"processVarName",
  cpqParameterConfigId:"ConfigID",
  cpqParameterOriginalConfigId:"OriginalConfigID",
  cpqParameterDocumentId:"DocumentID",
  cpqParameterBaseUrl:"baseUrl",
  cpqRedirectUrlEnd:"/connectCpq"
};
