/* Angular modules */
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
import {MessageService} from 'primeng/api';
/* app modules */
import {OfferService} from '../shared/offer.service';
import {NavigationStep} from '../../shared/guards/navigationStep-enum';
import {ItemService} from '../../project/shared/item.service';
import {Item, Project, Status} from '../../project/shared/project-model';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {NavigationLinkEnum} from '../../shared/guards/navigationLink-enum';
import {LoggerService} from '../../shared/logging/logger.service';
import {User} from '../../shared/user/user';
import {SimplifiedRange} from '../../shared/model/simplifiedRange-model';
import {Range, RangeStatus} from '../../shared/model/range-model';
import {ProjectService} from '../../project/shared/project.service';
import {NavigationRoute} from '../../shared/guards/route-enum';
import {Subject} from 'rxjs/Rx';
import {PackageOffer, PackageOfferGroup} from '../../admin/shared/package/package.model';
import {FileService, PackageOfferService} from '../../admin/shared';
import {PackageOfferGroupService} from '../../admin/shared/package/package-offer-group.service';
import {RangeType} from '../../shared/model/range-type';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-offer',
  templateUrl: './offers-page.component.html',
  styleUrls: ['./offers-page.component.less'],
})
export class OffersPageComponent implements OnInit, OnDestroy {
  @ViewChild('childModal') childModal: CommonModalComponent;

  @SessionStorage()
  user: User;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  currentItemct: Item;

  isLoading = true;

  // Variable for navigation bar when no item is currently selected
  @SessionStorage()
  noItemNavigationStep = NavigationStep.OFFERS_CT;

  // Variable for navigation bar when not on Administration screen
  @SessionStorage()
  isOnAdministrationScreen = false;

  @SessionStorage()
  documentInformations;


  ranges: Range[];
  currentRange = null;
  unsubscribe$: Subject<void> = new Subject<void>();

  packageOffers: PackageOffer[] = [];
  packageOfferGroups: PackageOfferGroup[] = [];
  currentPackageOffer: PackageOffer = null;
  selectedPackageOfferGroup: PackageOfferGroup;

  constructor(private offerService: OfferService,
              private itemService: ItemService,
              private projectService: ProjectService,
              private router: Router,
              private logger: LoggerService,
              private messageService: MessageService,
              private translateService: TranslateService,
              private packageOfferService: PackageOfferService,
              private packageOfferGroupService: PackageOfferGroupService,
              private fileService: FileService) {
  }

  ngOnInit(): void {
    this.logger.debug('OffersPageComponent init()');
    this.itemService.setItemNavigationStep(NavigationStep.OFFERS_CT, this.user, this.unsubscribe$);
    this.offerService.getRanges()
      .subscribe(ranges => {
        this.ranges = ranges.filter(range => range.status !== RangeStatus.DECOMMISSIONED);
      })
    this.getAllPackageOffers();

    if (this.currentItemct && this.currentItemct.status === Status.ordered && !this.projectService.isReadOnlyProject(this.currentProject, this.user)) {
      if (this.projectService.isCpqProject(this.currentProject)) {
        // Information Toast
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('T_ORDER_WARNING_TITLE'),
          detail: this.translateService.instant('T_PUSH_TO_CPQ_WARNING')
        });
      } else {
        // Information Toast
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('T_ORDER_WARNING_TITLE'),
          detail: this.translateService.instant('T_ORDER_WARNING')
        });
      }

    } else {
      if (this.currentItemct && !this.projectService.isReadOnlyProject(this.currentProject, this.user)) {
        // Information Toast
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('T_LOOSE_DATA_WARNING_TITLE'),
          detail: this.translateService.instant('T_LOOSE_DATA_WARNING')
        });
      }
    }

  }

  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    // unsubscribe from the subject itself:
    this.unsubscribe$.unsubscribe();
  }

  getStandardRanges(): Range[] {
    if(!this.ranges){
      return [];
    }
    return this.ranges.filter(range =>
      range.rangeType !== RangeType.IMPORTED &&
      range.rangeType !== RangeType.BUDJETARY_QUOTE
    )
  }

  getBudgetaryQuoteRanges(): Range[] {
    if(!this.ranges){
      return [];
    }
    return this.ranges.filter(range => range.rangeType ===  RangeType.BUDJETARY_QUOTE);
  }

  showPackageOfferGroupSelection(): boolean {
    return environment.packageActivation.includes(this.user.partnerCountry);
  }

  getAllPackageOffers(){
    this.packageOfferService.getAll().subscribe(packageOffers => {
      if (packageOffers) {
        if(this.showPackageOfferGroupSelection()){
          packageOffers = packageOffers.filter(offer => offer.type === 'IMPORTED');
        } else {
          packageOffers = packageOffers.filter(offer => offer.type === 'STANDARD');
        }

        packageOffers = packageOffers.sort((a, b) => {
          if(a.order != undefined && b.order != undefined && a.order < b.order){
            return -1;
          }
          return 1;
        });

        packageOffers.forEach(packageOffer => {
          if(packageOffer.imageFileId){
            this.fileService.get(packageOffer.imageFileId).subscribe(file => {
              packageOffer.image = file.data;
            })
          }
          if( packageOffer.packages.length > 0 ) {
            this.packageOffers.push(packageOffer);
            this.packageOfferGroupService.getPackageOfferGroupById(packageOffer.packageOfferGroupId).subscribe(packageOfferGroup => {
              if(!this.packageOfferGroups.find(aPackageOfferGroup => aPackageOfferGroup.id === packageOffer.packageOfferGroupId)){
                this.packageOfferGroups.push(packageOfferGroup);
                this.packageOfferGroups = this.packageOfferGroups.sort((a, b) => {
                  if(a.order != undefined && b.order != undefined && a.order < b.order){
                    return -1;
                  }
                  return 1;
                });
              }

              if(this.currentItemct && this.currentItemct.packageOfferId){
                const previouslySelectedPackageOffer = this.packageOffers.find(pack => {
                  if(pack.id === this.currentItemct.packageOfferId){
                    return pack;
                  }
                });
                this.selectedPackageOfferGroup = this.packageOfferGroups.find(packGroup => {
                  if(packGroup.id == previouslySelectedPackageOffer.packageOfferGroupId){
                    return packGroup;
                  }
                });
              } else {
                this.selectedPackageOfferGroup = this.packageOfferGroups[0];
              }
            })
          }
        })
      } else {
        this.packageOffers = [];
      }
      this.isLoading = false;
    });
  }

  /**
   * To route on the correct function for item creation
   * @param {Range} range future range of the item
   */
  addProduct(range: Range): void {
    this.logger.info('OffersPageComponent addProduct()');
    if (this.isPossibleToSelect(range)) {
      this.addItem(range);
    }
  }

  confirmLooseData() {
    this.logger.info('OffersPageComponent confirmLooseData()');

    if (this.currentItemct) {
      this.itemService.deleteItemFromProject(this.currentProject.id, this.currentItemct.id)
        .takeUntil(this.unsubscribe$)
        .subscribe(item => {
            this.currentItemct = null;
            this.documentInformations = null;
            if (this.currentRange) {
              this.addItem(this.currentRange);
            } else if (this.currentPackageOffer) {
              this.addPackageOffer(this.currentPackageOffer);
            }
          }
        );
    }
  }

  // return true if it is possible to select this range
  isPossibleToSelect(range): boolean {
    return (!this.currentItemct || this.currentItemct.status !== Status.ordered ||
        (!! this.currentItemct.range && this.currentItemct.range.id === range.id)) &&
      !this.projectService.isReadOnlyProject(this.currentProject, this.user);
  }

  // return true if it is possible to select this package
  isPossibleToSelectPackage(): boolean {
    return (!this.currentItemct || this.currentItemct.status !== Status.ordered || !!this.currentItemct.packageOfferId) &&
      !this.projectService.isReadOnlyProject(this.currentProject, this.user);
  }

  /**
   * Create or update item for package offer
   */
  addPackageOffer(packageOffer: PackageOffer): void {
    if (this.isPossibleToSelectPackage()) {
      this.logger.info('OffersPageComponent addPackage()');
      // When we replace an existing item
      if (this.currentItemct != null) {
        this.currentRange = null;
        this.currentPackageOffer = packageOffer;
        this.childModal.show();
      } else {
        this.createItem(null, packageOffer);
      }
    }
  }

  /**
   * Create or update item for selected range
   * @param range range
   */
  private addItem(range: Range): void {
    this.logger.info('OffersPageComponent addItem()');
    // When we replace an existing item
    if (this.currentItemct != null) {
      if (!!this.currentItemct.packageOfferId || this.currentItemct.range.id !== range.id) {
        this.currentRange = new SimplifiedRange(range);
        this.currentPackageOffer = null;
        this.childModal.show();
      } else {
        this.routeItem(range);
      }
    } else {
      this.createItem(range);
    }
  }

  /**
   * Itemct creation with range or package offer
   * @param range range to create Itemct
   * @param packageOffer
   */
  private createItem(range?: Range, packageOffer?: PackageOffer): void {
    this.isLoading = true;
    this.itemService.addItem(this.currentProject.id, !!range ? range : packageOffer, this.user)
      .toPromise()
      .then(item => {
        this.currentItemct = item;
        // log after storage of item in session to get item info in business log
        this.logger.logStatus (this.currentItemct);
      })
      .then(() => this.projectService.getProject(this.currentProject.id).toPromise())
      .then( project => this.currentProject = project)
      .then(() => this.isLoading = false)
      .then(() => (!!range) ? this.routeItem(range) : this.routePackageOfferItem());
  }

  /**
   * Function to route on the correct page following the item creation
   * @param {Range} range the range of the item which will determine the route path
   */
  private routeItem(range: Range) {
    if (this.currentItemct.status !== Status.ordered) {
      const maxNavigationStep = NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).id;
      if (maxNavigationStep > this.currentItemct.maxNavigationStep) {
        this.currentItemct.maxNavigationStep = maxNavigationStep;
      }
    }
    this.router.navigate([NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).link]);
  }

  /**
   * Function to route on the correct page for package offer
   */
  private routePackageOfferItem() {
    if (this.currentItemct.status !== Status.ordered) {
      const maxNavigationStep = NavigationRoute.getNextStepByPackageOffer(this.currentItemct.currentNavigationStep).id;
      if (maxNavigationStep > this.currentItemct.maxNavigationStep) {
        this.currentItemct.maxNavigationStep = maxNavigationStep;
      }
    }
    this.router.navigate([NavigationLinkEnum.PACKAGE_OFFER]);
  }
}
