<div class="container section-margin" #container>
  <div class="row">

    <!-- FIRST COLUMN -->
    <div class="col-sm-12 col-md-4 offer-image-column">
      <div class="offer-image-div">
        <h1 *ngIf="!isAccessoriesRange()" translate>T_SWITCHBOARD_CHARACTERISTICS_TITLE</h1>
        <h1 *ngIf="isAccessoriesRange()" translate>{{getAccessoryTitle()}}</h1>

        <!-- OFFER IMAGE BOX -->
        <div class="offer-image-box">
          <div class="offerImageHeader">
            <span class="offer-name">{{currentItemct.range.nameKey | translate}}</span>
          </div>

          <app-element-tile *ngIf="currentItemct.range.rangePicture"
                            [pictureUrl]="currentItemct.range.rangePicture?.url"
                            [pictureFileId]="currentItemct.range.rangePicture?.imageFileId"
                            [clickable]="false"
                            [boxShadow]="false">
          </app-element-tile>

          <!-- SETTING COMPLETE INDICATOR -->
          <span translate class="required-field">
            <span [ngClass]="{'se-icons icon': true, 'se-notification-ok-stroke icon-validated': settingsEnded,
            'se-notification-critical icon-required': !settingsEnded }">
				{{settingsEnded ? 'notification_ok_stroke' : 'notification_critical'}}
			</span>
            {{settingsEnded ? 'T_SETTING_ENDED' : 'T_REQUIRED_FIELD' }}
          </span>
        </div>
      </div>
    </div>

    <!-- SPACER -->
    <div class="col-sm-12 col-md-1"></div>

    <!-- SECOND COLUMN -->
    <div class="col-sm-12 col-md-7">

      <div *ngIf="waitingForServer" class="cover-spinner">
        <!-- SPINNER -->
        <app-spinner class="center-spinner" [sizeClass]="'large'">
          <h3 translate>{{spinnerMessage}}</h3>
        </app-spinner>
      </div>

      <form class="form-horizontal" role="form">

        <!-- ELECTRICAL CHARACTERISTICS -->
        <app-characteristic-fieldset
          [title]="'T_ELECTRICAL_CHARACTERISTICS'"
          [listOfElement]="electricalCharacteristicsValues"
          [readOnly]="!isAllowedToChange"
          (changeValue)="changeValue($event, 'electrical')">
        </app-characteristic-fieldset>

        <!-- ACCESSORIES CHARACTERISTICS -->
        <app-characteristic-fieldset *ngFor="let accessoriesFamily of allAccessories; let i = index"
                                     [title]="accessoriesFamily.category"
                                     [listOfElement]="accessoriesFamily.accessories"
                                     [readOnly]="!isAllowedToChange"
                                     [accessInfo]="currentItemct.range.accessoriesInformation"
                                     [(display)]="allAccessories.length === 1 || accessoriesFamily.display"
                                     (displayChange)="openAccessoryCategoryChanged(i, $event)"
                                     (changeValue)="changeValue($event, 'accessory')"
                                     (clickOnLast)="(i===allAccessories.length-1)?scrollDown():null">
        </app-characteristic-fieldset>

        <!-- VALIDATE BUTTON -->
        <div class="text-center">
          <button type="button" class="btn btn-default-gray-background btn-characteristics"
                  [disabled]="disableValidateButton()"
                  [ngClass]="{'disabled': currentItemct.maxNavigationStep <= characteristicStepNumber}"
                  (click)="goToNextTab()" translate>
            T_CHARACTERISTICS_VALIDATE
          </button>
        </div>

      </form>
    </div>
  </div>
</div>

<!-- confirm dialog -->
<app-common-modal #LooseModal [title]="'T_LOOSE_DATA_TITLE' | translate" [name]="'T_LOOSE_DATA_TITLE'"
                  [description]="'T_LOOSE_DATA' | translate" (onClickYesButton)="confirmLooseData()"
                  (onClickNoButton)="reinitData()" [size]="'modal-sm'">
</app-common-modal>
