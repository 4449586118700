<div class="panel-heading" [ngClass]="{'read-only-project': project.readOnly}" >

  <div class="btn-group  button-div pull-right"  dropdown
       placement="bottom left">
    <button dropdownToggle type="button" class="btn se-top-btn toggle-dropdown"
            [ngClass]="{'read-only-project': project.readOnly}"
            (click)="setCurrentIdProject(project)">
      <span class="se-icons font-param" aria-hidden="true">other_vertical</span>
    </button>
    <ul *dropdownMenu class="box-dropdown dropdown-menu dropdown-menu-right" role="menu">
      <li *ngIf="displayEditingElement(project) && !displayShareElement() && !isCpqProject()" ><a (click)="showModal('duplicate')" translate>T_PROJECT_DUPLICATE</a></li>
      <li *ngIf="displayEditingElement(project) && !displayShareElement() && isCpqProject()" ><a (click)="showModal('duplicateCpq')" translate>T_PROJECT_DUPLICATE</a></li>
      <li *ngIf="displayShareElement()" ><a (click)="showModal('duplicateOnMyProject')" translate>T_PROJECT_DUPLICATE_ON_MY_PROJECT</a></li>
      <li *ngIf="displayDeletingElement(project)" ><a (click)="showModal('delete')" translate>T_PROJECT_DELETE</a></li>
      <li *ngIf="isCpqProject()" class="li-cpq">
        <a *ngIf="canPushToCpq()" class="push-to-cpq" (click)="showModal('push')" translate>{{ showUpdateInCpq() ? 'T_PROJECT_UPDATE_CPQ' : 'T_PROJECT_PUSH_CPQ' }}</a>
        <a *ngIf="!canPushToCpq()" class="disabled" translate>
          {{ showUpdateInCpq() ? 'T_PROJECT_UPDATE_CPQ' : 'T_PROJECT_PUSH_CPQ' }}</a>
        <span *ngIf="!canPushToCpq()" class="info-pellet span-blue se-icons " (mouseover)="displayPushCpqAlert=true" (mouseout)="displayPushCpqAlert=false">information_circle</span>
      </li>
      <li *ngIf="isPrivateProject() && !isDemoUser()" (click)="updateProjectGroup()"><a [tooltip]="getPrivateProjectMigrateTooltip()" translate>T_PROJECTS_PRIVATE_MIGRATE</a></li>
      <li *ngIf="!isCpqProject() && rightService.isCPQUser()"><a (click)="showModal('associateToCpq')" translate>T_PROJECT_ASSOCIATE_TO_CPQ</a></li>
      <div *ngIf="displayPushCpqAlert" class="pushToCpqMessage">
        <span translate>T_PROJECT_CPQ_PUSH_MESSAGE</span>
      </div>
    </ul>
  </div>


  <app-project-bom-button
    [items]="project.itemCT"
    [currency]="localization?.displayedCurrency"
    [showPrices]="!isActivatedDemo() && !isCpqProject()"
    (onClickGoToProjectBom)="goToProjectBom()"></app-project-bom-button>
</div>

<div class="panel-body projectContent" [ngClass]="{'read-only-project': project.readOnly}">
  <div class="row">
    <div class="col-md-2">
      <!-- PROJECT NAME EDITABLE -->
      <div class="header-project-name">
        <span *ngIf="isPrivateProject()" class="pi pi-eye-slash private-project-logo" tooltipPlacement="right" [tooltip]="getPrivateProjectTooltip()"></span>
        <h4 #projectName
            *ngIf="displayEditingElement(project)"
            [ngClass]="{'isOnError': !isValidName}"
            class="editableContent"
            contenteditable="true"
            (blur)="updateProjectName(project, documentInformations, currentItemct, $event)"
            (keyup)="projectNameCheck($event)"
            onpaste="return false;">
          {{project.name}}</h4>
        <!-- PROJECT NAME NOT EDITABLE -->
        <h4 #projectName *ngIf="!displayEditingElement(project)">{{project.name}}</h4>
        <span *ngIf="isCpqProject() && project.cpqStatus === 'TO_BE_PUSHED'" class="cpq-label" translate>T_PROJECT_CPQ</span>
        <span *ngIf="isCpqProject() && project.cpqStatus === 'PUSHED'" class="cpq-label" translate>T_PROJECT_PUSHED_TO_CPQ</span>
      </div>

      <p class="projectParam">
        <span translate>T_PROJECT_CREATION_DATE</span> :
        <span class="project-date">{{utilService.getLocalizedDate(project.creationDate, user.preferredLanguage)}}</span>
      </p>
      <p class="projectParam">
        <span translate>T_PROJECT_LAST_UPDATE</span> :
        <span class="project-date">{{utilService.getLocalizedDate(project.updateDate, user.preferredLanguage)}}</span>
      </p>
      <p class="projectParam" *ngIf="isCpqProject()">
        <span translate>T_QUOTE_CPQ_NB</span> :
        <span class="project-date">{{project.cpqNumber}}</span>
      </p>
      <div *ngIf="!project.readOnly && !isCpqProject()" class="share-button-div">
        <button type="button" class="btn btn-primary share-button"
                (click)="collaboration(project)" translate>
          <span class="se-icons share-label">action_share</span>
          T_COLLABORATIVE_PROJECT_COLLABORATION
        </button>
      </div>
      <p *ngIf="project.sharedProjectCount" class="projectParamCount">
        <span class="project-count">{{project.sharedProjectCount}}</span>
        <span *ngIf="project.sharedProjectCount === 1" translate>T_COLLABORATIVE_TIME_SHARING_COUNT</span>
        <span *ngIf="project.sharedProjectCount > 1" translate>T_COLLABORATIVE_TIMES_SHARING_COUNT</span>
      </p>

      <div *ngIf="project.readOnly" class="read-only-message-div">
        <div class="se-icons brand-warning exclamation-circle-margin read-only-warning">general_danger_wired</div>
        <div class="read-only-message" translate>T_PROJECT_READ_ONLY_INFORMATION</div>
      </div>

    </div>
    <div class="col-md-10">
      <app-item-carousel [project]="project" [readOnly]="!onMyLastProjectsTab || project.readOnly || isAlreadyPushToCpq()"
                         (clickOnItem)="goToItemCt($event)"
                         [deleteItemEvent]="deleteItemEvent"
                         (clickOnAdd)="addSwitchboard($event)"
                         (clickOnDeleteItem)="deleteItem($event)">
      </app-item-carousel>
    </div>
  </div>
</div>
