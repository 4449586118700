/* Angular modules */
import {AfterViewInit, Component, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {SessionStorage} from 'ngx-webstorage';
import {LoggerService} from '../../../shared/logging/logger.service';
import {TranslationKey, TranslationService} from '../../shared';
import {AddButton, TranslationStatusEnum} from 'app/shared/model/localization.model';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {isNullOrUndefined} from 'util';
import {TabsetComponent} from 'ngx-bootstrap';
import {CommonModalComponent} from '../../../shared/common-modal/common-modal.component';
import {Language} from '../../../shared/model/language-model';
import {TranslationHelperService} from 'app/admin/shared/translation/translation.helper.service';
import {OfferService} from '../../../offers/shared/offer.service';
import {Range} from '../../../shared/model/range-model';
import {UtilService} from '../../../shared/util/util.service';
import {Table} from 'primeng/table';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.less'],
})

export class TranslationComponent implements OnInit {

  @SessionStorage()
  isOnAdministrationScreen = true;

  @SessionStorage()
  user;

  @SessionStorage()
  localization;

  translationKeys: TranslationKey[];

  translationLength: number;

  locales: any[];

  ranges: Range[];

  offers: string[] = [];

  languageTranslation: any;

  allLanguages: Language[];

  countryNameCode: string;

  languageToAdd: string;

  indexOfActiveTab = 0;

  isAddingNewLanguage = false;

  isExportTranslation = false;

  isLoading = false;

  cols: any[];

  @ViewChild('dataTableFilter') dataTableFilter: Table;

  @ViewChild('allTabs') allTabs: TabsetComponent;

  @ViewChild('addLanguageModal') addLanguageModal: CommonModalComponent;


  public constructor(private translationService: TranslationService,
                     private messageService: MessageService,
                     private translateService: TranslateService,
                     private translationHelperService: TranslationHelperService,
                     private offerService: OfferService,
                     private utilService: UtilService,
                     private logger: LoggerService,
                     private route: ActivatedRoute) {
  }


  public ngOnInit(): void {
    this.getAllTranslationKeys();
    this.getAllLanguages();
    this.getUserCountryName();
    this.cols = [
      { field: 'key', header: (this.translateService.instant('T_BO_TRANSLATION_COLUMN_KEY')), style: 'key-column', filterValue: '' },
      { field: 'defaultTranslation', header: (this.translateService.instant('T_BO_TRANSLATION_COLUMN_DEFAULT')), style: 'translation-column', filterValue: ''  },
      { field: 'translation', header: 'language Translation', style: 'translation-column', filterValue: ''},
      { field: 'status', header: (this.translateService.instant('T_BO_TRANSLATION_COLUMN_STATUS')), style: 'status-column', filterValue: ''}
      ];
  }


  initFiltersWithParametersFromUrl() {
    this.route.queryParams
        .subscribe(params => {
              if(params.translationKey){
                setTimeout(() => {
                  this.dataTableFilter.filter(params.translationKey, 'key', 'contains');
                  this.cols.map(col => {
                      if(col.field === 'key'){
                        col.filterValue = params.translationKey;
                      }
                      return col;
                  })
                },500);
              }
            }
        );
  }

  /**
   * Function to check if elem is an instance of AddButton
   * @param elem
   * @returns {boolean}
   */
  isAddButton(elem: any) {
    return elem instanceof AddButton;
  }

  getHeaderName(language): string {
    return language.toUpperCase() + '-' + this.localization.country.toUpperCase();
  }

  onTabChange(locale, event) {
    // get locale languageTranslation column
    const localeTranslation = locale.translations.find(function (trad) {
      return trad.key === 'T_BO_TRANSLATION_COLUMN_LANGUAGE';
    });
    this.languageTranslation = localeTranslation.translation;
    if (isNullOrUndefined(this.languageTranslation)) {
      // get default value of languageTranslation column
      const localeTranslationDefaultValue = this.translationKeys.find(function (trad) {
        return trad.key === 'T_BO_TRANSLATION_COLUMN_LANGUAGE';
      });
      this.languageTranslation = localeTranslationDefaultValue.defaultTranslation;
    }
    this.indexOfActiveTab = this.allTabs.tabs.indexOf(event);
  }

  getDescriptionByKey(key, locale): string {
    const localeTranslation = locale.translations.find(function (trad) {
      return trad.key === key;
    });
    return localeTranslation.description;
  }

  showAddLanguageModal() {
    this.updateLanguageList();
    this.isAddingNewLanguage = false;
    setTimeout(() => {
      if (this.indexOfActiveTab >= 0) {
        this.allTabs.tabs[this.indexOfActiveTab].active = true;
      } else {
        this.allTabs.tabs[0].active = true;
      }
    });
    this.addLanguageModal.show();
  }

  addNewLanguage() {
    this.isLoading = true;
    if (isNullOrUndefined(this.languageToAdd)) {
      this.languageToAdd = this.allLanguages[0].code;
    }
    this.translationService.addNewLanguage(this.languageToAdd).subscribe(
      () => {
        this.isAddingNewLanguage = true;
        this.getAllTranslationKeys();
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_BO_LANGUAGE_ADD_SUCCESS'),
        });
      },
      error => {
        this.logger.error(error);
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_BO_LANGUAGE_ADD_ERROR'),
        });
      },
      () => this.isLoading = false
    );
    this.addLanguageModal.hide();
    this.languageToAdd = null;
  }

  setLanguageCode(language) {
    this.languageToAdd = language;
  }


  getTranslationNumberRow(content) {
    let numberRow = 1;
    if (!isNullOrUndefined(content)  && content.length > 45) {
      numberRow = Math.ceil(content.length / 45);
    }
    return numberRow;
  }

  translationChange(locale, translation) {
    locale.editedStatus = true;
    locale.translations.forEach(trad => {
      if (trad.key === translation.key) {
        trad.status = TranslationStatusEnum.EDITED;
      }
    });
  }

  getTradPercentage(locale) {
    return this.translationHelperService.getTradPercentage(locale.translations, this.translationLength);
  }

  updateLocaleAfterImport() {
    this.isExportTranslation = true;
    this.getAllTranslationKeys();
  }

  isLoadingEvent(value: boolean) {
    this.isLoading = value;
  }

  private getAllTranslationKeys() {
    // get translationKeys
    this.translationService.getTranslationKeys(true).subscribe(translationKeys => {
      this.translationKeys = translationKeys;
      this.translationLength = translationKeys.length;
      this.buildAllLocaleDatas();
    });
  }

  private buildAllLocaleDatas() {
    // get locales and build translation data
    this.translationService.getLocalesOfCountry().subscribe( localeKeys => {
      this.locales = localeKeys;

      // get languageTranslation column on init
      const localeTranslation = this.locales[0].translations.find(trad => trad.key === 'T_BO_TRANSLATION_COLUMN_LANGUAGE');

      this.languageTranslation = localeTranslation === null ?
        this.translationKeys.find(key => key.key === 'T_BO_TRANSLATION_COLUMN_LANGUAGE').defaultTranslation
        : localeTranslation.translation;

      // customize tab add
      this.locales.push(new AddButton());

      // if adding a new language, we must display the tab of new language
      if (this.isAddingNewLanguage) {
        const index = this.locales.length - 2;
        setTimeout(() => {
          this.allTabs.tabs[index].active = true;
        });
        this.indexOfActiveTab = index;
        this.updateLanguageList();
        this.isAddingNewLanguage = false;
      }
      // if import translations, we must to stay on the current tab
      if (this.isExportTranslation) {
        setTimeout(() => {
          if (this.indexOfActiveTab >= 0) {
            this.allTabs.tabs[this.indexOfActiveTab].active = true;
          } else {
            this.allTabs.tabs[0].active = true;
          }
        });
        this.isExportTranslation = false;
      }
      this.initFiltersWithParametersFromUrl();
    });
  }

  private getAllLanguages() {
    this.translationService.getAllLanguages().subscribe(
      languages => {
        this.allLanguages = languages;
        // this.languageToAdd = languages[0].code;
      }
    );
  }

  private updateLanguageList() {
    this.locales.forEach(locale => {
      let  code = '';
      if (!isNullOrUndefined(locale.language)) {
        code = locale.language.toUpperCase();
      }
      this.allLanguages.forEach(language => {
        if (code === language.code.toUpperCase()) {
          const index = this.allLanguages.indexOf(language, 0);
          this.allLanguages.splice(index, 1);
        }
      });
    });
    if (isNullOrUndefined(this.languageToAdd)) {
      this.languageToAdd = this.allLanguages[0].code;
    }
  }

  private getUserCountryName() {
    this.translationService.getCountryNameByCode(this.user.partnerCountry).subscribe(
      country => {
        this.countryNameCode = country + ' (' + this.user.partnerCountry + ')';
      }
    );
  }

}

