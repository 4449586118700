<div id="se-top-bar" class="se-top-bar container-fluid to-front">
  <div>
    <div class="row top-bar-height">
      <div *ngIf="(!isActivatedDemoUser() && !isDemoMode) || isDeactivatedDemoUser()" class="col-xs-3 col-sm-2 col-md-2 col-lg-2 header-element app-title">
        <img class="pointer app-ecostruxure-logo header-subelement" (click)="redirectToProjects()"
             src="./assets/images/EcoStruxure_logo_green.png" alt="EcoStruxure Logo"/>

        <div>
          <span class="pointer header-subelement" (click)="redirectToProjects()">Power <span class="pointer app-title-light">Build</span></span>
        </div>
      </div>

      <!--Demo Logo-->
      <div *ngIf="isActivatedDemoUser()" class="col-xs-4 col-sm-4 col-md-4 col-lg-3 header-element app-title">
        <img class="pointer app-ecostruxure-logo header-subelement" (click)="redirectToProjects()"
             src="./assets/images/EcoStruxure_logo_green.png" alt="EcoStruxure Logo"/>
        <div>
        <span class="pointer header-subelement" (click)="redirectToProjects()">Power <span
          class="pointer app-title-light">Build<sup><em>DEMO</em></sup></span></span>
        </div>
      </div>
      <!--Demo Logo end-->

      <!-- Project name section -->
      <div class="col-xs-4 col-sm-3 col-md-3 col-lg-3 header-element">
      <span [style.visibility]="projectNameMustBeDisplayed()?'visible':'hidden'">
        <span #projectName *ngIf="currentProject && !isReadOnlyProject()"
              class="editableContent"
              contenteditable="true"
              onpaste="return false;"
              [ngClass]="{'isOnError': !isValidProjectName}"
              (blur)="updateProjectName(currentProject, documentInformations, currentItemct, $event)"
              (keydown)="projectNameCheck($event)">
          {{currentProject.name}}
        </span>
        <span #projectName *ngIf="currentProject && isReadOnlyProject()">{{currentProject?.name}}</span>
        <span *ngIf="!isReadOnlyProject() && currentItemct !== null"> - </span>
        <span #itemName *ngIf="!isReadOnlyProject() && currentItemct !== null"
              class="editableContent"
              [ngClass]="{'isOnError': !isValidItemName}"
              contenteditable="true"
              onpaste="return false;"
              (blur)="updateItemName(currentProject, currentItemct, $event, user)"
              (keydown)="itemNameCheck($event)">
          {{currentItemct?.name}}
        </span>

        <span #itemName *ngIf="isReadOnlyProject()">{{currentItemct?.name}}</span>
      </span>
      </div>
      <!-- All header sections -->
      <div class="col-xs-3 col-sm-3 col-md-4 col-lg-3 header-element">
        <div class="row">
          <!-- CPQ section-->
          <div class="box" *ngIf="showCpqSection()">
            <div class="row">
              <span class="se-icons font-header dark-grey-color" aria-hidden="true">quotation</span>
              <div [ngClass]="{'hidden-xs hidden-sm hidden-md': true}"
                   style="display: inline-block;vertical-align: middle"
                   tooltip="{{'T_QUOTE_CPQ_NB' | translate}}" placement="bottom" container="body">
                <div class="box-label" translate>T_QUOTE_CPQ_ID</div>
                <div class="box-sub-label">{{cpqQuoteId}}</div>
              </div>

              <div class="btn-group hidden-lg" dropdown placement="bottom left">
                <button dropdownToggle type="button" class="btn dropdown-toggle se-top-btn">
                  <span class="se-icons font-header dark-grey-color arrow-down" aria-hidden="true">arrow2_down</span>
                </button>

                <ul *dropdownMenu class="box-dropdown dropdown-menu dropdown-menu-right" role="menu">
                  <li class="box-label" translate>T_QUOTE_CPQ_ID</li>
                  <li class="box-sub-label">
                    <div class="box-sub-label">{{cpqQuoteId}}</div>
                  </li>
                </ul>
              </div>
              <div class="btn-group" dropdown placement="bottom left">
                <button dropdownToggle type="button" class="btn se-top-btn toggle-dropdown">
                  <span class="se-icons font-header dark-grey-color" aria-hidden="true">other_vertical</span>
                </button>
                <ul *dropdownMenu class="box-dropdown dropdown-menu dropdown-menu-right" role="menu">
                  <li><a (click)="goBackToCpq()" translate>T_REDIRECT_TO_CPQ</a></li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div *ngIf="(!isActivatedDemoUser() && !isDemoMode) || isDeactivatedDemoUser()" [ngClass]="{ 'col-xs-3 col-sm-5 col-md-4 header-element' : true, 'col-lg-5' : !isAdminUser(), 'col-lg-6' : isAdminUser()}"
           align="right">
        <div class="btn-group" dropdown placement="bottom left">
          <button dropdownToggle type="button" class="btn dropdown-toggle se-top-btn btn-header">
            <span class="se-icons text-green font-header" aria-hidden="true">user_management</span>
            <span class="hidden-xs hidden-sm hidden-md se-top-btn-over"
                  *ngIf="user">{{user.firstName}} {{user.lastName}}</span>
            <span class="se-icons font-header dark-grey-color arrow-down" aria-hidden="true">arrow2_down</span>
          </button>
          <ul *dropdownMenu class="dropdown-menu dropdown-width-fill dropdowightn-menu-right" role="menu">
            <li class="align-left"><a (click)="redirectToSettings()" translate>T_BUTTON_SETTINGS</a></li>
            <li class="align-left"><a (click)="logout()" translate>T_BUTTON_LOGOUT</a></li>
          </ul>
        </div>

        <div class="btn-group " dropdown placement="bottom left">
          <button dropdownToggle (click)="logSupportClick()" type="button" class="btn dropdown-toggle se-top-btn btn-header">
            <span class="se-icons text-green font-header" aria-hidden="true">help</span>
            <span class="hidden-xs hidden-sm hidden-md se-top-btn-over" translate>T_SUPPORT_BUTTON</span>
            <span class="se-icons font-header dark-grey-color arrow-down" aria-hidden="true">arrow2_down</span>
          </button>
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right ccc" role="menu">
            <li class="align-left"><strong translate>T_SUPPORT_CCC_TITLE</strong></li>
            <li class="divider dropdown-divider"></li>
            <li *ngIf="getPhoneSupportContact()" class="align-left"><span translate>T_SUPPORT_PHONE_NUMBER</span>
              : {{getPhoneSupportContact()}}</li>
            <li class="align-left" *ngIf="getFaxSupportContact()"><span translate>Fax number</span>
              : {{getFaxSupportContact()}}</li>
            <li class="align-left"><span translate>T_SUPPORT_EMAIL</span> : {{getEmailSupportContact()}}</li>
            <li class="divider dropdown-divider"></li>
            <li *ngIf="isAdminUser() && isFrUser()" class="align-left report-container">
              <a (click)="downloadProjectReport()" class="zero-padding" translate>T_DOWNLOAD_REPORT_PROJECT</a>
              <div class="spinner-download-report">
                <app-spinner *ngIf="isDownloadingProjectReport" [sizeClass]="'small'"></app-spinner>
              </div>
            </li>
            <li *ngIf="isAdminUser() && isFrUser()" class="align-left report-container">
                <a (click)="downloadReferenceReport()" class="zero-padding" translate>T_DOWNLOAD_REPORT_REFERENCE</a>
                <div class="spinner-download-report">
                  <app-spinner *ngIf="isDownloadingReferenceReport" [sizeClass]="'small'"></app-spinner>
                </div>
            </li>
            <li *ngIf="isAdminUser() && isFrUser()" class="divider dropdown-divider"></li>
            <li class="align-left"><a (click)="openUserManual()" class="zero-padding" translate>T_SUPPORT_USER_MANUAL</a>
            </li>
            <li class="align-left"><a (click)="showAboutModal()" class="zero-padding" translate>T_SUPPORT_ABOUT</a></li>
          </ul>
        </div>

        <!-- NOTIFICATIONS BELL -->
        <app-notifications-bell></app-notifications-bell>

        <!-- ADMIN DROPDOWN -->
        <div *ngIf="isAdminUser() || isCommToolAdminUser()" class="btn-group " dropdown placement="bottom left">
          <button dropdownToggle type="button" class="btn dropdown-toggle se-top-btn btn-header">
            <span class="se-icons text-green font-header" aria-hidden="true">action_settings1</span>
            <span class="se-top-btn-over"> </span>
            <span class="se-icons font-header dark-grey-color arrow-down" aria-hidden="true">arrow2_down</span>
          </button>
          <ul *dropdownMenu class="dropdown-menu dropdown-padding dropdown-menu-right" role="menu">
            <li *ngIf="isAdminUser()"><a (click)="redirectToAdmin()" translate>T_ADMIN_ACCESS</a></li>
            <li *ngIf="isCommToolAdminUser()"><a (click)="openComToolsBackOffice()" translate>T_COM_TOOLS_ACCESS</a></li>
          </ul>
        </div>
      </div>

      <!-- Price status section -->
      <div class="myse-status-container">
        <div *ngIf="!isDeactivatedDemoUser() && !isActivatedDemoUser() && !isDemoMode && !isCpqUser()" [ngClass]="{'margin-left': !bomShortCutMustBeDisplay()}">
            <app-myse-status></app-myse-status>
          </div>
      </div>
      <!--Demo version section-->
      <div *ngIf="isActivatedDemoUser() || isDeactivatedDemoUser()" class="col-xs-3 col-sm-3 col-md-4 col-lg-4 header-element" align="right">
        <div class="btn-group">
          <button *ngIf="isActivatedDemoUser() && !isDemoMode" type="button" class="btn register-button" (click)="goToDemoMode()" translate>
            T_DEMO_VERSION_TRY_BUTTON
          </button>
          <button type="button" class="btn register-button" (click)="showRegisterModal()" translate>
            T_DEMO_VERSION_REGISTER_BUTTON
          </button>
          <a class="btn register-button" style="color: #434343" (click)="logout()" translate>T_BUTTON_LOGOUT</a>
        </div>
      </div>
      <!--Demo version section end-->

      <div *ngIf="!isAdminUser()" class="hidden-xs hidden-sm hidden-md col-lg-1 header-element" align="right">
        <img class="app-header-logo" src="./assets/images/se-header-logo.png" alt="SE Logo"/>
      </div>

    </div>
  </div>
</div>

<!--About modal-->
<app-about-modal #aboutModal></app-about-modal>

<!--Register modal-->
<app-register-modal #registerModal>
</app-register-modal>

<!-- order dialog -->
<app-common-modal #alertModal [name]="'alertModal'" [twoButtonsDialogs]="false">
</app-common-modal>
