<p-card class="accessory">
  <div class="accessory-title">
    <h5 translate>{{accessory.name}}</h5>
    <button *ngIf="isEditable()" class="action-button se-icon" (click)="onClickEditAccessoryButton(accessory)">action_editor</button>
    <button class="action-button se-icon" (click)="onClickDeleteAccessoryButton(accessory)">action_delete</button>
    <button (click)="onClickOpenAccessoryButton()" class="se-icon accessory-open-button">{{isExpanded ? "arrow2_down" : "arrow2_right"}}</button>
  </div>

  <div *ngIf="isExpanded">
    <p><span>Description : </span><span translate>{{accessory.description}}</span></p>
    <p><span>Type : </span><span translate>{{accessory.htmlElementType}}</span></p>
    <p *ngIf="accessory.required">
      <span class="se-icons brand-warning">notification_critical_stroke</span>
      <span translate>T_ADMIN_OFFER_DATA_MANAGEMENT_ACCESSORY_IS_MANDATORY</span></p>
    <p *ngIf="accessory.defaultValue"><span>Default Value : </span><span translate>{{getDefaultValueName(accessory)}}</span></p>
    <p>
      <span>Values : </span>
      <ng-template #withoutTooltipTemplate>
        <span [innerHTML]="'T_ACCESSORY_VALUE_WITHOUT_TOOLTIP' | translate" class="tooltip-field"></span>
      </ng-template>

      <span class="se-icons brand-information" container="body"
            [tooltip]="withoutTooltipTemplate" placement="right">information_circle</span>
    </p>
    <p *ngIf="accessory.values.length < 2" class="warning-tooltip" >
      <span class="se-icon">notification_critical_stroke</span>
      <span translate>T_ACCESSORY_VALUE_NOT_ENOUGH_VALUES_WARNING</span>
    </p>
    <p-table [value]="getAccessoryValuesSorted()">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" translate="T_OPTION_VALUE_NAME"></th>
          <th scope="col" translate="T_OPTION_VALUE_REFERENCE"></th>
          <th scope="col" class="accessory-value-actions-column" translate="T_OPTION_VALUE_ACTIONS" ></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-accessoryValue let-index="rowIndex">
        <tr>
          <td translate>{{accessoryValue.value}}</td>
          <td translate>{{accessoryValue.reference ? accessoryValue.reference.ref : ""}}</td>
          <td class="accessory-value-actions-column">
            <button
              [disabled]="index === accessory.values.length-1"
              class="accessory-value-actions-buttons se-icon"
              (click)="onClickMoveDownAccessoryValueButton(accessory, accessoryValue)">
              arrow3_down
            </button>
            <button
              [disabled]="index === 0"
              class="accessory-value-actions-buttons se-icon"
              (click)="onClickMoveUpAccessoryValueButton(accessory, accessoryValue)">
              arrow3_up
            </button>
            <button
              *ngIf="isEditable(accessoryValue.value)"
              class="accessory-value-actions-buttons se-icon"
              (click)="onClickEditAccessoryValueButton(accessory, accessoryValue)">
              action_editor
            </button>
            <button
              *ngIf="isDeletable(accessoryValue.value)"
              class="accessory-value-actions-buttons se-icon"
              (click)="onClickDeleteAccessoryValueButton(accessory, accessoryValue)">
              action_delete
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-card>
