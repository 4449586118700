<div class="row ">
  <h1 class="offer" translate></h1>
</div>

<app-common-modal #addReferenceModal [name]="'addReference'" [title]=" " [twoButtonsDialogs]="true"
                  [withFooter]="false">
  <h4 modal-title class="modal-title" translate>T_OFFER_DATA_MANAGEMENT_ADD_NEW_REFERENCE</h4>
  <div modal-body>


    <div class="row">
      <app-spinner *ngIf="loading" [sizeClass]="'medium'"></app-spinner>
      <div class="content-container" *ngIf="!loading">
        <div class="input-group" *ngIf="!isBudgetaryQuoteRange()">
          <p-autoComplete [(ngModel)]="reference"
                          scrollHeight="120px"
                          [size]="64"
                          [placeholder]="'T_OFFER_DATA_MANAGEMENT_SEARCH_REFERENCE' | translate"
                          [suggestions]="filteredList" (completeMethod)="filterReferences()"
                          [emptyMessage]="'T_OFFER_DATA_MANAGEMENT_NO_REFERENCE_FOUND' | translate"></p-autoComplete>
          <span class="input-group-btn">
            <button disabled class="search-btn">
              <span class="glyphicon glyphicon-search"></span>
            </button>
          </span>
        </div>
        <div *ngIf="isBudgetaryQuoteRange()">
          <div class="form-group">
            <label for="referenceInput" class="normal-label">{{ 'T_OFFER_DATA_MANAGEMENT_ENTER_REFERENCE' | translate }}
              :</label>
            <input type="text"
                   id="referenceInput"
                   [(ngModel)]="newReference"
                   class="form-control  normal-input"
                   style="font-weight: normal;"
                   (ngModelChange)="validateInput($event); isValidForm()"
            />
            <div *ngIf="showError && newReference && !isReferenceValid()" class="text-danger">
              <em translate>T_OFFER_DATA_MANAGEMENT_ONLY_ALPHANUMERIC_ALLOWED</em>
            </div>
          </div>
        </div>
        <div *ngIf="showSldAndLayoutSelector()" class="input-group input-add-sld">
          <input id="sld" type="text" [value]="displaySldList()"
                 class="search-input"
                 disabled>
          <span class="input-group-btn">
            <button (click)="getSLDList()" class="btn add-sld-btn field-mandatory" type="button">
              <em translate>T_OFFER_DATA_MANAGEMENT_ADD_SLD</em>
            </button>
          </span>
        </div>

        <div *ngIf="showSldAndLayoutSelector()" class="input-group input-add-layout">
          <input id="layout" type="text" [value]="displayLayoutList()"
                 class="search-input"
                 disabled>
          <span class="input-group-btn">
          <button (click)="getLayoutList()" class="btn add-layout-btn" type="button">
            <em translate>T_OFFER_DATA_MANAGEMENT_ADD_LAYOUT</em>
          </button>
        </span>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="modal-add-reference-footer form-horizontal">
      <button class="btn btn-default-white-background" (click)="hide()" translate>T_BUTTON_CANCEL</button>
      <button class="btn btn-default-white-background" [disabled]="!isValidForm()" (click)="addReference(); hide()"
              translate>T_OFFER_DATA_MANAGEMENT_ADD_REFERENCE
      </button>
    </div>

    <div *ngIf="showSldAndLayoutSelector()" class="mandatory-message">
      <em class="field-mandatory"></em>
      <span translate>T_OFFER_DATA_MANAGEMENT_MANDATORY_FIELDS</span>
    </div>

  </div>
</app-common-modal>


<!--Modal add sld-->
<app-common-modal #sldModal [name]="'sldModal'" [withFooter]="false" [closable]="false"
                  [title]="'T_OFFER_DATA_MANAGEMENT_ADD_SLD' | translate" size="modal-lg">
  <div modal-body class="form-horizontal">
    <app-odm-offer-sld
      [loading]="loadingSld"
      [sldList]="sldList"
      (onClickAddButton)="getSldToAddOnReference($event)"
      (onClickCancelButton)="sldModal.hide()">
    </app-odm-offer-sld>
  </div>
</app-common-modal>


<!--Modal add layout-->
<app-common-modal #layoutModal [name]="'layoutModal'" [withFooter]="false" [closable]="false"
                  [title]="'T_OFFER_DATA_MANAGEMENT_ADD_LAYOUT' | translate" size="modal-lg">
  <div modal-body class="form-horizontal">
    <app-offer-data-management-offer-layout
      [loading]="loadingLayout"
      [layoutList]="layoutList"
      (onClickAddButton)="getLayoutToAddOnReference($event)"
      (onClickCancelButton)="layoutModal.hide()">
    </app-offer-data-management-offer-layout>
  </div>
</app-common-modal>
