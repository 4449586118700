import {DeliveryPolicy, PriceManagement, RangePicture} from 'app/shared/model/simplifiedRange-model';
import {SwitchboardLimit} from './switchboardLimit-model';
import {Packing} from './packingSelected-model';
import {RangeType} from './range-type';
import {Service} from '../../admin/shared/services/services.model';

export class Range {
  id = null;
  nameKey: string;
  shortDescriptionKey: string;
  imageKey: string;
  switchboardLimit: SwitchboardLimit;
  functionnalUnitsTransformerCompatible?: Array<any>;
  electricalCharacteristics?: Array<string> = null;
  hideCharacteristicScreen: boolean;
  refs?: Array<string> = null;
  filters?: Array<string> = null;
  mainCharacteristics: Array<string> = [];
  electricalCharacteristicsFilters: Array<string> = [];
  mto?: boolean;
  bslRangeId: number;
  validityPeriod: number;
  version: string;
  priceManagement: PriceManagement;
  deliveryPolicy: DeliveryPolicy;
  rangePicture: RangePicture;
  optionalPacking?: Array<Packing> = null;
  rangeType: RangeType;
  visibleComponentsFilters: string[];
  digiQCountry: boolean;
  cardSortCriterion: string;
  useTransformerSelector: boolean;
  emailOrdering: string;
  restrictedOrderingType: RestrictedOrderingType;
  services?: Array<Service> = [];
  partnerDiscounts: PartnerDiscount[];
  active: boolean;
  status?: RangeStatus;
  offerDataManagementConfigurationStep?: OfferDataManagementConfigurationStep;
  accessoriesInformation: string;
  accessoriesCharacteristics: string[];
  restrictedAccess: string;
  importedRange: boolean;
}

export enum RangeStatus {
  PUBLISHED = 'PUBLISHED',
  CONFIGURED = 'CONFIGURED',
  AVAILABLE = 'AVAILABLE',
  TEST = 'TEST',
  DECOMMISSIONED = 'DECOMMISSIONED',
  IMPORTED = 'IMPORTED'
}

export enum OfferDataManagementConfigurationStep {
  CHARACTERISTIC,
  FILTER,
  MAIN_CHARACTERISTIC,
  DOCUMENT,
  REFERENCE,
  PUBLICATION
}

export enum RestrictedOrderingType {
  DIRECT = 'DIRECT',
  INDIRECT = 'INDIRECT'
}

export class PartnerDiscount {
  partnerName: string;
  discount: number | string;
}
