<div class="options-management-container">

  <h3><span class="back-link" (click)="returnOnOfferSelection()" translate>T_OFFER_DATA_MANAGEMENT_BACK_BUTTON</span></h3>

  <div>
    <div class="titles">
      <p class="range-key" translate><span translate>T_OFFER_DATA_MANAGEMENT_OPTIONS_LIST_OF_REFERENCES_OF_RANGE </span>{{selectedRange.nameKey}}</p>
    </div>
  </div>

  <div *ngIf="!isLoading">

    <div class="button-container">
      <input [(ngModel)]="searchValue" (ngModelChange)="searchInputChanged()" [placeholder]="searchPlaceholder"/>
      <button  class="btn btn-smart"  (click)="onClickAddOptionButton()" translate>
        <span class="se-icons">action_add_stroke</span>T_OPTION_ADD_BUTTON
      </button>
      <button  class="btn btn-smart"  (click)="onClickAddOptionValueButton()" translate>
        <span class="se-icons">action_add_stroke</span>T_OPTION_VALUE_ADD_BUTTON
      </button>
      <button  class="btn btn-smart"  (click)="onClickExportOptionsButton()" translate>
        <span class="se-icons">action_download</span>T_PRICE_EXPORT
      </button>
      <app-spinner *ngIf="isExportLoading" [vCenter]="false" [sizeClass]="'extra-small'"></app-spinner>
    </div>

    <h6 *ngIf="searchValue && displayedReferences.length === 0" translate>T_SEARCH_NO_MATCH</h6>

    <div class="category" *ngFor="let reference of displayedReferences">
      <app-odm-options-list-references
        [reference]="reference.ref"
        [options]="optionsByReference[reference.ref]"
        [isExpanded]="activeReference === reference.ref"
        [activeOption]="activeOption"
        [showOnPopUpStatus]="showOnPopUpManagement()"
        (clickEditOptionButton)="onClickEditOptionButton($event, reference.ref)"
        (clickDeleteOptionButton)="onClickDeleteOptionButton($event, reference.ref)"
        (clickCopyOptionButton)="onClickCopyOptionButton($event, reference.ref)"
        (clickEditOptionValueButton)="onClickEditOptionValueButton($event, reference.ref)"
        (clickDeleteOptionValueButton)="onClickDeleteOptionValueButton($event, reference.ref)"
        (changeOptionsOrderEvent)="onChangeOptionsOrder($event, reference.ref)"
      >
      </app-odm-options-list-references>
    </div>
  </div>

  <div *ngIf="isLoading" class="odm-left-column">
    <app-spinner [sizeClass]="'large'"></app-spinner>
  </div>
</div>

<app-odm-edit-option-modal #addOptionModal
                           (saveOption)="addOption($event)"
                           [references]="references">
</app-odm-edit-option-modal>

<app-odm-edit-option-modal #editOptionModal
                           (saveOption)="editOption($event)"
                           [references]="references">
</app-odm-edit-option-modal>

<app-odm-copy-option-modal #copyOptionModal
                           (saveOption)="copyOption($event)"
                           [references]="references"
                           [rangeId]="selectedRange.id">
</app-odm-copy-option-modal>
<app-common-modal #deleteOptionModal [name]="'deleteOptionModal'" (onClickYesButton)="deleteOption()"
                  [description]="'T_DELETE_OPTION_MODAL_MESSAGE' | translate" >
</app-common-modal>
<app-odm-edit-option-value-modal #addOptionValueModal
                                    (saveOptionValue)="addOptionValue($event)"
                                    [isOption]="true"
                                    [selectedRange]="selectedRange">
</app-odm-edit-option-value-modal>

<app-odm-edit-option-value-modal #editOptionValueModal
                                    (saveOptionValue)="editOptionValue($event)"
                                    [selectedRange]="selectedRange">
</app-odm-edit-option-value-modal>
<app-common-modal #deleteOptionValueModal [name]="'deleteOptionValueModal'" (onClickYesButton)="deleteOptionValue()"
                  [description]="'T_DELETE_OPTION_VALUE_MODAL_MESSAGE' | translate" >
</app-common-modal>
