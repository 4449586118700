<app-common-modal #rangeManagementModal [name]="'rangeManagementModal'" [title]=" " [twoButtonsDialogs]="true"
                  [withFooter]="false">
  <h4 modal-title class="modal-title" translate>T_OFFER_DATA_MANAGEMENT_RANGE_INFORMATION</h4>

  <div modal-body>
    <div *ngIf="!isBudgetaryQuoteRange()">
      <h5 translate>T_OFFER_DATA_MANAGEMENT_BSL_RANGE_ID</h5>
      <input type="number" [(ngModel)]="editRangeInfoPayload.bslRangeId"/>
    </div>

    <div *ngIf="displayAllEditableData()">
      <app-odm-restricted-access-select
        [selectedAccess]="selectedAccess"
        (changeRestrictedAccess)="onChangeRestrictedAccess($event) ">
      </app-odm-restricted-access-select>
    </div>


    <div *ngIf="displayAllEditableData()">
      <h5 translate>T_OFFER_DATA_MANAGEMENT_CUSTOM_IMAGE</h5>

      <button type="button" class="btn btn-default-gray-background" (click)="imageInput.click()" translate>
        <em class="pi pi-download">&nbsp;</em>
        T_UPLOAD_IMAGE
        <input #imageInput type="file" (change)="imageUpload($event)" accept=".jpg, .jpeg" style="display:none"/>
      </button>
      <app-element-tile *ngIf="editRangeInfoPayload.customImageId"
                        [pictureFileId]="editRangeInfoPayload.customImageId"
                        [readOnly]="true"
                        [clickable]="false">
      </app-element-tile>

    </div>

    <div class="edit-range-modal-footer">
      <button class="btn btn-default-white-background" (click)="cancel()" translate>T_BUTTON_CANCEL</button>
      <button class="btn btn-default-white-background" [disabled]="!isValidForm()"
              (click)="clickOnSaveRangeButton(); hide()" translate>T_OFFER_DATA_MANAGEMENT_SAVE_RANGE_INFO_BUTTON
      </button>
    </div>
  </div>

</app-common-modal>

