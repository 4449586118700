/* Angular modules */
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
/* app modules */
import {Localization} from '../../shared/model/localization.model';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {LoggerService} from '../../shared/logging/logger.service';
import {Partner} from '../../shared/partner/partner';
import {Subject} from 'rxjs/Rx';
import {
  DocumentationCategory,
  DocumentationHelperService,
} from 'app/export/shared';
import {Documentation} from "../../shared/documentation/documentation.model";

@Component({
  selector: 'app-card-documentation',
  templateUrl: './card-documentation.component.html',
  styleUrls: ['./card-documentation.component.less']
})
export class CardDocumentationComponent implements OnInit {

  @ViewChild('docInfoModal')
  docInfoModal: CommonModalComponent;

  @Input()
  documentations: Array<Documentation> = [];

  @SessionStorage()
  user;

  @SessionStorage()
  localization: Localization;

  @SessionStorage()
  partner: Partner;

  @SessionStorage()
  noItemNavigationStep;

  readonly NO_LANGAGE = 'NONE';

  commercialDocuments: Array<Map<string, Documentation>> = [];
  staticPlans: Array<Map<string, Documentation>> = [];
  staticDatasheets: Array<Map<string, Documentation>> = [];
  staticManualDocuments: Array<Map<string, Documentation>> = [];
  referencesOfSelectedCubicles: Array<string> = [];
  cadFiles: Array<Map<string, Documentation>> = [];
  environment: Array<Map<string, Documentation>> = [];
  catalogs: Array<Map<string, Documentation>> = [];
  promotionMaterials: Array<Map<string, Documentation>> = [];
  technicalPublications: Array<Map<string, Documentation>> = [];
  applicationSolutions: Array<Map<string, Documentation>> = [];
  whitePapers: Array<Map<string, Documentation>> = [];
  certificates: Array<Map<string, Documentation>> = [];
  specifications: Array<Map<string, Documentation>> = [];
  training: Array<Map<string, Documentation>> = [];

  // True is we are on the project documentation page, False if we are on the configuration documentation page
  isProjectDocumentation: boolean;

  /* the current range type */
  documentChosenLanguage: string;

  constructor(private logger: LoggerService,
              private documentationHelperService: DocumentationHelperService) {
  }

  public ngOnInit(): void {
    this.logger.debug('DocumentationComponent init()');

    // The language is the language chosen on 'Settings' tab
    this.documentChosenLanguage = this.user.preferredLanguage;

    this.filterDocumentationByCategory(this.documentations);

  }


  /**
   * Allow to dispatch documents by category between arrays
   * @param documents
   */
  private filterDocumentationByCategory(documents: Array<Documentation>) {
    const commercialTechnicals = [];
    const staticPlans = [];
    const staticDatasheets = [];
    const staticManualDocuments = [];
    const cadFiles = [];
    const environment = [];
    const catalogs = [];
    const promotionMaterials = [];
    const technicalPublications = [];
    const applicationSolutions = [];
    const whitePapers = [];
    const certificates = [];
    const specifications = [];
    const training = [];


        documents.forEach(
      document => {
        if (document !== null && document !== undefined) {
          switch (document.category) {
            case DocumentationCategory.COMMERCIAL:
            case DocumentationCategory.TECHNICAL:
            case DocumentationCategory.COMMERCIAL_TECHNICAL_BSL:
            case DocumentationCategory.CGV:
              commercialTechnicals.push(document);
              break;
            case DocumentationCategory.TECHNICAL_DOC:
            case DocumentationCategory.TECHNICAL_PUBLICATIONS:
              technicalPublications.push(document);
              break;
            case DocumentationCategory.PLANS:
              // Added form ODM
              if (document.references === undefined || document.references === null) {
                staticPlans.push(document);
              } else {
                document.references.forEach(ref => {
                  if (this.referencesOfSelectedCubicles.includes(ref)) {
                    staticPlans.push(document);
                  }
                })
              }
              if (this.referencesOfSelectedCubicles.length === 0) {
                staticPlans.push(document);
              }
              break;
            case DocumentationCategory.ENVIRONMENT:
              staticPlans.push(document);
              break;
            case DocumentationCategory.DATASHEETS:
              staticDatasheets.push(document);
              break;
            case DocumentationCategory.USER_GUIDE:
              staticManualDocuments.push(document);
              break;
            case DocumentationCategory.CAD:
              cadFiles.push(document);
              break;
            case DocumentationCategory.CATALOGS:
              catalogs.push(document);
              break;
            case DocumentationCategory.PROMOTIONAL_MATERIALS:
              promotionMaterials.push(document);
              break;
            case DocumentationCategory.APPLICATIONS_SOLUTIONS:
              applicationSolutions.push(document);
              break;
            case DocumentationCategory.WHITE_PAPERS:
              whitePapers.push(document);
              break;
            case DocumentationCategory.CERTIFICATES:
              certificates.push(document);
              break;
            case DocumentationCategory.SPECIFICATIONS:
              specifications.push(document);
              break;
            case DocumentationCategory.TRAINING:
              training.push(document);
              break;
          }
        }
      });

    this.commercialDocuments = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(commercialTechnicals, this.commercialDocuments);
    this.staticPlans = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(staticPlans, this.staticPlans);
    this.staticDatasheets = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(staticDatasheets, this.staticDatasheets);
    this.staticManualDocuments = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(staticManualDocuments, this.staticManualDocuments);
    this.cadFiles = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(cadFiles, this.cadFiles);
    this.environment = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(environment, this.environment);
    this.catalogs = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(catalogs, this.catalogs);
    this.promotionMaterials = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(promotionMaterials, this.promotionMaterials);
    this.technicalPublications = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(technicalPublications, this.technicalPublications);
    this.applicationSolutions = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(applicationSolutions, this.applicationSolutions);
    this.whitePapers = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(whitePapers, this.whitePapers);
    this.certificates = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(certificates, this.certificates);
    this.specifications = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(specifications, this.specifications);
    this.training = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(training, this.training);
  }
}
