import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  AccessoryCategory,
  AccessoryCharacteristics,
  EditAccessoryCategory
} from '../../../../shared/model/accessory.model';
import {
  OdmModalCategoryNameComponent
} from '../odm-modal-category-name/odm-modal-category-name.component';
import {Pair} from '../../../../shared/util/pair';
import {SessionStorage} from 'ngx-webstorage';
import {User} from '../../../../shared/user/user';
import {Localization} from '../../../../shared/model/localization.model';
import {CommonModalComponent} from '../../../../shared/common-modal/common-modal.component';
import {CharacteristicValue} from '../../../../characteristics/shared/characteristics.model';
import {Range} from "../../../../shared/model/range-model";

@Component({
  selector: 'app-odm-manage-category',
  templateUrl: './odm-manage-category.component.html',
  styleUrls: ['./odm-manage-category.component.less']
})
export class OdmManageCategoryComponent {


  @ViewChild('changeNameModal') changeNameModal: OdmModalCategoryNameComponent;

  @ViewChild('deleteModal') deleteModal: CommonModalComponent;

  @ViewChild('cannotDeleteModal') cannotDeleteModal: CommonModalComponent;

  @SessionStorage()
  public localization: Localization;

  @SessionStorage()
  public user: User;

  @Input()
  category: AccessoryCategory;

  @Input()
  selectedRange: Range;

  @Input()
  accessories: AccessoryCharacteristics[];

  @Output()
  onDeleteCategory = new EventEmitter<string>();

  @Output()
  clickEditCategory: EventEmitter<EditAccessoryCategory> = new EventEmitter<EditAccessoryCategory>();

  @Output()
  clickEditAccessoryButton: EventEmitter<AccessoryCharacteristics> = new EventEmitter<AccessoryCharacteristics>();
  @Output()
  clickDeleteAccessoryButton: EventEmitter<AccessoryCharacteristics> = new EventEmitter<AccessoryCharacteristics>();

  @Output()
  clickEditAccessoryValueButton: EventEmitter<{accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue}> = new EventEmitter<{accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue}>();
  @Output()
  clickDeleteAccessoryValueButton: EventEmitter<{accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue}> = new EventEmitter<{accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue}>();

  @Output()
  changeAccessoryOrderEvent: EventEmitter<{accessoryId: string, orderIndex: number}> = new EventEmitter<{accessoryId: string; orderIndex: number}>();

  @Output()
  clickMoveDownAccessoryValueEvent: EventEmitter<{accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue}> = new EventEmitter<{accessory: AccessoryCharacteristics; accessoryValue: CharacteristicValue}>();
  @Output()
  clickMoveUpAccessoryValueEvent: EventEmitter<{accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue}> = new EventEmitter<{accessory: AccessoryCharacteristics; accessoryValue: CharacteristicValue}>();

  @Input()
  isExpanded = false;
  @Input()
  activeAccessory: string;

  constructor() { }

  onClickOpenCategoryButton() {
    this.isExpanded = !this.isExpanded
  }

  getAccessoriesByCategory(): AccessoryCharacteristics[] {
    return this.accessories
      .filter(accessory => accessory.category === this.category.id)
      .sort((accessoryA, accessoryB) => accessoryA.orderIndex - accessoryB.orderIndex)
      .map((accessory, index) => {
        accessory.orderIndex = index;
        return accessory;
      });
  }

  editName() {
    this.changeNameModal.show();
  }

  setCategoryName(translationByLanguage: Pair<string, string>[]) {
    let event: EditAccessoryCategory = new EditAccessoryCategory();
    event.nameKey = this.category.nameKey;
    event.translationByLanguage = translationByLanguage;
    this.clickEditCategory.emit(event);

  }

  deleteCategory() {
    if (this.getAccessoriesByCategory().length > 0) {
      this.cannotDeleteModal.show();
    } else {
      this.deleteModal.show();
    }
  }

  confirmDialog() {
    this.onDeleteCategory.emit(this.category.id);
  }

  onClickEditAccessoryButton(accessory: AccessoryCharacteristics) {
    this.clickEditAccessoryButton.emit(accessory);
  }

  onClickDeleteAccessoryButton(accessory: AccessoryCharacteristics) {
    this.clickDeleteAccessoryButton.emit(accessory);
  }

  onClickEditAccessoryValueButton(event) {
    this.clickEditAccessoryValueButton.emit(event);
  }

  onClickDeleteAccessoryValueButton(event) {
    this.clickDeleteAccessoryValueButton.emit(event);
  }

  onDragAndDropAccessory(categoryId: string, event) {
    let accessoriesByCategory = this.getAccessoriesByCategory();
    if(event.previousIndex != event.currentIndex){
      let accessoryId = accessoriesByCategory.find(accessory => accessory.category === categoryId && accessory.orderIndex === event.previousIndex).id;
        this.changeAccessoryOrderEvent.emit({ accessoryId, orderIndex: event.currentIndex });
    }
  }

  onClickMoveDownAccessoryValue(event) {
    this.clickMoveDownAccessoryValueEvent.emit(event);
  }
  onClickMoveUpAccessoryValue(event) {
    this.clickMoveUpAccessoryValueEvent.emit(event);
  }
}
