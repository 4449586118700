import {Component, Input} from '@angular/core';
import {Dimension} from '../../project/shared/project-model';

@Component({
  selector: 'app-dimensions',
  templateUrl: './dimensions.component.html',
  styleUrls: ['./dimensions.component.less']
})
export class DimensionsComponent {

  @Input()
  public dimension: Dimension;

  constructor() { }

  hasDimension(): boolean {
    return this.dimension !== null && this.dimension !== undefined &&
      this.dimension.width !== null && this.dimension.width !== undefined &&
      this.dimension.height !== null && this.dimension.height !== undefined ;
  }

}
