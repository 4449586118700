import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccessoryCharacteristics} from '../../../../shared/model/accessory.model';
import {CharacteristicValue} from '../../../../characteristics/shared/characteristics.model';
import {Range} from "../../../../shared/model/range-model";
import {RangeType} from "../../../../shared/model/range-type";

@Component({
  selector: 'app-odm-manage-accessory',
  templateUrl: './odm-manage-accessory.component.html',
  styleUrls: ['./odm-manage-accessory.component.less']
})
export class OdmManageAccessoryComponent {

  @Input()
  accessory: AccessoryCharacteristics;

  @Input()
  selectedRange: Range;

  @Output()
  clickEditAccessoryButton: EventEmitter<AccessoryCharacteristics> = new EventEmitter<AccessoryCharacteristics>();
  @Output()
  clickDeleteAccessoryButton: EventEmitter<AccessoryCharacteristics> = new EventEmitter<AccessoryCharacteristics>();

  @Output()
  clickEditAccessoryValueButton: EventEmitter<{
    accessory: AccessoryCharacteristics,
    accessoryValue: CharacteristicValue
  }> = new EventEmitter<{ accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue }>();
  @Output()
  clickDeleteAccessoryValueButton: EventEmitter<{
    accessory: AccessoryCharacteristics,
    accessoryValue: CharacteristicValue
  }> = new EventEmitter<{ accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue }>();

  @Output()
  clickMoveDownAccessoryValueEvent: EventEmitter<{
    accessory: AccessoryCharacteristics,
    accessoryValue: CharacteristicValue
  }> = new EventEmitter<{ accessory: AccessoryCharacteristics; accessoryValue: CharacteristicValue }>();
  @Output()
  clickMoveUpAccessoryValueEvent: EventEmitter<{
    accessory: AccessoryCharacteristics,
    accessoryValue: CharacteristicValue
  }> = new EventEmitter<{ accessory: AccessoryCharacteristics; accessoryValue: CharacteristicValue }>();

  @Input()
  isExpanded = false;


  onClickOpenAccessoryButton() {
    this.isExpanded = !this.isExpanded;
  }

  onClickEditAccessoryButton(accessory: AccessoryCharacteristics) {
    this.clickEditAccessoryButton.emit(accessory);
  }

  onClickDeleteAccessoryButton(accessory: AccessoryCharacteristics) {
    this.clickDeleteAccessoryButton.emit(accessory);
  }

  onClickEditAccessoryValueButton(accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue) {
    this.clickEditAccessoryValueButton.emit({accessory, accessoryValue});
  }

  onClickDeleteAccessoryValueButton(accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue) {
    this.clickDeleteAccessoryValueButton.emit({accessory, accessoryValue});
  }

  onClickMoveDownAccessoryValueButton(accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue) {
    this.clickMoveDownAccessoryValueEvent.emit({accessory, accessoryValue});
  }

  onClickMoveUpAccessoryValueButton(accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue) {
    this.clickMoveUpAccessoryValueEvent.emit({accessory, accessoryValue});
  }

  getDefaultValueName(accessory: AccessoryCharacteristics) {
    return accessory.defaultValue ? accessory.values.find(value => value.id == accessory.defaultValue).value : "";
  }

  getAccessoryValuesSorted() {
    return this.accessory.values.sort((a, b) => a.orderIndex - b.orderIndex);
  }

  isEditable(key?: string): boolean {
    return key === undefined
      ? this.accessory.values.length === 0 || this.accessory.values.every(value => value.value !== null)
      : !this.accessory.importedAccessory && key !== 'T_WITHOUT';
  }

  isDeletable(key: string): boolean {
    const isBudgetary = this.selectedRange?.rangeType === RangeType.BUDJETARY_QUOTE;
    return !this.accessory.importedAccessory && (key !== 'T_WITHOUT' || isBudgetary);
  }
}
