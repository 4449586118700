<div class="app-splash" >
  <div class="shadow splash-container">
    <div class="background"></div>

    <div class="transparent-div">
      <p><progressbar class="loading-bar" *ngIf="!hasConnectionError"></progressbar></p>
    </div>
    <div class="row menu">
      <div class=" col-md-8">
        <img class="logo" src="assets/images/ecoStruxure_logo_white.png"/>
        <div class="app-name">Power <span class="font-sub">Build - Contractors</span></div>
        <div class="version">Version {{commercialVersion}}</div>
      </div>
      <div class="col-md-4 error-message" *ngIf="hasConnectionError">
        <span class="error-message">Connection error. If the problem persists, please contact the administrator.</span>
        <div><button class="btn btn-retry" (click)="relogin()">Retry</button></div>
      </div>
    </div>
    <div class="splash-footer">
      <div class="footer-message">
        This application is protected by copyright law and international treaties<br/>
        &copy; 2024 Schneider Electric Industries SAS. All Rights Reserved.
      </div>
      <img class="footer-logo" src="assets/images/life_is_on_se_white.png"/>
    </div>
  </div>
</div>
