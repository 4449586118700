// bsl characteristic model
import {DocumentationCategory} from '../../../export/shared';
import {Reference} from '../../../configuration/shared/model/reference';

export class BslRangeCharacteristicsSummary {
  checked = false;
  disabled = false;
  name: string;
  key: string;
  category: string;
  values: string[];
  presentOnAllReferences: boolean;
  missingOnRef: string[];
}

export class BslRangeCharacteristicsSummaryMap {
  [key: string]: BslRangeCharacteristicsSummary;
}

export class RangeBslDocument {
  name: string;
  description: string;
  downloadUrl: string;
  extensions: string[];
  expressCategory?: DocumentationCategory;

  constructor(file: RangeBslDocument) {
    this.name = file.name;
    this.description = file.description;
    this.downloadUrl = file.downloadUrl;
    this.extensions = file.extensions;
  }
}

export class BslFileCheckbox extends RangeBslDocument {
  checked = false;
  disabled = false;

  constructor(bslFile: RangeBslDocument) {
    super(bslFile);
  }
}

export class BslFileCheckboxMap {
  [key: string]: { fileCheckbox: BslFileCheckbox, category: DocumentationCategory };
}

export class ReferenceView {
  reference: Reference;
  type: string;
  electricalCharacOk: boolean;
  electricalCharacMissing: string[];
  mainCharacOk: boolean;
  mainCharacMissing: string[];
  origin: any;
  mySEAvailability: boolean;
  mySEAvailabilityDate: Date;
  functionalUnitsSLDOk : boolean;
  functionalUnitsSLDMissing : string[];
}

