import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UtilService} from '../../shared/util/util.service';
import {TreeNode} from "primeng/api";
import {Item} from '../../project/shared/project-model';
import {ReferenceToOrder} from '../../configuration/shared/model/component';
import {RestrictedOrderingType} from '../../shared/model/range-model';

@Injectable()
export class BomHelper {

  constructor(private translateService: TranslateService,
              private utilService: UtilService) {
  }

  /**
   * Create on an array representing the columns of the BOM
   * @param {boolean} isDemoMode demo mode
   * @param {boolean} isCpqProject if it is a CPQ Project
   * @param {boolean} canApplyDiscount if user can apply a discount
   * @param {boolean} isMySEUser user has mySE account
   * @param {boolean} isPartnerNetPrice is partner net price displayed
   * @param {boolean} needToDisplayAvailability
   * @returns {Array<any>}
   */
  public getBOMColumns(isDemoMode: boolean,
                       isCpqProject: boolean,
                       canApplyDiscount: boolean,
                       isMySEUser: boolean,
                       isPartnerNetPrice: boolean,
                       needToDisplayAvailability: boolean
  ): Array<any> {
    let bomCols: Array<any> = [];


    if (isDemoMode) {
      bomCols = [
        {field: 'category', header: 'T_BOM_CATEGORY', className: 'text-left bom-category'},
        {field: 'ref', header: 'T_BOM_REFERENCE', className: 'text-left bom-break-word bom-text-overflow'},
        {field: 'description', header: 'T_BOM_DESCRIPTION', className: 'text-left bom-break-word'},
        {field: 'price', header: 'T_DEMO_VERSION_BOM_UNIT_PRICE', width: '10%', className: 'text-right bomPrice'},
        {field: 'count', header: 'T_BOM_QUANTITY', width: '10%', className: 'text-center bomPrice'},
        {field: 'deliveryTime', header: 'T_BOM_DELIVERY_TIME', width: '15%', className: 'text-right bomPrice'},
        {field: 'totalPrice', header: 'T_DEMO_VERSION_BOM_TOTAL_PRICE', width: '10%', className: 'text-right bomPrice'}
      ];
    } else if (isCpqProject) {
      bomCols = [
        {field: 'category', header: 'T_BOM_CATEGORY', className: 'text-left bom-category'},
        {field: 'ref', header: 'T_BOM_REFERENCE', className: 'text-left bom-break-word bom-text-overflow'},
        {field: 'description', header: 'T_BOM_DESCRIPTION', className: 'text-left bom-break-word'},
        {field: 'value', header: 'T_BOM_VALUE', width: '10%', className: 'text-right bom-text-overflow'},
        {field: 'count', header: 'T_BOM_QUANTITY', width: '10%', className: 'text-center bomPrice'},
        {field: 'deliveryTime', header: 'T_BOM_DELIVERY_TIME', width: '15%', className: 'text-right bomPrice'},
      ];
    } else if ((isMySEUser && this.utilService.displayMySENetPrice())) {
      bomCols = [
        {field: 'category', header: 'T_BOM_CATEGORY', className: 'text-left bom-category'},
        {field: 'ref', header: 'T_BOM_REFERENCE', className: 'text-left bom-break-word bom-text-overflow'},
        {field: 'description', header: 'T_BOM_DESCRIPTION', className: 'text-left bom-break-word'},
        {field: 'netPrice', header: 'T_BOM_UNIT_NET_PRICE', width: '10%', className: 'text-right bomPrice'},
        {field: 'count', header: 'T_BOM_QUANTITY', width: '10%', className: 'text-center bomPrice'},
        {field: 'deliveryTime', header: 'T_BOM_DELIVERY_TIME', width: '10%', className: 'text-right bomPrice'},
        {field: 'totalNetPrice', header: 'T_BOM_TOTAL_NET_PRICE', width: '10%', className: 'text-right bomPrice'},
      ];
      if (canApplyDiscount) {
        bomCols.push({
          field: 'discountedNetPrice',
          header: 'T_BOM_DISCOUNTED_PRICE',
          width: '10%',
          className: 'text-right bomPrice'
        });
      }
    } else if (isPartnerNetPrice) {
      bomCols = [
        {field: 'category', header: 'T_BOM_CATEGORY', className: 'text-left bom-category'},
        {field: 'ref', header: 'T_BOM_REFERENCE', className: 'text-left bom-break-word bom-text-overflow'},
        {field: 'description', header: 'T_BOM_DESCRIPTION', className: 'text-left bom-break-word'},
        {field: 'price', header: 'T_BOM_UNIT_PRICE', width: '10%', className: 'text-right bomPrice'},
        {field: 'partnerDiscount', header: 'T_BOM_PARTNER_DISCOUNT', width: '10%', className: 'text-right bomPrice'},
        {field: 'netPrice', header: 'T_BOM_UNIT_NET_PRICE', width: '10%', className: 'text-right bomPrice'},
        {field: 'count', header: 'T_BOM_QUANTITY', width: '10%', className: 'text-center bomPrice'},
        {field: 'deliveryTime', header: 'T_BOM_DELIVERY_TIME', width: '10%', className: 'text-right bomPrice'},
        {field: 'totalNetPrice', header: 'T_BOM_TOTAL_NET_PRICE', width: '10%', className: 'text-right bomPrice'},
      ];
    } else if (canApplyDiscount) {
      bomCols = [
        {field: 'category', header: 'T_BOM_CATEGORY', className: 'text-left bom-category'},
        {field: 'ref', header: 'T_BOM_REFERENCE', className: 'text-left bom-break-word bom-text-overflow'},
        {field: 'description', header: 'T_BOM_DESCRIPTION', className: 'text-left bom-break-word'},
        {field: 'price', header: 'T_BOM_UNIT_PRICE', width: '10%', className: 'text-right bomPrice'},
        {field: 'count', header: 'T_BOM_QUANTITY', width: '10%', className: 'text-center bomPrice'},
        {field: 'deliveryTime', header: 'T_BOM_DELIVERY_TIME', width: '15%', className: 'text-right bomPrice'},
        {field: 'totalPrice', header: 'T_BOM_TOTAL_PRICE', width: '10%', className: 'text-right bomPrice'},
        {field: 'discountedPrice', header: 'T_BOM_DISCOUNTED_PRICE', width: '10%', className: 'text-right bomPrice'}
      ];
    } else {
      bomCols = [
        {field: 'category', header: 'T_BOM_CATEGORY', className: 'text-left bom-category'},
        {field: 'ref', header: 'T_BOM_REFERENCE', className: 'text-left bom-break-word bom-text-overflow'},
        {field: 'description', header: 'T_BOM_DESCRIPTION', className: 'text-left bom-break-word'},
        {field: 'price', header: 'T_BOM_UNIT_PRICE', width: '10%', className: 'text-right bomPrice'},
        {field: 'count', header: 'T_BOM_QUANTITY', width: '10%', className: 'text-center bomPrice'},
        {field: 'deliveryTime', header: 'T_BOM_DELIVERY_TIME', width: '15%', className: 'text-right bomPrice'},
        {field: 'totalPrice', header: 'T_BOM_TOTAL_PRICE', width: '10%', className: 'text-right bomPrice'}
      ];
    }
    if (needToDisplayAvailability) {
      const indexDeliveryTimeCol = bomCols.findIndex(col => col.field === 'deliveryTime');
      bomCols.splice(indexDeliveryTimeCol, 0, {
        field: 'availableOnStock',
        header: 'T_BOM_AVAILABLE_ON_STOCK',
        width: '5%',
        className: 'text-center bomPrice'
      });
    }

    return bomCols;
  }


  /**
   * Create on an object representing the columns of the BOM to export
   * @param {boolean} isDemoMode user is not registered
   * @param {boolean} hasPrices the column price must be displayed
   * @param {boolean} canApplyDiscount apply national sales discount
   * @param {boolean} isMySEUser user has mySE account
   * @param {boolean} hasFoNetPrice if item has some valid Fo net price
   * @param localization user localization
   * @param {boolean} needToDisplayAvailability the column availability on stock must be displayed
   * @returns {any} one item columns
   */
  public getExportBOMColumns(isDemoMode: boolean,
                             hasPrices: boolean,
                             canApplyDiscount: boolean,
                             isMySEUser: boolean,
                             hasFoNetPrice: boolean,
                             localization,
                             needToDisplayAvailability: boolean): any {
    let bomCols: any;

    if (isDemoMode) {
      bomCols = {
        category: this.translateService.instant('T_BOM_CATEGORY'),
        ref: this.translateService.instant('T_BOM_REFERENCE'),
        description: this.translateService.instant('T_BOM_DESCRIPTION'),
        discountUnitPrice: this.translateService.instant('T_DEMO_VERSION_BOM_UNIT_PRICE'),
        count: this.translateService.instant('T_BOM_QUANTITY'),
        deliveryTime: this.translateService.instant('T_BOM_DELIVERY_TIME'),
        discountedPrice: this.translateService.instant('T_DEMO_VERSION_BOM_TOTAL_PRICE')
      };
    }
    if (hasPrices) {
      if (hasFoNetPrice || (isMySEUser && this.utilService.displayMySENetPrice())) {
        if (canApplyDiscount) {
          bomCols = {
            category: this.translateService.instant('T_BOM_CATEGORY'),
            ref: this.translateService.instant('T_BOM_REFERENCE'),
            description: this.translateService.instant('T_BOM_DESCRIPTION'),
            netPrice: this.translateService.instant('T_BOM_UNIT_PRICE', {currency: localization.displayedCurrency}),
            count: this.translateService.instant('T_BOM_QUANTITY'),
            deliveryTime: this.translateService.instant('T_BOM_DELIVERY_TIME'),
            totalNetPrice: this.translateService.instant('T_BOM_TOTAL_PRICE', {currency: localization.displayedCurrency}),
            discountedNetPrice: this.translateService.instant('T_BOM_DISCOUNTED_PRICE', {currency: localization.displayedCurrency})
          };
        } else {
          if (hasFoNetPrice && !isMySEUser) {
            bomCols = {
              category: this.translateService.instant('T_BOM_CATEGORY'),
              ref: this.translateService.instant('T_BOM_REFERENCE'),
              description: this.translateService.instant('T_BOM_DESCRIPTION'),
              unitPrice: this.translateService.instant('T_BOM_UNIT_PRICE', {currency: localization.displayedCurrency}),
              partnerDiscount: this.translateService.instant('T_BOM_PARTNER_DISCOUNT'),
              netPrice: this.translateService.instant('T_BOM_UNIT_NET_PRICE', {currency: localization.displayedCurrency}),
              count: this.translateService.instant('T_BOM_QUANTITY'),
              deliveryTime: this.translateService.instant('T_BOM_DELIVERY_TIME'),
              totalNetPrice: this.translateService.instant('T_BOM_TOTAL_PRICE', {currency: localization.displayedCurrency}),
            };
          } else {
            bomCols = {
              category: this.translateService.instant('T_BOM_CATEGORY'),
              ref: this.translateService.instant('T_BOM_REFERENCE'),
              description: this.translateService.instant('T_BOM_DESCRIPTION'),
              netPrice: this.translateService.instant('T_BOM_UNIT_PRICE', {currency: localization.displayedCurrency}),
              count: this.translateService.instant('T_BOM_QUANTITY'),
              deliveryTime: this.translateService.instant('T_BOM_DELIVERY_TIME'),
              totalNetPrice: this.translateService.instant('T_BOM_TOTAL_PRICE', {currency: localization.displayedCurrency}),
            };
          }
        }
      } else if (canApplyDiscount) {
        bomCols = {
          category: this.translateService.instant('T_BOM_CATEGORY'),
          ref: this.translateService.instant('T_BOM_REFERENCE'),
          description: this.translateService.instant('T_BOM_DESCRIPTION'),
          unitPrice: this.translateService.instant('T_BOM_UNIT_PRICE', {currency: localization.displayedCurrency}),
          count: this.translateService.instant('T_BOM_QUANTITY'),
          deliveryTime: this.translateService.instant('T_BOM_DELIVERY_TIME'),
          totalPrice: this.translateService.instant('T_BOM_TOTAL_PRICE', {currency: localization.displayedCurrency}),
          discountedPrice: this.translateService.instant('T_BOM_DISCOUNTED_PRICE', {currency: localization.displayedCurrency})
        };
      } else {
        bomCols = {
          category: this.translateService.instant('T_BOM_CATEGORY'),
          ref: this.translateService.instant('T_BOM_REFERENCE'),
          description: this.translateService.instant('T_BOM_DESCRIPTION'),
          unitPrice: this.translateService.instant('T_BOM_UNIT_PRICE', {currency: localization.displayedCurrency}),
          count: this.translateService.instant('T_BOM_QUANTITY'),
          deliveryTime: this.translateService.instant('T_BOM_DELIVERY_TIME'),
          discountedPrice: this.translateService.instant('T_BOM_TOTAL_PRICE', {currency: localization.displayedCurrency})
        };
      }
    } else {
      bomCols = {
        category: this.translateService.instant('T_BOM_CATEGORY'),
        ref: this.translateService.instant('T_BOM_REFERENCE'),
        description: this.translateService.instant('T_BOM_DESCRIPTION'),
        count: this.translateService.instant('T_BOM_QUANTITY'),
        deliveryTime: this.translateService.instant('T_BOM_DELIVERY_TIME')
      };
    }
    if (needToDisplayAvailability) {
      bomCols.availableOnStock = this.translateService.instant('T_BOM_AVAILABLE_ON_STOCK');
    }
    return bomCols;
  }

  /**
   * Creates on object representing the columns of the Project BOM to export
   * there are three new columns (configuration, status and category)but value column is deleted
   * @param {boolean} isDemoMode user is not registered
   * @param {boolean} hasPrices the column price must be displayed
   * @param {boolean} canApplyDiscount apply national sales discount
   * @param {boolean} isMySEUser user has mySE account
   * @param {boolean} hasFoNetPrice if item has some valid Fo net price
   * @param localization user localization
   * @param {boolean} needToDisplayAvailability the column availability on stock must be displayed
   * @returns {any} project columns
   */
  public getExportProjectBOMColumns(
    isDemoMode: boolean,
    hasPrices: boolean,
    canApplyDiscount: boolean,
    isMySEUser: boolean,
    hasFoNetPrice: boolean,
    localization,
    needToDisplayAvailability: boolean): any {
    let bomCols: any;
    if (isDemoMode) {
      bomCols = {
        configuration: this.translateService.instant('T_BOM_CONFIGURATION'),
        status: this.translateService.instant('T_BOM_STATUS'),
        category: this.translateService.instant('T_BOM_CATEGORY'),
        ref: this.translateService.instant('T_BOM_REFERENCE'),
        description: this.translateService.instant('T_BOM_DESCRIPTION'),
        discountUnitPrice: this.translateService.instant('T_DEMO_VERSION_BOM_UNIT_PRICE'),
        count: this.translateService.instant('T_BOM_QUANTITY'),
        deliveryTime: this.translateService.instant('T_BOM_DELIVERY_TIME'),
        discountedPrice: this.translateService.instant('T_DEMO_VERSION_BOM_TOTAL_PRICE')
      };
    }
    if (hasPrices) {
      if (isMySEUser && this.utilService.displayMySENetPrice()) {
        bomCols = {
          configuration: this.translateService.instant('T_BOM_CONFIGURATION'),
          status: this.translateService.instant('T_BOM_STATUS'),
          category: this.translateService.instant('T_BOM_CATEGORY'),
          ref: this.translateService.instant('T_BOM_REFERENCE'),
          description: this.translateService.instant('T_BOM_DESCRIPTION'),
          netPrice: this.translateService.instant('T_BOM_UNIT_PRICE', {currency: localization.displayedCurrency}),
          count: this.translateService.instant('T_BOM_QUANTITY'),
          deliveryTime: this.translateService.instant('T_BOM_DELIVERY_TIME'),
          discountedNetPrice: this.translateService.instant('T_BOM_TOTAL_PRICE', {currency: localization.displayedCurrency})
        };

      } else if (hasFoNetPrice) {
        bomCols = {
          configuration: this.translateService.instant('T_BOM_CONFIGURATION'),
          status: this.translateService.instant('T_BOM_STATUS'),
          category: this.translateService.instant('T_BOM_CATEGORY'),
          ref: this.translateService.instant('T_BOM_REFERENCE'),
          description: this.translateService.instant('T_BOM_DESCRIPTION'),
          unitPrice: this.translateService.instant('T_BOM_UNIT_PRICE', {currency: localization.displayedCurrency}),
          partnerDiscount: this.translateService.instant('T_BOM_PARTNER_DISCOUNT'),
          netPrice: this.translateService.instant('T_BOM_UNIT_NET_PRICE', {currency: localization.displayedCurrency}),
          count: this.translateService.instant('T_BOM_QUANTITY'),
          deliveryTime: this.translateService.instant('T_BOM_DELIVERY_TIME'),
          discountedNetPrice: this.translateService.instant('T_BOM_TOTAL_PRICE', {currency: localization.displayedCurrency})
        };
      } else if (canApplyDiscount) {
        bomCols = {
          configuration: this.translateService.instant('T_BOM_CONFIGURATION'),
          status: this.translateService.instant('T_BOM_STATUS'),
          category: this.translateService.instant('T_BOM_CATEGORY'),
          ref: this.translateService.instant('T_BOM_REFERENCE'),
          description: this.translateService.instant('T_BOM_DESCRIPTION'),
          unitPrice: this.translateService.instant('T_BOM_UNIT_PRICE', {currency: localization.displayedCurrency}),
          count: this.translateService.instant('T_BOM_QUANTITY'),
          deliveryTime: this.translateService.instant('T_BOM_DELIVERY_TIME'),
          totalPrice: this.translateService.instant('T_BOM_TOTAL_PRICE', {currency: localization.displayedCurrency}),
          discountedPrice: this.translateService.instant('T_BOM_DISCOUNTED_PRICE', {currency: localization.displayedCurrency})
        };
      } else {
        bomCols = {
          configuration: this.translateService.instant('T_BOM_CONFIGURATION'),
          status: this.translateService.instant('T_BOM_STATUS'),
          category: this.translateService.instant('T_BOM_CATEGORY'),
          ref: this.translateService.instant('T_BOM_REFERENCE'),
          description: this.translateService.instant('T_BOM_DESCRIPTION'),
          unitPrice: this.translateService.instant('T_BOM_UNIT_PRICE', {currency: localization.displayedCurrency}),
          count: this.translateService.instant('T_BOM_QUANTITY'),
          deliveryTime: this.translateService.instant('T_BOM_DELIVERY_TIME'),
          discountedPrice: this.translateService.instant('T_BOM_TOTAL_PRICE', {currency: localization.displayedCurrency})
        };
      }
    } else {
      bomCols = {
        configuration: this.translateService.instant('T_BOM_CONFIGURATION'),
        status: this.translateService.instant('T_BOM_STATUS'),
        category: this.translateService.instant('T_BOM_CATEGORY'),
        ref: this.translateService.instant('T_BOM_REFERENCE'),
        description: this.translateService.instant('T_BOM_DESCRIPTION'),
        count: this.translateService.instant('T_BOM_QUANTITY'),
        deliveryTime: this.translateService.instant('T_BOM_DELIVERY_TIME')
      };
    }
    if (needToDisplayAvailability) {
      bomCols.availableOnStock = this.translateService.instant('T_BOM_AVAILABLE_ON_STOCK');
    }
    return bomCols;
  }

  /**
   * Format node Of Bom tree node
   * @param node
   * @param isDemoMode
   */
  public formatTreeNode(node: TreeNode, isDemoMode: boolean) {
// cubicles options and functions
    node.children.forEach(val => {
        this.formatBomChild(val, isDemoMode);
        // set price in empty if demo mode
        if (isDemoMode) {
          val.data.totalPrice = '';
          val.data.totalNetPrice = '';
          val.data.price = '';
        }
        // functional unit options
        val.children.forEach(child => {
          this.formatBomChild(child, isDemoMode);
          // options details
          child.children.forEach(childOfOption => {
            this.formatBomChild(childOfOption, isDemoMode);
          });
        });
      }
    );
  }

  /**
   * Get all items to update
   * @param items
   * @param referencesToOrder
   */
  public getItemsToUpdate(items: Item[], referencesToOrder: ReferenceToOrder[]) {
    const itemsToUpdate: Item[] = [];
    items.forEach(item => {
      referencesToOrder.forEach(referenceToOrder => {
        if (this.getReferencesOfItem(item).some(reference => reference === referenceToOrder.reference)) {
          item.orderedReferences.push(referenceToOrder.reference);
          itemsToUpdate.push(item);
        }
      });
    });
    return itemsToUpdate;
  }

  /**
   * Get the ordering reference list
   * @param item
   * @param restrictedOrderingType
   * @param referenceToOrderList
   */
  public getOrderingReferenceList(item, restrictedOrderingType: RestrictedOrderingType, referenceToOrderList: ReferenceToOrder[]) {
    // cubicle or transformer component
    if (!!item.packageOfferId || restrictedOrderingType === item.range.restrictedOrderingType) {
      // accessories' references
      item.accessories.forEach(accessory => {
        if (accessory.reference.ref && item.orderedReferences && !item.orderedReferences.includes(accessory.reference.ref)) {
          this.addReferenceToList(accessory.reference.ref, item.range ? item.range.nameKey : 'T_PACKAGE_OFFER_NAME',
            accessory.quantity, accessory.reference.price, referenceToOrderList);
        }
      });
      // component references
      item.components.forEach(component => {
        if (component.reference.ref && item.orderedReferences && !item.orderedReferences.includes(component.reference.ref)) {
          this.addReferenceToList(component.reference.ref, item.range ? item.range.nameKey : 'T_PACKAGE_OFFER_NAME',
            component.quantity, component.reference.price, referenceToOrderList);
        }
        // component option references
        if (component.options && component.options.length > 0) {
          component.options.forEach(option => {
            if (option.values && option.values.length > 0) {
              option.values.forEach(value => {
                if (value.reference.ref && item.orderedReferences && !item.orderedReferences.includes(value.reference.ref)) {
                  this.addReferenceToList(value.reference.ref, item.range ? item.range.nameKey : 'T_PACKAGE_OFFER_NAME',
                    value.quantity, value.reference.price, referenceToOrderList);
                }
              });
            }
          });
        }
      });
      // service references
      item.selectedServices.forEach(selectedService => {
        if (selectedService.reference && selectedService.reference.ref && item.orderedReferences && !item.orderedReferences.includes(selectedService.reference.ref)) {
          this.addReferenceToList(selectedService.reference.ref, item.range ? item.range.nameKey : 'T_PACKAGE_OFFER_NAME',
            selectedService.quantity, selectedService.reference.price, referenceToOrderList);
        }
      });
    }
    // transformer component added in cubicle
    item.components.forEach(component => {
      if (component.functionalUnits && component.functionalUnits.length > 0) {
        component.functionalUnits.forEach(functionalUnit => {
          if (functionalUnit.transformerCompatible && functionalUnit.transformer && functionalUnit.transformer.reference &&
            item.orderedReferences && !item.orderedReferences.includes(functionalUnit.transformer.reference.ref)) {
            if (restrictedOrderingType === functionalUnit.restrictedOrderingType) {
              this.addReferenceToList(functionalUnit.transformer.reference.ref, functionalUnit.transformerRange, functionalUnit.transformer.quantity,
                functionalUnit.transformer.reference.price, referenceToOrderList);
            }
          }
        });
      }
    });
  }

  /**
   * Format data for child on parameter
   * @param child
   * @param isDemoMode
   */
  private formatBomChild(child, isDemoMode: boolean) {
    child.expanded = true;
    if (!!child.data.totalPrice) {
      if (child.data.price != null) {
        child.data.price = UtilService.formatPrice(child.data.price);
      }
      child.data.totalPrice = UtilService.formatPrice(child.data.totalPrice);
      child.data.discountedPrice = UtilService.formatPrice(child.data.discountedPrice);
      child.data.discountedNetPrice = UtilService.formatPrice(child.data.discountedNetPrice);
    } else if (child.data.price == 0) {
      child.data.price = this.translateService.instant('T_BOM_INCLUDED');
      child.data.totalPrice = this.translateService.instant('T_BOM_INCLUDED');
    }
    if (!!child.data.netPrice) {
      child.data.netPrice = UtilService.formatPrice(child.data.netPrice);
    } else if (child.data.netPrice == 0) {
      child.data.netPrice = this.translateService.instant('T_BOM_INCLUDED');
      child.data.totalNetPrice = this.translateService.instant('T_BOM_INCLUDED');
    }
    if (!!child.data.totalNetPrice && typeof child.data.totalNetPrice === 'number') {
      child.data.totalNetPrice = UtilService.formatPrice(child.data.totalNetPrice);
    }
    // set price in empty if demo mode
    if (isDemoMode) {
      child.data.totalPrice = '';
      child.data.totalNetPrice = '';
      child.data.price = '';
    }
  }

  /**
   * Add new reference to order in the list
   * @param reference
   * @param offer
   * @param quantity
   * @param price
   * @param referenceToOrderList
   */
  private addReferenceToList(reference: string, offer: string, quantity: number, price: number, referenceToOrderList: ReferenceToOrder[]) {
    const referenceAdding = referenceToOrderList.find(referenceToOrder =>
      referenceToOrder.reference === reference && referenceToOrder.offer === this.translateService.instant(offer));
    if (referenceAdding && referenceAdding.reference != null) {
      referenceAdding.quantity = referenceAdding.quantity + quantity;
      referenceAdding.price = Math.round((referenceAdding.price + price) * 100) / 100;
    } else {
      referenceToOrderList.push({
        reference: reference,
        offer: this.translateService.instant(offer),
        quantity: quantity,
        price: Math.round(price * quantity * 100) / 100
      });
    }
  }

  /**
   * Reference list
   * @param item
   */
  private getReferencesOfItem(item: Item) {
    const referenceList = [];
    item.accessories.forEach(accessory => {
      if (accessory.reference.ref) {
        referenceList.push(accessory.reference.ref);
      }
    });
    item.components.forEach(component => {
      if (component.reference.ref) {
        referenceList.push(component.reference.ref);
      }
      if (component.options && component.options.length > 0) {
        component.options.forEach(option => {
          if (option.values && option.values.length > 0) {
            option.values.forEach(value => {
              if (value.reference.ref) {
                referenceList.push(value.reference.ref);
              }
            });
          }
        });
      }
    });
    item.components.forEach(component => {
      if (component.functionalUnits && component.functionalUnits.length > 0) {
        component.functionalUnits.forEach(functionalUnit => {
          if (functionalUnit.transformerCompatible && functionalUnit.transformer && functionalUnit.transformer.reference) {
            referenceList.push(functionalUnit.transformer.reference.ref);
          }
        });
      }
    });
    item.selectedServices.forEach(selectedService => {
      if (selectedService.reference && selectedService.reference.ref) {
        referenceList.push(selectedService.reference.ref);
      }
    });
    return referenceList;
  }

}
