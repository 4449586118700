import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SessionStorage} from 'ngx-webstorage';
import {User} from '../../../../shared/user/user';
import {Range} from '../../../../shared/model/range-model';
import {OptionsService} from '../../../../shared/services/options.service';
import {
  CopyOptionPayload,
  CubicleOption,
  CubicleOptionValue,
  EditOptionPayload,
  EditOptionValuePayload,
} from '../../../../shared/model/cubicleOption-model';
import {Localization} from '../../../../shared/model/localization.model';
import {OdmEditOptionModalComponent} from '../odm-edit-option-modal/odm-edit-option-modal.component';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {CustomTranslateLoader} from '../../../../shared/localization/CustomTranslateLoader';
import {CommonModalComponent} from '../../../../shared/common-modal/common-modal.component';
import {OdmEditOptionValueModalComponent} from '../odm-edit-option-value-modal/odm-edit-option-value-modal.component';
import {RangeType} from '../../../../shared/model/range-type';
import {OdmCopyOptionModalComponent} from "../odm-copy-option-modal/odm-copy-option-modal.component";
import {Reference} from "../../../../configuration/shared/model/reference";
import {UploadService} from "../../../shared";
import {LoggerService} from "../../../../shared/logging/logger.service";

@Component({
  selector: 'app-odm-manage-options',
  templateUrl: './odm-manage-options.component.html',
  styleUrls: ['./odm-manage-options.component.scss']
})
export class OdmManageOptionsComponent implements OnInit {

  @SessionStorage()
  public user: User;

  @SessionStorage()
  localization: Localization;

  @Input()
  selectedRange: Range;

  @Input()
  isOption: boolean;

  @Output()
  clickBack: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('addOptionModal') addOptionModal: OdmEditOptionModalComponent;
  @ViewChild('editOptionModal') editOptionModal: OdmEditOptionModalComponent;
  @ViewChild('deleteOptionModal') deleteOptionModal: CommonModalComponent;
  @ViewChild('copyOptionModal') copyOptionModal: OdmCopyOptionModalComponent;

  @ViewChild('addOptionValueModal') addOptionValueModal: OdmEditOptionValueModalComponent;
  @ViewChild('editOptionValueModal') editOptionValueModal: OdmEditOptionValueModalComponent;
  @ViewChild('deleteOptionValueModal') deleteOptionValueModal: CommonModalComponent;


  isLoading = false;
  isExportLoading = false;
  optionsByReference: Map<string, CubicleOption[]>;
  references: Reference[] = [];
  displayedReferences: Reference[] = [];
  activeReference: string;
  activeOption: string;
  optionNameToDelete: string;
  optionValueNameToDelete: string;
  searchValue: string;
  searchPlaceholder: string;


  constructor(private readonly optionsService: OptionsService,
              private readonly messageService: MessageService,
              private readonly customTranslateLoader: CustomTranslateLoader,
              private readonly translateService: TranslateService,
              private readonly uploadService: UploadService,
              private readonly logger: LoggerService,) {
  }

  ngOnInit(): void {
    this.getOptions();
    this.searchPlaceholder = this.translateService.instant("T_SEARCH");
  }

  returnOnOfferSelection() {
    this.clickBack.emit();
  }

  getOptions() {
    this.isLoading = true;
    this.optionsService.getOptionsByRangeId(this.selectedRange.id, true).subscribe(payload => {
      const sortedOptionsByReference = new Map<string, CubicleOption[]>();
      Object.keys(payload.optionsByReferences).sort().forEach(key => {

        payload.optionsByReferences[key] = payload.optionsByReferences[key].sort((a, b) => {
          return a.orderIndex - b.orderIndex;
        });
        // Sort values by reference
        payload.optionsByReferences[key].forEach(option =>
          option.values = option.values.sort((a, b) => {
            if (a.reference === null && b.reference === null) return 0;
            if (a.reference === null) return 1;
            if (b.reference === null) return -1;
            return a.reference.ref.localeCompare(b.reference.ref);
          })
        );
        sortedOptionsByReference[key] = payload.optionsByReferences[key];
      });

      this.optionsByReference = sortedOptionsByReference;
      this.references = payload.references.sort((a, b) => a.ref.localeCompare(b.ref));
      this.displayedReferences = this.references;
      this.isLoading = false;
    }, () => this.isLoading = false)
  }

  onSuccessEditingOption() {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('T_INFO'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_EDITING_OPTION'),
    });

    this.customTranslateLoader.getTranslation(this.user.preferredLanguage, true).subscribe(res => {
      this.translateService.setTranslation(this.user.preferredLanguage, res, false);
      this.getOptions();
    });
  }

  onErrorEditingOption(error) {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_ERROR'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_EDITING_OPTION') + ': ' + error,
    });
    this.getOptions();
  }

  onSuccessDeletingOption() {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('T_INFO'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_DELETING_OPTION'),
    });
    this.optionNameToDelete = null;
    this.getOptions();
  }

  onErrorDeletingOption(error) {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_ERROR'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_DELETING_OPTION') + ': ' + error,
    });
    this.optionNameToDelete = null;
    this.getOptions();
  }

  onClickAddOptionButton() {
    const languages = this.localization.langs.map(lang => lang.language);
    this.addOptionModal.showAddMode(languages, this.showOnPopUpManagement());
  }

  onClickAddOptionValueButton() {
    this.addOptionValueModal.showAddMode(this.optionsByReference);
  }


  onClickEditOptionValueButton(event: { option: CubicleOption, optionValue: CubicleOptionValue }, reference: string) {
    this.editOptionValueModal.showEditMode(event.option, event.optionValue, reference);
  }


  addOption(addOptionForm: EditOptionPayload) {
    this.isLoading = true;
    this.activeReference = addOptionForm.reference;
    this.activeOption = null;
    addOptionForm.rangeId = this.selectedRange.id;
    this.optionsService.createOption(addOptionForm).subscribe(() => {
      this.onSuccessEditingOption();
      this.activeOption = addOptionForm.nameKey;
    }, (error) => {
      this.onErrorEditingOption(error);
    });
  }

  editOption(editOptionPayload: EditOptionPayload) {
    this.isLoading = true;
    this.activeReference = editOptionPayload.reference;
    editOptionPayload.rangeId = this.selectedRange.id;
    this.activeOption = null;
    this.optionsService.editOption(editOptionPayload).subscribe(() => {
      this.onSuccessEditingOption();
    }, (error) => {
      this.onErrorEditingOption(error);
    });
  }

  copyOption(copyOptionPayload: CopyOptionPayload) {
    this.isLoading = true;
    this.activeOption = null;
    this.optionsService.copyOption(copyOptionPayload).subscribe(() => {
      this.onSuccessEditingOption();
    }, (error) => {
      this.onErrorEditingOption(error);
    });
  }

  deleteOption() {
    this.isLoading = true;
    this.activeOption = null;
    this.optionsService.deleteOption(this.selectedRange.id, this.activeReference, this.optionNameToDelete).subscribe(() => {
      this.onSuccessDeletingOption();
    }, (error) => {
      this.onErrorDeletingOption(error);
    });
  }

  addOptionValue(editOptionValuePayload: EditOptionValuePayload) {
    this.isLoading = true;
    this.activeReference = editOptionValuePayload.reference;
    this.activeOption = editOptionValuePayload.optionNameKey;
    this.optionsService.addOptionValue(editOptionValuePayload).subscribe(() => {
      this.onSuccessEditingOption();
    }, (error) => {
      this.onErrorEditingOption(error);
    });
  }

  editOptionValue(editOptionValuePayload: EditOptionValuePayload) {
    this.isLoading = true;
    this.activeReference = editOptionValuePayload.reference;
    this.activeOption = editOptionValuePayload.optionNameKey;
    this.optionsService.editOptionValue(editOptionValuePayload).subscribe(() => {
      this.onSuccessEditingOption();
    }, (error) => {
      this.onErrorEditingOption(error);
    });
  }

  deleteOptionValue() {
    this.isLoading = true;
    this.activeOption = this.optionNameToDelete;
    this.optionsService.deleteOptionValue(this.selectedRange.id, this.activeReference, this.optionNameToDelete, this.optionValueNameToDelete).subscribe(() => {
      this.onSuccessDeletingOption();
    }, (error) => {
      this.onErrorDeletingOption(error);
    });
  }

  onClickEditOptionButton(option: CubicleOption, reference: string) {
    this.editOptionModal.showEditMode(option, reference, this.showOnPopUpManagement());
  }

  onClickDeleteOptionButton(option: CubicleOption, reference: string) {
    this.optionNameToDelete = option.name;
    this.activeReference = reference;
    this.deleteOptionModal.show();
  }

  onClickCopyOptionButton(option: CubicleOption, reference: string) {
    this.activeReference = reference;
    this.copyOptionModal.show(option, reference);
  }

  onClickDeleteOptionValueButton(event: { option: CubicleOption, optionValue: CubicleOptionValue }, reference: string) {
    this.optionNameToDelete = event.option.name;
    this.optionValueNameToDelete = event.optionValue.value;
    this.activeReference = reference;
    this.deleteOptionValueModal.show();
  }

  onClickExportOptionsButton() {
    this.logger.info('Export options of range: ' + this.translateService.instant(this.selectedRange.nameKey));
    this.isExportLoading = true;
    this.optionsService.exportOptionsOfRange(this.selectedRange.id).subscribe(
      response => {
        this.isExportLoading = false;
        this.uploadService.createXlsFileFromBackendResponse(response);
      }, () => {
        this.isExportLoading = false;
      }
    );

  }

  showOnPopUpManagement(): boolean {
    return this.selectedRange.rangeType != RangeType.TRANSFORMER;
  }

  searchInputChanged() {
    if (!this.references)
      return;

    this.displayedReferences = this.references.filter(reference => {
      // check ref name
      if (reference.ref.toLowerCase().includes(this.searchValue.toLowerCase()))
        return true;
      // check options
      let options: CubicleOption[] = this.optionsByReference[reference.ref];
      return options.some(opt => {
        // check option name
        if (this.translateService.instant(opt.name).toLowerCase().includes(this.searchValue.toLowerCase()))
          return true;
        // check option values
        return opt.values.some(value => {
          // check option value name
          if (this.translateService.instant(value.value).toLowerCase().includes(this.searchValue.toLowerCase()))
            return true;
          // check option value ref
          if (value.reference && value.reference.ref && value.reference.ref.toLowerCase().includes(this.searchValue.toLowerCase()))
            return true;

          return false;
        })
      })
    })
  }

  onChangeOptionsOrder(event: { option: CubicleOption; currentIndex: number }, ref: string) {
    this.activeOption =event.option.name;
    this.isLoading = true;
    this.activeReference =ref;
    this.optionsService.editOptionsOrder(this.selectedRange.id,this.activeReference , this.activeOption ,event.currentIndex).subscribe(() => {
      this.onSuccessEditingOption();
    }, (error) => {
      this.onErrorEditingOption(error);
    })
  }
}
