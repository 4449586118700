import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  CubicleOption,
  CubicleOptionValue
} from '../../../../shared/model/cubicleOption-model';

@Component({
  selector: 'app-odm-options-list-references',
  templateUrl: './odm-options-list-references.component.html',
  styleUrls: ['./odm-options-list-references.component.less']
})
export class OdmOptionsListReferencesComponent {

  @Input()
  reference: string;

  @Input()
  options: CubicleOption[];

  @Input()
  isExpanded = false;

  @Input()
  activeOption: string;

  @Input()
  showOnPopUpStatus: boolean = true;

  @Input()
  option: CubicleOption;

  @Output()
  clickEditOptionButton: EventEmitter<CubicleOption> = new EventEmitter<CubicleOption>();
  @Output()
  clickDeleteOptionButton: EventEmitter<CubicleOption> = new EventEmitter<CubicleOption>();
  @Output()
  clickCopyOptionButton: EventEmitter<CubicleOption> = new EventEmitter<CubicleOption>();
  @Output()
  clickEditOptionValueButton: EventEmitter<{option: CubicleOption, optionValue: CubicleOptionValue}> = new EventEmitter<{option: CubicleOption, optionValue: CubicleOptionValue}>();
  @Output()
  clickDeleteOptionValueButton: EventEmitter<{option: CubicleOption, optionValue: CubicleOptionValue}> = new EventEmitter<{option: CubicleOption, optionValue: CubicleOptionValue}>();
  @Output()
  changeOptionsOrderEvent: EventEmitter<{option: CubicleOption,currentIndex: number}> = new EventEmitter<{option: CubicleOption, currentIndex: number}>();

  constructor() { }


  onClickOpenReferenceButton() {
    this.isExpanded = !this.isExpanded
  }

  onClickEditOptionButton(option: CubicleOption) {
    this.clickEditOptionButton.emit(option);
  }

  onClickDeleteOptionButton(option: CubicleOption) {
    this.clickDeleteOptionButton.emit(option);
  }

  onClickCopyOptionButton(option: CubicleOption) {
    this.clickCopyOptionButton.emit(option);
  }

  onClickEditOptionValueButton(event: { option: CubicleOption, optionValue: CubicleOptionValue }) {
    this.clickEditOptionValueButton.emit(event);
  }

  onClickDeleteOptionValueButton(event: { option: CubicleOption, optionValue: CubicleOptionValue }) {
    this.clickDeleteOptionValueButton.emit(event);
  }

  onDragAndDropOptions(event: any, options: CubicleOption[]) {
    if (event.previousIndex !== event.currentIndex) {
      let selectedOption = options.find(opt => {
        if (opt.orderIndex === event.previousIndex) {
          return opt;
        }
      });
      this.changeOptionsOrderEvent.emit({
        option: selectedOption,
        currentIndex: event.currentIndex,
      });
    }
  }
}
