/* Angular modules */
import {Component, Input, OnInit} from '@angular/core';
/* ngx modules */
import {TranslateService} from '@ngx-translate/core';
import {SessionStorage} from 'ngx-webstorage';
/* 3rd parties libraries */
import {MessageService} from 'primeng/api';
/* app modules */
import {Documentation} from '../../shared/documentation/documentation.model';
import {
  DocumentationCategory,
  DocumentationHelperService,
  DocumentationService,
  DocumentExtension,
  DocumentInformations,
  StaticDocumentationService
} from '../shared';
import {Item, Project} from 'app/project/shared/project-model';
import {Localization} from '../../shared/model/localization.model';
import {LoggerService} from '../../shared/logging/logger.service';
import {isNullOrUndefined, isUndefined} from 'util';
import {BomService} from '../../bom/shared';
import {ItemService} from '../../project/shared/item.service';
import {User} from '../../shared/user/user';
import {RightsService} from '../../shared/rights/rights.service';
import {ProjectService} from '../../project/shared/project.service';
import {FileService} from '../../admin/shared';
import {File} from '../../admin/shared/file/file.model';
import {FileDownloadEventDatalayerService} from '../../shared/google-analytics/file-download-event-datalayer.service';


@Component({
  selector: 'app-documentation-list',
  templateUrl: './documentation-list.component.html',
  styleUrls: ['./documentation-list.component.less'],
})
export class DocumentationListComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  documentations: Array<Map<string, Documentation>> = [];

  @Input()
  documentChosenLanguage: string;

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  user: User;

  @SessionStorage()
  localization: Localization;

  @SessionStorage()
  documentInformations: DocumentInformations;

  @SessionStorage()
  maxDeliveryTime;

  projectItems: Item[];

  // True is we are on the project documentation page, False if we are on the configuration documentation page
  isProjectDocumentation: boolean;


  isQuoteSegment: boolean;

  constructor(private logger: LoggerService,
              private translateService: TranslateService,
              private documentationService: DocumentationService,
              private fileService: FileService,
              private staticDocService: StaticDocumentationService,
              private messageService: MessageService,
              private itemService: ItemService,
              private documentationHelperService: DocumentationHelperService,
              private bomService: BomService,
              private fileDownloadEventDatalayerService: FileDownloadEventDatalayerService) {
  }

  ngOnInit(): void {
    this.isProjectDocumentation = !this.currentItemct;
    this.isQuoteSegment = this.title === 'T_BOM_DOCUMENTATION_QUOTE';
    this.itemService.getItems(this.currentProject.id)
      .subscribe(items => this.projectItems = items);
  }

  /**
   * return true if the document is available in preferred language
   *
   * @param {Map<string, Documentation>} document
   * @returns {boolean}
   */
  public isDocumentAvailable(document: Map<string, Documentation>): boolean {
    return document.has(this.documentChosenLanguage);
  }

  /**
   *  If the document is available on the language chosen on the 'Settings', retrieve it
   *  Contrary, if it is present on any other language retrieve it in one of available language
   *
   * @param {Map<string, Documentation>} document
   * @returns {Documentation}
   */
  public getAvailableDocumentByLanguage(document: Map<string, Documentation>): Documentation {
    if (document.has(this.documentChosenLanguage)) {
      return document.get(this.documentChosenLanguage);
    }
    return document.values().next().value;
  }

  /**
   * Return document type for icon
   *
   * @param {Map<string, Documentation>} document
   * @returns {boolean} true if pdf
   */
  public isPdf(document: Map<string, Documentation>): boolean {
    return this.isDocumentAvailable(document) ?
      document.get(this.documentChosenLanguage).extension === DocumentExtension.PDF :
      document.values().next().value.extension === DocumentExtension.PDF; // extention is identical for all entries
  }

  /**
   * Return document type for icon
   *
   * @param {Map<string, Documentation>} document
   * @returns {boolean} true if docx
   */
  public isWord(document: Map<string, Documentation>): boolean {
    return this.isDocumentAvailable(document) ?
      document.get(this.documentChosenLanguage).extension === DocumentExtension.DOCX :
      document.values().next().value.extension === DocumentExtension.DOCX; // extention is identical for all entries
  }

  /**
   * Return document type for icon
   *
   * @param {Map<string, Documentation>} document
   * @returns {boolean} true if zip
   */
  public isZip(document: Map<string, Documentation>): boolean {

    return this.isDocumentAvailable(document) ?
      document.get(this.documentChosenLanguage).extension === DocumentExtension.ZIP :
      document.values().next().value.extension === DocumentExtension.ZIP; // extention is identical for all entries

  }

  /**
   * check if the document is an external link
   * @param {Map<string, Documentation>} document
   * @returns {boolean}
   */
  public isExternalLink(document: Map<string, Documentation>): boolean {
    return !isNullOrUndefined(document.get(this.documentChosenLanguage))
      && document.get(this.documentChosenLanguage).link !== '' &&
      (!this.isPdf(document) && !this.isWord(document) && !this.isZip(document));
  }

  /**
   * Return document type for icon, by default
   *
   * @param {Map<string, Documentation>} document
   * @returns {boolean} true if pdf
   */
  public isUnknown(document: Map<string, Documentation>): boolean {
    return !this.isPdf(document) && !this.isWord(document) && !this.isZip(document) && !this.isExternalLink(document);
  }

  public hasExtensionName(): boolean {
    let ret = false;

    this.documentations.forEach(map =>
      map.forEach(doc => {
        if (!isUndefined(doc.extension)
          && doc.extension !== DocumentExtension.DOCX && doc.extension !== DocumentExtension.LINK) {
          ret = true;
        }
      })
    );

    return ret;
  }

  /**
   * Download a document (dynamic / static, with or without link)
   *
   * @param {Map<string, Documentation>} document
   */
  public downloadFile(document: Map<string, Documentation>) {
    const doc = this.getAvailableDocumentByLanguage(document);
    this.logger.info('CardDocumentationListComponent downloadFile : ' + doc.title);
    this.fileDownloadEventDatalayerService.FileDownloadEvent(doc);
    this.documentations.find(map => map === document).get(doc.language).downloadInProgress = true;

    if (DocumentationHelperService.isDynamicDocumentation(doc)) {
      if (this.currentItemct !== null && this.currentItemct !== undefined) {
        // Update references if we are not on project BOM
        this.bomService.extractReferencesToArray(this.currentItemct, false);
        this.itemService.updateItem(this.currentItemct, this.user)
          .subscribe(() => this.generateDocumentation(doc.language, document));
      } else {
        this.generateDocumentation(doc.language, document);
      }
    } else {
      this.downloadDocument(doc);
    }
  }

  /**
   * Get document's title
   *
   * @param {Map<string, Documentation>} document
   * @returns {string}
   */
  public getTitle(document: Map<string, Documentation>) {
    if (this.isDocumentAvailable(document)) {
      if (document.get(this.documentChosenLanguage).category === DocumentationCategory.ORDER) {
        return this.translateService.instant(document.get(this.documentChosenLanguage).title) + ' - ' +
          new Date(document.get(this.documentChosenLanguage).orderInformation.orderDate).toUTCString();
      }
      return document.get(this.documentChosenLanguage).title;
    } else {
      return document.values().next().value.title; // title is identical for all entries;
    }
  }

  /**
   * Get document's extention
   *
   * @param {Map<string, Documentation>} document
   * @returns {string}
   */
  public getExtension(document: Map<string, Documentation>) {
    return this.isDocumentAvailable(document) ?
      document.get(this.documentChosenLanguage).extension :
      document.values().next().value.extension;
  }

  /**
   * Return true if download is in progress for a document
   * @param document(
   * @returns {boolean}
   */
  public isDownloadInProgress(document: Map<string, Documentation>): boolean {
    return this.getAvailableDocumentByLanguage(document).downloadInProgress;
  }

  /**
   * Get document's size
   *
   * @param {Map<string, Documentation>} document
   * @returns {string}
   */
  public getSize(document: Map<string, Documentation>) {
    return document.get(this.documentChosenLanguage) != null && document.get(this.documentChosenLanguage).size !== null ?
      document.get(this.documentChosenLanguage).size : this.getAvailableDocumentByLanguage(document).size;
  }

  public hasBeenDownloaded(doc: Map<string, Documentation>): boolean {
    if (!this.documentInformations) {
      return false;
    }
    return this.documentInformations.downloadedDoc.includes(doc.values().next().value.id);
  }

  public isAdditionalQuoteDocumentation(): boolean {
    return this.isQuoteSegment && this.documentations && this.documentations.length > 0;
  }

  /**
   * Allow to download document when it is stored on the application
   * or to open a link on the browser when it is stored elsewhere
   * @param {Documentation} document
   */
  private downloadDocument(document: Documentation) {
    if (!document.link || document.link === '') {
      if (this.isProjectDocumentation && document.category === DocumentationCategory.PROJECT_COMMERCIAL) {
        // Download project document
        this.staticDocService.generateProjectDocument(
          this.currentProject.id,
          this.localization.country,
          document.language,
          document.fileName,
          document.stamperInformation,
          true,
          this.maxDeliveryTime)
          .subscribe(
            response => {
              this.manageSuccessfullDownloadDocument(document, response);
            },
            (error) => {
              this.manageErrorDownloadDocument(document, error);
            }
          );
      } else {
        if (document.localFileId && document.localFileId !== '') {
          this.fileService.get(document.localFileId)
            .subscribe(
              file => {
                this.manageSuccessfullyDownloadLocalDocument(document, file);

              },
              (error) => {
                this.manageErrorDownloadDocument(document, error);
              }
            );
        } else {
          // Item document
          this.staticDocService.generateStaticDocument(
            this.currentProject.id,
            this.currentItemct.id,
            this.localization.country,
            document.language,
            document.fileName,
            document.stamperInformation)
            .subscribe(
              response => {
                this.manageSuccessfullDownloadDocument(document, response);
              },
              (error) => {
                this.manageErrorDownloadDocument(document, error);
              }
            );
        }
      }
    }
    if (document.link && document.link !== '') {
      window.open(document.link);
      // Set document as downloaded
      if (this.documentInformations && this.documentInformations.downloadedDoc != null && !this.documentInformations.downloadedDoc.includes(document.id)) {
        this.documentInformations.downloadedDoc.push(document.id);
        this.documentationService.addDownloadedDoc(this.currentProject.id, this.currentItemct ? this.currentItemct.id : this.currentProject.id, document.id)
          .subscribe(result => this.documentInformations = result);
      }
      // Stop spinner from spinning
      document.downloadInProgress = false;
    }
  }

  /**
   * Method called after a successfull download of a document
   * @param document
   * @param response
   */
  private manageSuccessfullDownloadDocument(document, response) {
    document.downloadInProgress = false;
    this.documentationHelperService.createDocumentFromBackendResponse(
      this.currentItemct ? this.currentItemct.id : this.currentProject.id,
      this.currentProject.id,
      document.id,
      this.documentInformations,
      response);
  }

  /**
   * Method called after a successfull download of a document
   * @param document
   * @param file
   */
  private manageSuccessfullyDownloadLocalDocument(document: Documentation, file: File) {
    this.fileService.downloadBase64File(document.extension, file.data, document.fileName + '.' + document.extension);
    document.downloadInProgress = false;
    this.documentationHelperService.updateDocumentInformation(
      this.currentItemct ? this.currentItemct.id : this.currentProject.id,
      this.currentProject.id,
      document.id,
      this.documentInformations);
  }

  /**
   * Method called after an error downloading a document
   * @param document
   * @param error
   */
  private manageErrorDownloadDocument(document, error) {
    this.logger.error('download static document ' + document.fileName + error.toString());
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_ERROR'),
      detail: this.translateService.instant('T_EXPORT_STATIC_ERROR'),
    });
    document.downloadInProgress = false;
  }

  /**
   * Allows to download dynamic document generated on backend
   * @param {string} language ISO code of the country
   * @param {Map<string, Documentation>} document documents sorted by language
   * @returns {Subscription}
   */
  private generateDocumentation(language: string, document: Map<string, Documentation>) {
    const doc = document.get(language);
    const isPDFFormat = doc.extension === DocumentExtension.PDF;
    switch (doc.category) {
      case DocumentationCategory.COMMERCIAL:
        return this.documentationService
          .generateCommercialBID(this.currentProject.id, this.currentItemct, language, isPDFFormat)
          .subscribe(
            response => {
              this.documentationHelperService.createDocumentFromBackendResponse(this.currentItemct.id, this.currentProject.id,
                doc.id, this.documentInformations, response);
            },
            error => {
              this.documentations.find(map => map === document).get(language).downloadInProgress = false;
              this.logger.error(error.status + ' ' + error.statusText);
            },
            () => this.documentations.find(map => map === document).get(language).downloadInProgress = false);
      case DocumentationCategory.TECHNICAL:
        return this.documentationService
          .generateTechnicalBID(
            this.currentProject.id, this.currentItemct, language, isPDFFormat)
          .subscribe(response => {
              this.documentationHelperService.createDocumentFromBackendResponse(this.currentItemct.id, this.currentProject.id,
                doc.id, this.documentInformations, response);
            },
            error => {
              this.documentations.find(map => map === document).get(language).downloadInProgress = false;
              this.logger.error(error.status + ' ' + error.statusText);
            },
            () => this.documentations.find(map => map === document).get(language).downloadInProgress = false);
      case DocumentationCategory.COMMERCIAL_TECHNICAL:
        return this.documentationService
          .generateCommercialAndTechnicalBID(
            this.currentProject.id, this.currentItemct, language, isPDFFormat)
          .subscribe(response => {
              this.documentationHelperService.createDocumentFromBackendResponse(this.currentItemct.id, this.currentProject.id,
                doc.id, this.documentInformations, response);
            },
            error => {
              this.documentations.find(map => map === document).get(language).downloadInProgress = false;
              this.logger.error(error.status + ' ' + error.statusText);
            },
            () => this.documentations.find(map => map === document).get(language).downloadInProgress = false);
      case DocumentationCategory.ORDER:
        return this.documentationService
          .generatePurchaseOrder(this.currentProject.id, language, isPDFFormat, 'doc', doc.orderInformation.orderDate)
          .subscribe(response => {
              this.documentationHelperService.createDocumentFromBackendResponse((this.currentItemct !== null) ? this.currentItemct.id : null,
                this.currentProject.id,
                doc.id, this.documentInformations, response);
            },
            error => {
              this.documentations.find(map => map === document).get(language).downloadInProgress = false;
              this.logger.error(error.status + ' ' + error.statusText);
            },
            () => this.documentations.find(map => map === document).get(language).downloadInProgress = false);
      case DocumentationCategory.CGV:
        return this.documentationService
          .generateCGV(isPDFFormat)
          .subscribe(response => {
              this.documentationHelperService.createDocumentFromBackendResponse(this.currentItemct.id, this.currentProject.id,
                doc.id, this.documentInformations, response);
            },
            error => {
              this.documentations.find(map => map === document).get(language).downloadInProgress = false;
              this.logger.error(error.status + ' ' + error.statusText);
            },
            () => this.documentations.find(map => map === document).get(language).downloadInProgress = false);
    }
  }
}
