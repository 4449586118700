import {Injectable} from '@angular/core';
import { Translation, TranslationStatusEnum} from '../../../shared/model/localization.model';
import {isNullOrUndefined} from 'util';

@Injectable()
export class TranslationHelperService {

  constructor() {}

  getTradPercentage(translations, keyNumber): number {
    // calculate translation coverage percentage
    let translateNumber = 0;
    if (!isNullOrUndefined(translations) && translations.length > 0) {
      translations.find(translation => {
        if (!isNullOrUndefined(translation.translation) && translation.translation.length > 0) {
          translateNumber = translateNumber + 1;
        }
      });
    }
    return translateNumber * 100 / keyNumber;
  }

}
