<div>
  <div *ngIf="!isLoading">
    <div *ngIf="getStandardRanges().length > 0">
      <h1 class="title" translate>T_CHOOSE_OFFER</h1>
      <div class="offers-container">
        <app-element-tile *ngFor="let range of getStandardRanges()"
                          [readOnly]="!isPossibleToSelect(range)"
                          [name]="range.nameKey"
                          [pictureUrl]="range.rangePicture?.url"
                          [pictureFileId]="range.rangePicture?.imageFileId"
                          (clickOnTile)="addProduct(range)">
        </app-element-tile>
        <span *ngIf="!showPackageOfferGroupSelection()">
          <app-element-tile *ngFor="let packageOffer of packageOffers"
                            [readOnly]="!isPossibleToSelectPackage()"
                            [name]="'T_PACKAGE_OFFER_NAME'"
                            [pictureUrl]="'./assets/images/package.png'"
                            (clickOnTile)="addPackageOffer(packageOffer)">
          </app-element-tile>
        </span>
      </div>
    </div>
    <div *ngIf="showPackageOfferGroupSelection()">
      <app-package-offer-group-selector
        [packageOffers]="packageOffers"
        [packageOfferGroups]="packageOfferGroups"
        [selectedPackageOfferGroup]="selectedPackageOfferGroup"
        (packageOfferSelected)="addPackageOffer($event)">
      </app-package-offer-group-selector>
    </div>
    <div *ngIf="getBudgetaryQuoteRanges().length > 0">
      <h1 class="title" translate>T_BUDGETARY_QUOTE_OFFER</h1>
      <div class="offers-container">
        <app-element-tile *ngFor="let range of  getBudgetaryQuoteRanges()"
                          [readOnly]="!isPossibleToSelect(range)"
                          [name]="range.nameKey"
                          [pictureUrl]="range.rangePicture?.url"
                          [pictureFileId]="range.rangePicture?.imageFileId"
                          (clickOnTile)="addProduct(range)">
        </app-element-tile>
      </div>
    </div>
  </div>
  <app-spinner *ngIf="isLoading" [sizeClass]="'large'"></app-spinner>
</div>

<!-- confirm dialog -->
<app-common-modal #childModal [name]="'childModal'" [title]="'T_LOOSE_DATA_TITLE' | translate"
                  [description]="'T_LOOSE_DATA' | translate" (onClickYesButton)="confirmLooseData()" [size]="'modal-sm'">
</app-common-modal>
