/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
/* app modules */
import {LoggerService} from '../logging/logger.service';
import {
  CopyOptionPayload,
  CubicleOption,
  EditOptionValuePayload,
  GetOptionsPayload
} from '../model/cubicleOption-model';
import {
  EditOptionForm
} from '../../admin/offer-data-management/odm-options-management/odm-edit-option-modal/odm-edit-option-modal.component';
import {TranslationPayload} from "../model/translation-payload";

@Injectable()
export class OptionsService {

  constructor(private httpClient: HttpClient,
              private logger: LoggerService) {
  }

  getOptionsByRangeId(rangeId, forceUpdate: boolean = false): Observable<GetOptionsPayload> {
    this.logger.debug('OptionsService getOptionsByRangeId()');
    return this.httpClient.cache(forceUpdate).get<GetOptionsPayload>('/options/' + rangeId );
  }

  createOption(editOptionForm: EditOptionForm): Observable<GetOptionsPayload> {
    return this.httpClient.post<GetOptionsPayload>('/options', editOptionForm);
  }

  editOption(editOptionForm: EditOptionForm): Observable<GetOptionsPayload> {
    return this.httpClient.put<GetOptionsPayload>('/options', editOptionForm);
  }

  copyOption(copyOptionPayload: CopyOptionPayload): Observable<GetOptionsPayload> {
    return this.httpClient.put<GetOptionsPayload>('/options/copy', copyOptionPayload);
  }

  deleteOption(rangeId: string, reference: string, name: string): Observable<any> {
    return this.httpClient.delete<any>('/options/'+ rangeId + '/' + reference + '/' + name);
  }

  addOptionValue(addOptionValueForm: EditOptionValuePayload) : Observable<GetOptionsPayload> {
    return this.httpClient.post<GetOptionsPayload>('/options/value', addOptionValueForm);
  }

  editOptionValue(addOptionValueForm: EditOptionValuePayload) : Observable<GetOptionsPayload> {
    return this.httpClient.put<GetOptionsPayload>('/options/value', addOptionValueForm);
  }

  deleteOptionValue(rangeId: string, reference: string, optionName: string, valueName: string): Observable<any> {
    return this.httpClient.delete<any>('/options/'+ rangeId + '/' + reference + '/' + optionName + '/' + valueName);
  }
  getOptionValueTranslations(rangeId: string, reference: string): Observable<TranslationPayload> {
    return this.httpClient.get<TranslationPayload>('/offer-data-management/' + rangeId + '/'  + reference + '/value/translations');
  }
  getOptionDescriptionTranslations(rangeId: string, reference: string): Observable<TranslationPayload[]> {
    return this.httpClient.get<TranslationPayload[]>('/offer-data-management/' + rangeId + '/'  + reference + '/nameAndDescription/translations');
  }

  exportOptionsOfRange(rangeId: string): Observable<any> {
    return this.httpClient.post('/options/export/'+ rangeId, '',{responseType: 'blob', observe: 'response'});
  }

  editOptionsOrder(rangeId: string, reference: string,optionName: string, currentIndex: number) {
    return this.httpClient.put<any>('/options/order/' + rangeId + '/' + reference  + '/' + optionName + '/' + currentIndex, {});
  }
}
