import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BslRangeCharacteristicsSummary,
  BslRangeCharacteristicsSummaryMap
} from '../../../../shared/bsl-reference/offer-data-management-model';
import {Range} from '../../../../../shared/model/range-model';
import {OfferDataManagementHelper} from '../../../../shared/util/offer-data-management-helper';
import * as _ from 'lodash';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {RangeType} from "../../../../../shared/model/range-type";

@Component({
  selector: 'app-odm-add-offer-characteristics',
  templateUrl: './odm-add-offer-characteristics.component.html',
  styleUrls: ['./odm-add-offer-characteristics.component.less']
})
export class OdmAddOfferCharacteristicsComponent implements OnInit {

  @Input()
  bslCharacteristicsMap: BslRangeCharacteristicsSummaryMap;

  @Input()
  selectedRange: Range;

  @Output()
  nextActiveChange: EventEmitter<void> = new EventEmitter<void>();

  selectedCharacteristics: BslRangeCharacteristicsSummary[];

  hideSreenValue: boolean;

  nonMainCharac: string[] = [];

  constructor() {
  }

  ngOnInit() {
    this.bslCharacteristicsMap = OfferDataManagementHelper.initBslCharacteristicsMap(this.bslCharacteristicsMap, this.selectedRange.electricalCharacteristicsFilters);
    this.nonMainCharac = Object.values(this.bslCharacteristicsMap).filter(char => !char.presentOnAllReferences).map(char => char.key);
    if (this.selectedRange.electricalCharacteristicsFilters) {
      this.selectedCharacteristics = this.electricalCharacteristics(this.selectedRange.electricalCharacteristicsFilters);
    }
    if (this.selectedRange.hideCharacteristicScreen) {
      this.hideSreenValue = true;
      this.bslCharacteristicsMap = OfferDataManagementHelper.disableBslCharacteristicsMap(this.bslCharacteristicsMap);
    }
  }

  selectedElectricalCharacteristics(characteristics: BslRangeCharacteristicsSummary[]) {
    this.updateCharacteristics(characteristics);
    if (this.selectedCharacteristics) {
      this.selectedRange.electricalCharacteristicsFilters = this.selectedCharacteristics.map(ch =>
        this.selectedRange.rangeType === RangeType.BUDJETARY_QUOTE ? ch.key : this.selectedRange.nameKey + '_' + ch.key
      );
      this.nextActiveChange.emit();
    }
  }

  /**
   * This function hide checkbox
   * @param checkbox
   */
  hideScreen(checkbox) {
    if (checkbox.checked) {
      this.bslCharacteristicsMap = OfferDataManagementHelper.disableBslCharacteristicsMap(this.bslCharacteristicsMap);
      this.selectedRange.electricalCharacteristicsFilters = [];
      this.selectedRange.hideCharacteristicScreen = true;
      this.selectedCharacteristics = [];
    } else {
      this.bslCharacteristicsMap = OfferDataManagementHelper.enableBslCharacteristicsMap(this.bslCharacteristicsMap);
      this.selectedRange.hideCharacteristicScreen = false;
    }
    this.nextActiveChange.emit();
  }

  /**
   * Method to reorder list when user drop an item
   * @param event
   */
  drop(event: CdkDragDrop<BslRangeCharacteristicsSummary[]>) {
    moveItemInArray(this.selectedCharacteristics, event.previousIndex, event.currentIndex);
    this.selectedRange.electricalCharacteristicsFilters = this.selectedCharacteristics.map(ch =>
      this.selectedRange.rangeType === RangeType.BUDJETARY_QUOTE ? ch.key : this.selectedRange.nameKey + '_' + ch.key);
  }
  private electricalCharacteristics(elecCharacs: string[]): BslRangeCharacteristicsSummary[] {
    const characteristics = [];
    elecCharacs.forEach(elecCharac => {
      const key = this.selectedRange.rangeType === RangeType.BUDJETARY_QUOTE ? elecCharac : elecCharac.substring(this.selectedRange.nameKey.length + 1);
      const bslRangeCharacteristicsSummary = _.find(_.values(this.bslCharacteristicsMap), { key });
      if (bslRangeCharacteristicsSummary) {
        bslRangeCharacteristicsSummary.checked = true;
        characteristics.push(bslRangeCharacteristicsSummary);
      }
    });
    return characteristics;
  }

  /**
   * Update selected characteristics regarding new characteristics (keep old order)
   * @param characteristics
   */
  private updateCharacteristics(characteristics: BslRangeCharacteristicsSummary[]) {
    // Firs, we remove old characteristics
    this.selectedCharacteristics = this.selectedCharacteristics.filter(characteristic => this.isSelected(characteristic, characteristics));
    // Then we add at the end of the array, new characteristic
    characteristics.filter(characteristic => characteristic.checked &&
      !this.isSelected(characteristic, this.selectedCharacteristics)).forEach(
      characteristic => this.selectedCharacteristics.push(characteristic)
    );
  }

  /**
   * Return true if characteristic is present and checked in characteristics array
   * @param characteristic
   * @param characteristics
   */
  private isSelected(characteristic: BslRangeCharacteristicsSummary, characteristics: BslRangeCharacteristicsSummary[]): boolean {
    return characteristics.filter(charac => (charac.key === characteristic.key && charac.checked)).length > 0;
  }
}
