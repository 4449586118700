<p-menu #menu [popup]="true" [model]="actions"></p-menu>

<div class="{{customClass}}" [ngClass]="{'cell-offer-image text-center img-rounded box-shadow':boxShadow, 'unclickable': readOnly || !clickable}" (click)="click()" tooltip="{{optionalTooltip | translate}}">

  <div *ngIf="readOnly" class="not-selectionable"></div>

  <!-- ELEMENT NAME -->
  <div class="offer-box" *ngIf="name">
    <span class="offer-name">{{name | translate }}</span>
    <button *ngIf="actionsMenu" type="button" pButton icon="pi pi-bars" class="action-menu-open-button" (click)="menu.toggle($event)"></button>
  </div>


  <div class="offer-image-wrapper">
    <!-- ELEMENT IMAGE FROM URL -->
    <img *ngIf="!pictureFileId && !!pictureUrl" [src]="pictureUrl" class="offer-image"/>

    <!-- ELEMENT IMAGE FROM ASSETS -->
    <img *ngIf="!pictureFileId && !pictureUrl && !!picturesAssetsPath" [src]="'./assets/images/' + picturesAssetsPath" class="offer-image"/>

    <!-- ELEMENT IMAGE FROM BASE64 string -->
    <img *ngIf="!!pictureFileId && !!pictureBase64" [src]="getImageFromBase64(pictureBase64)" class="offer-image"/>

    <!-- NO ELEMENT IMAGE FOUND OR GIVEN -->
    <button *ngIf="!pictureUrl && !picturesAssetsPath && !pictureBase64" class="se-icons offer-image no-image">media_Picture1</button>

  </div>

  <!-- STATUS LABEL FOR DATA MANAGEMENT -->
  <div *ngIf="status">
    <div class="status-label" [ngClass]="{'label-darkgrey':status === 'NO_REFERENCE', 'label-green':status === 'PUBLISHED',
    'label-blue':status === 'CONFIGURED', 'label-yellow':status === 'AVAILABLE', 'label-grey':status === 'TEST','label-lightyellow':status === 'IMPORTED'}">
      {{status === 'NO_REFERENCE' ? 'AVAILABLE' : status}}
    </div>
  </div>
</div>
