import {NavigationLinkEnum} from './navigationLink-enum';
import {RangeType} from '../model/range-type';
import {NavigationStep} from './navigationStep-enum';
import {SimplifiedRange} from '../model/simplifiedRange-model';
import * as _ from 'lodash';

export class NavigationRoute {
  public static readonly SWITCHBOARD_STEPS = [
    {id: NavigationStep.PROJECTS, title: 'T_NAV_PROJECTS', link: '/', activate: 'true'},
    {id: NavigationStep.OFFERS_CT, title: 'T_NAV_OFFERS', link: NavigationLinkEnum.OFFERS_CT, activate: 'true'},
    {id: NavigationStep.CHARACTERISTICS, title: 'T_NAV_CHARACTERISTICS', link: NavigationLinkEnum.CHARACTERISTICS, activate: 'true'},
    {id: NavigationStep.CONFIGURATION, title: 'T_NAV_CONFIGURATION', link: NavigationLinkEnum.CONFIGURATION, activate: 'true'},
    {id: NavigationStep.SERVICES_CATALOG, title: 'T_NAV_SERVICES_CATALOG', link: NavigationLinkEnum.SERVICES_CATALOG, activate: 'true'},
    {id: NavigationStep.BILL_OF_MATERIALS, title: 'T_NAV_QUOTE', link: NavigationLinkEnum.BILL_OF_MATERIALS, activate: 'true'},
    {id: NavigationStep.EXPORT, title: 'T_NAV_EXPORT_CT', link: NavigationLinkEnum.EXPORT, activate: 'true'}
  ];

  public static readonly TRANSFORMER_STEPS =  [
    {id: NavigationStep.PROJECTS, title: 'T_NAV_PROJECTS', link: NavigationLinkEnum.PROJECTS, activate: 'true'},
    {id: NavigationStep.OFFERS_CT, title: 'T_NAV_OFFERS', link: NavigationLinkEnum.OFFERS_CT, activate: 'true'},
    {id: NavigationStep.CHARACTERISTICS, title: 'T_NAV_CHARACTERISTICS', link: NavigationLinkEnum.CHARACTERISTICS, activate: 'true'},
    {id: NavigationStep.TRANSFORMER, title: 'T_NAV_TRANSFORMER', link: NavigationLinkEnum.TRANSFORMER, activate: 'true'},
    {id: NavigationStep.TRANSFORMER_SELECTOR, title: 'T_NAV_TRANSFORMER', link: NavigationLinkEnum.TRANSFORMER_SELECTOR, activate: 'true'},
    {id: NavigationStep.SERVICES_CATALOG, title: 'T_NAV_SERVICES_CATALOG', link: NavigationLinkEnum.SERVICES_CATALOG, activate: 'true'},
    {id: NavigationStep.BILL_OF_MATERIALS, title: 'T_NAV_QUOTE', link:  NavigationLinkEnum.BILL_OF_MATERIALS, activate: 'true'},
    {id: NavigationStep.EXPORT, title: 'T_NAV_EXPORT_CT', link: NavigationLinkEnum.EXPORT, activate: 'true'}
  ];

  public static readonly ADMIN_STEPS =  [
    {id: 1, title: 'T_NAV_ADMIN_PRICE_MANAGEMENT', link: NavigationLinkEnum.PRICE_MANAGEMENT, activate: 'true'},
    {id: 2, title: 'T_NAV_ADMIN_PRICE_BY_PARTNER', link: NavigationLinkEnum.PRICE_BY_PARTNER, activate: 'true'},
    {id: 3, title: 'T_NAV_ADMIN_CONTACT_MANAGEMENT', link: NavigationLinkEnum.CONTACT_MANAGEMENT, activate: 'true'},
    {id: 4, title: 'T_NAV_ADMIN_USER_MANAGEMENT', link: NavigationLinkEnum.USER_MANAGEMENT, activate: 'true'},
    {id: 5, title: 'T_NAV_ADMIN_TRANSLATION', link: NavigationLinkEnum.TRANSLATION, activate: 'true'},
    {id: 6, title: 'T_NAV_ADMIN_DISTRIBUTOR', link: NavigationLinkEnum.DISTRIBUTOR_MANAGEMENT, activate: 'true'},
    {id: 7, title: 'T_NAV_ADMIN_OFFER_DATA_MANAGEMENT', link: NavigationLinkEnum.OFFER_DATA_MANAGEMENT, activate: 'true'},
    {id: 8, title: 'T_NAV_ADMIN_PACKAGE_MANAGEMENT', link: NavigationLinkEnum.PACKAGE_MANAGEMENT, activate: 'true'},
    {id: 9, title: 'T_NAV_ADMIN_UP_SELLING_TRANSFORMER', link: NavigationLinkEnum.UP_SELLING_TRANSFORMER, activate: 'true'},
    {id: 10, title: 'T_NAV_ADMIN_SERVICES', link: NavigationLinkEnum.SERVICES, activate: 'true'},
    {id: 11, title: 'T_NAV_ADMIN_TRIAL_CONFIGURATION', link: NavigationLinkEnum.TRIAL_CONFIGURATION, activate: 'true'},
  ];

  public static readonly NO_ITEM_STEPS =  [
    {id: NavigationStep.PROJECTS, title: 'T_NAV_PROJECTS', link: NavigationLinkEnum.PROJECTS, activate: 'true'},
    {id: NavigationStep.OFFERS_CT, title: 'T_NAV_OFFERS', link: NavigationLinkEnum.OFFERS_CT, activate: 'true'}
  ];

  public static readonly READ_ONLY_PROJECT_STEPS =  [
    {id: NavigationStep.PROJECTS, title: 'T_NAV_PROJECTS', link: NavigationLinkEnum.PROJECTS, activate: 'true'},
    {id: NavigationStep.BILL_OF_MATERIALS, title: 'T_NAV_QUOTE', link: NavigationLinkEnum.BILL_OF_MATERIALS, activate: 'true'}
  ];

  public static readonly PROJECT_BOM_STEPS =  [
    {id: NavigationStep.PROJECTS, title: 'T_NAV_PROJECTS', link: NavigationLinkEnum.PROJECTS, activate: 'true'},
    {id: NavigationStep.PROJECT_BILL_OF_MATERIALS, title: 'T_NAV_QUOTE_PROJECT_BOM', link:  NavigationLinkEnum.PROJECT_BILL_OF_MATERIALS, activate: 'true'},
    {id: NavigationStep.PROJECT_DOCUMENTATION, title: 'T_NAV_PROJECT_DOCUMENTATION', link:  NavigationLinkEnum.PROJECT_DOCUMENTATION, activate: 'true'}
  ];

  public static readonly READ_ONLY_PROJECT_BOM_STEPS =  [
    {id: NavigationStep.PROJECTS, title: 'T_NAV_PROJECTS', link: NavigationLinkEnum.PROJECTS, activate: 'true'},
    {id: NavigationStep.PROJECT_BILL_OF_MATERIALS, title: 'T_NAV_QUOTE_PROJECT_BOM', link:  NavigationLinkEnum.PROJECT_BILL_OF_MATERIALS, activate: 'true'}
  ];

  public static readonly ACCESSORIES_STEPS =  [
    {id: NavigationStep.PROJECTS, title: 'T_NAV_PROJECTS', link: NavigationLinkEnum.PROJECTS, activate: 'true'},
    {id: NavigationStep.OFFERS_CT, title: 'T_NAV_OFFERS', link: NavigationLinkEnum.OFFERS_CT, activate: 'true'},
    {id: NavigationStep.CHARACTERISTICS, title: 'T_NAV_CHARACTERISTICS', link: NavigationLinkEnum.CHARACTERISTICS, activate: 'true'},
    {id: NavigationStep.BILL_OF_MATERIALS, title: 'T_NAV_QUOTE', link:  NavigationLinkEnum.BILL_OF_MATERIALS, activate: 'true'},
    {id: NavigationStep.EXPORT, title: 'T_NAV_EXPORT_CT', link: NavigationLinkEnum.EXPORT, activate: 'true'}
  ];

  public static readonly PACKAGE_OFFER_STEPS = [
    {id: NavigationStep.PROJECTS, title: 'T_NAV_PROJECTS', link: '/', activate: 'true'},
    {id: NavigationStep.OFFERS_CT, title: 'T_NAV_OFFERS', link: NavigationLinkEnum.OFFERS_CT, activate: 'true'},
    {id: NavigationStep.PACKAGE_OFFER, title: 'T_NAV_PACKAGE_OFFER', link: NavigationLinkEnum.PACKAGE_OFFER, activate: 'true'},
    {id: NavigationStep.BILL_OF_MATERIALS, title: 'T_NAV_QUOTE', link: NavigationLinkEnum.BILL_OF_MATERIALS, activate: 'true'},
    {id: NavigationStep.EXPORT, title: 'T_NAV_EXPORT_CT', link: NavigationLinkEnum.EXPORT, activate: 'true'}
  ];

  public static readonly PROJECT_CONFIGURATION_LINKS_LIST = [
    NavigationLinkEnum.CHARACTERISTICS,
    NavigationLinkEnum.CONFIGURATION,
    NavigationLinkEnum.TRANSFORMER,
    NavigationLinkEnum.TRANSFORMER_SELECTOR,
    NavigationLinkEnum.SERVICES_CATALOG,
    NavigationLinkEnum.PACKAGE_OFFER
  ];

  /**
   * Used to determine set of steps of the navbar from the range type
   * @param {RangeType} range the range selected
   * @param isReadonlyProject flag to know if editing steps should be show
   * @returns set of steps
   */
  public static getRouteByRange(range: SimplifiedRange, isReadonlyProject?: boolean ): {id: NavigationStep, title: string, link: string, activate: string}[] {
    if (isReadonlyProject) {
      return Array.from(this.READ_ONLY_PROJECT_STEPS);
    }

    if (!range) {
      return this.PACKAGE_OFFER_STEPS;
    }

    switch (range.rangeType) {
      case RangeType.ACCESSORIES:
      case RangeType.FAKE:
        return this.ACCESSORIES_STEPS;
      case RangeType.SWITCHBOARD:
      case RangeType.BUDJETARY_QUOTE:
        const switchboardRoute = Array.from(this.SWITCHBOARD_STEPS);

        // characteristics screen yes/no
        if (range.hideCharacteristicScreen) {
          _.remove(switchboardRoute, step => step.id === NavigationStep.CHARACTERISTICS);
        }

        // service catalog screen yes/no
        if (!range.services || range.services.length === 0) {
          _.remove(switchboardRoute, step => step.id === NavigationStep.SERVICES_CATALOG);
        }
        return switchboardRoute;
      case RangeType.TRANSFORMER:
        const transformerRoute = Array.from(this.TRANSFORMER_STEPS);

        // transformer configurator or transformer selector
        range.useTransformerSelector ? _.remove(transformerRoute, step => step.id === NavigationStep.TRANSFORMER) :
          _.remove(transformerRoute, step => step.id === NavigationStep.TRANSFORMER_SELECTOR);

        // characteristics screen yes/no
        if (range.hideCharacteristicScreen) {
          _.remove(transformerRoute, step => step.id === NavigationStep.CHARACTERISTICS);
        }

        // service catalog screen yes/no
        if (!range.services || range.services.length === 0) {
          _.remove(transformerRoute, step => step.id === NavigationStep.SERVICES_CATALOG);
        }

        return transformerRoute;
    }
  }

  /**
   * Used to determine next step
   * @param {RangeType} range the range selected
   * @param {NavigationStep} currentStep the range selected
   * @returns the next step
   */
  public static getNextStepByRange(range: SimplifiedRange, currentStep: NavigationStep) {
    const routeByRange = this.getRouteByRange(range);
    const currentStepIndex = routeByRange.findIndex(step => Number(step.id) === Number(currentStep));
    return routeByRange[currentStepIndex + 1];
  }

  /**
   * Used to determine next step
   * @param {RangeType} range the range selected
   * @param {NavigationStep} currentStep the range selected
   * @returns the next step
   */
  public static getNextStepByPackageOffer(currentStep: NavigationStep) {
    const routeByRange = this.getRouteByRange(null);
    const currentStepIndex = routeByRange.findIndex(step => Number(step.id) === Number(currentStep));
    return routeByRange[currentStepIndex + 1];
  }

  /**
   * Used to determine next step
   * @param {RangeType} range the range selected
   * @param {NavigationStep} currentStep the range selected
   * @param {NavigationStep} maxStep of item
   * @returns the next or max step
   */
  public static getNextStepWithMaxByRange(range: SimplifiedRange, currentStep: NavigationStep, maxStep: NavigationStep) {
    const routeByRange = this.getRouteByRange(range);
    const currentStepIndex = routeByRange.findIndex(step => Number(step.id) === Number(currentStep));
    const maxStepIndex = routeByRange.findIndex(step => Number(step.id) === Number(maxStep));
    return  maxStepIndex > currentStepIndex ? routeByRange[maxStepIndex] : routeByRange[currentStepIndex + 1] ;
  }


  /**
   * Used to determine next step
   * @param {RangeType} range the range selected
   * @param {NavigationStep} currentStep the range selected
   * @returns the previous step
   */
  public static getPreviousStepByRange(range: SimplifiedRange, currentStep: NavigationStep) {
    const routeByRange = this.getRouteByRange(range);
    const currentStepIndex = routeByRange.findIndex(step => Number(step.id) === Number(currentStep));
    return routeByRange[currentStepIndex - 1];
  }
}

