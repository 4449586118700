import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Range} from '../../../shared/model/range-model';
import {SessionStorage} from 'ngx-webstorage';
import {User} from '../../../shared/user/user';
import {EditRangeInfoPayload} from '../shared/odm-range-management-modal/odm-range-management-modal.component';
import {RangeType} from '../../../shared/model/range-type';
import {NavigationSidePanelItem} from "../../../layout/navigation-side-panel/navigation-side-panel.component";
import {environment} from "../../../../environments/environment";
import {
  TransformRangeInfoPayload
} from "../shared/odm-transform-to-selector-range-modal/odm-transform-to-selector-range-modal.component";

@Component({
  selector: 'app-odm-offer-selection',
  templateUrl: './odm-offer-selection.component.html',
  styleUrls: ['./odm-offer-selection.component.less']
})
export class OdmOfferSelectionComponent {


  @SessionStorage()
  user: User;

  @Input()
  ranges: Range[] = [];

  @Input()
  availableRanges: Range[] = [];

  @Input()
  odmLoading: boolean;

  @Input()
  bslLoading: boolean;

  @Input()
  loadingImportExport: boolean;

  @Output()
  clickOnComponentManagement: EventEmitter<Range> = new EventEmitter<Range>();

  @Output()
  clickOnRangeConfiguration: EventEmitter<Range> = new EventEmitter<Range>();

  @Output()
  clickOnRangeAddition: EventEmitter<Range> = new EventEmitter<Range>();

  @Output()
  onChangeRangeOrder: EventEmitter<Range[]> = new EventEmitter<Range[]>();

  @Output()
  onDecommissionRange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  clickOnAccessoriesManagement: EventEmitter<Range> = new EventEmitter<Range>();

  @Output()
  clickOnOptionsManagement: EventEmitter<Range> = new EventEmitter<Range>();

  @Output()
  onClickRangeExport: EventEmitter<String> = new EventEmitter<String>();

  @Output()
  onClickRangeImport: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  saveRangeInfo: EventEmitter<EditRangeInfoPayload> = new EventEmitter<EditRangeInfoPayload>();

  @Output()
  transformRangeInfo: EventEmitter<TransformRangeInfoPayload> = new EventEmitter<TransformRangeInfoPayload>();

  @Output()
  onTransformImportedRange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onPublishRange: EventEmitter<string> = new EventEmitter<string>();

  tabs: NavigationSidePanelItem[] = [
    {id: 1, title: 'T_NAV_ADMIN_OFFER_DATA_MANAGEMENT_STANDARD', active: true},
    {id: 2, title: 'T_NAV_ADMIN_OFFER_DATA_MANAGEMENT_IMPORTED'},
    {id: 3, title: 'T_NAV_ADMIN_OFFER_DATA_MANAGEMENT_AVAILABLE'},
    {id: 4, title: 'T_NAV_ADMIN_OFFER_DATA_MANAGEMENT_BUDGETARY_QUOTE'},
  ]

  constructor() {
  }

  getTabs() {
    if (!this.importReferencesIsAllowed()) {
      return this.tabs.filter((tab) => {
        return tab.id !== 2
      })
    }
    return this.tabs;
  }

  isTabActive(id: number) {
    return this.tabs.find((tab) => {
      return tab.id === id
    }).active;
  }

  selectTab(tab: NavigationSidePanelItem) {
    this.tabs = this.tabs.map((t) => {
      t.active = t.id === tab.id;
      return t;
    });
  }

  rangeExport(rangeId: string) {
    this.onClickRangeExport.emit(rangeId)
  }

  rangeImport(event, rangeType) {
    event.rangeType = rangeType;
    this.onClickRangeImport.emit(event);
  }


  importReferencesIsAllowed(): boolean {
    return environment.packageActivation.includes(this.user.partnerCountry);
  }

  getStandardRanges() {
    if (this.ranges === null || this.ranges === undefined) {
      return []
    }
    return this.ranges.filter((range) => {
      return range.rangeType !== RangeType.IMPORTED && range.rangeType !== RangeType.BUDJETARY_QUOTE;
    })
  }

  getImportedRanges() {
    if (this.ranges === null || this.ranges === undefined) {
      return []
    }
    return this.ranges.filter((range) => {
      return range.rangeType === RangeType.IMPORTED;
    });
  }

  /**
   * Get imported ranges for budgetary quote
   */
  getBudgetaryQuoteImportedRanges() {
    return [...new Set(this.ranges.filter(range => range.rangeType === RangeType.BUDJETARY_QUOTE).map(range => range.id))]
      .map(id => this.ranges.find(range => range.id === id));
  }

  /**
   * Range components has been selected
   * @param range
   */
  goToComponentManagement(range) {
    this.clickOnComponentManagement.emit(range);
  }

  /**
   * Range accessories has been selected
   * @param range
   */
  goToAccessoriesManagement(range) {
    this.clickOnAccessoriesManagement.emit(range);
  }

  /**
   * Range accessories has been selected
   * @param range
   */
  goToOptionsManagement(range) {
    this.clickOnOptionsManagement.emit(range);
  }

  /**
   * Range addition has been selected
   * @param {any} range
   */
  goToRangeAddition(range) {
    this.clickOnRangeAddition.emit(range);
  }

  /**
   * Range order has been changed
   * @param {any} listranges list of range
   */
  updateRangeOrder(listRanges, rangeType) {
    if (rangeType === RangeType.IMPORTED) {
      listRanges = [...this.getStandardRanges(), ...listRanges];
    } else {
      listRanges = [...listRanges, ...this.getImportedRanges()];
    }
    this.ranges = listRanges;
    this.onChangeRangeOrder.emit(listRanges);
  }

  /**
   * Range has been decommissioned
   * @param {string} rangeId range id
   */
  decommissionRange(rangeId: string) {
    this.onDecommissionRange.emit(rangeId);
  }

  transformImportedRange(rangeId: string) {
    this.onTransformImportedRange.emit(rangeId);
  }
  publishRange(rangeId: string) {
    this.onPublishRange.emit(rangeId);
  }

  onSaveRangeInfo(rangeInfo: EditRangeInfoPayload) {
    this.saveRangeInfo.emit(rangeInfo);
  }

  onTransformRangeSelector(transformRange: TransformRangeInfoPayload) {
    this.transformRangeInfo.emit(transformRange);
  }
}
