import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Range} from "../../../../shared/model/range-model";
import {
  AccessoryCategory,
  AccessoryCharacteristics,
  EditAccessoryCategory
} from '../../../../shared/model/accessory.model';
import {AccessoriesService} from '../../../../shared/services/accessories.service';
import {TranslateService} from '@ngx-translate/core';
import {AccessoryCategoriesService} from '../../../../shared/services/accessoryCategories.service';
import {Pair} from '../../../../shared/util/pair';
import {OdmModalCategoryNameComponent} from '../odm-modal-category-name/odm-modal-category-name.component';
import {CustomTranslateLoader} from '../../../../shared/localization/CustomTranslateLoader';
import {SessionStorage} from 'ngx-webstorage';
import {User} from '../../../../shared/user/user';
import {
  EditAccessoryForm,
  OdmEditAccessoryModalComponent
} from '../odm-edit-accessory-modal/odm-edit-accessory-modal.component';
import {Localization} from '../../../../shared/model/localization.model';
import {MessageService} from 'primeng/api';
import {CharacteristicValue} from '../../../../characteristics/shared/characteristics.model';
import {CommonModalComponent} from '../../../../shared/common-modal/common-modal.component';
import {TranslationService} from '../../../shared';
import {
  EditAccessoryValueForm,
  OdmEditAccessoryValueModalComponent
} from '../odm-edit-accessory-value-modal/odm-edit-accessory-value-modal.component';
import {RangeType} from "../../../../shared/model/range-type";


@Component({
  selector: 'app-odm-manage-accessories',
  templateUrl: './odm-manage-accessories.component.html',
  styleUrls: ['./odm-manage-accessories.component.less']
})

export class OdmManageAccessoriesComponent implements OnInit {

  isLoading: boolean;

  @SessionStorage()
  public user: User;

  @Input()
  selectedRange: Range;

  @Output()
  clickBack: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('addCategoryModal') addCategoryModal: OdmModalCategoryNameComponent;

  @ViewChild('addAccessoryModal') addAccessoryModal: OdmEditAccessoryModalComponent;
  @ViewChild('editAccessoryModal') editAccessoryModal: OdmEditAccessoryModalComponent;
  @ViewChild('deleteAccessoryModal') deleteAccessoryModal: CommonModalComponent;

  @ViewChild('addAccessoryValueModal') addAccessoryValueModal: OdmEditAccessoryValueModalComponent;
  @ViewChild('editAccessoryValueModal') editAccessoryValueModal: OdmEditAccessoryValueModalComponent;
  @ViewChild('deleteAccessoryValueModal') deleteAccessoryValueModal: CommonModalComponent;


  @SessionStorage()
  localization: Localization;

  accessoryCategories: AccessoryCategory[];
  accessories: AccessoryCharacteristics[];

  accessoryToDelete: string;
  accessoryValueToDelete: { accessoryId: string; accessoryValueId: string };

  activeCategory: string;
  activeAccessory: string;

  constructor(private translateService: TranslateService,
              private accessoriesService: AccessoriesService,
              private accessoryCategoriesService: AccessoryCategoriesService,
              private readonly customTranslateLoader: CustomTranslateLoader,
              private readonly messageService: MessageService,
              private translationService: TranslationService) {
  }

  ngOnInit() {
    this.getAccessories();
  }

  getAccessories() {
    this.accessoriesService.getAccessories(this.selectedRange.id).subscribe(accessories => {
      this.accessories = accessories;

      this.accessories.forEach(accessory => {
        accessory.values.forEach(value => {
          value.reference = value.reference ? value.reference : null;
        });
        accessory.values.sort((a, b) => {
          const aIsNull = a.reference === null;
          const bIsNull = b.reference === null;
          if (aIsNull && bIsNull) return 0;
          if (aIsNull) return 1;
          if (bIsNull) return -1;
          return a.reference.ref.length - b.reference.ref.length;
        });
      });
      this.getCategories();
    })
  }

  getCategories() {
    this.accessoryCategoriesService.getAccessoryCategory(this.selectedRange.id, true).subscribe(categories => {
      this.accessoryCategories = categories;
      if (this.accessoryCategories.length === 1) {
        this.activeCategory = this.accessoryCategories[0].id;
      }
      this.isLoading = false;
    })
  }

  addCategory(translationByLanguage: Pair<string, string>[]) {
    this.isLoading = true
    this.activeCategory = null;
    this.activeAccessory = null;
    this.accessoryCategoriesService.addAccessoryCategory(this.selectedRange.id, translationByLanguage)
      .subscribe((cat) => {
          this.customTranslateLoader.getTranslation(this.user.preferredLanguage, true).subscribe(res => {
            this.translateService.setTranslation(this.user.preferredLanguage, res, false);
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('T_INFO'),
              detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_CREATING_CATEGORY_ACCESSORY'),
            });
            this.getCategories();
            this.activeCategory = cat.id;
          });
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_CREATING_CATEGORY_ACCESSORY'),
          });
          this.isLoading = false;
        });
  }

  addAccessory(editAccessoryForm: EditAccessoryForm) {
    this.isLoading = true;
    this.activeCategory = editAccessoryForm.categoryId;
    this.activeAccessory = null;
    this.accessoriesService.createAccessory(editAccessoryForm).subscribe((newAccessory) => {
      this.onSuccessEditingAccessory();
      this.activeAccessory = newAccessory.id;
    }, (error) => {
      this.onErrorEditingAccessory(error);
    });
  }

  editAccessory(editAccessoryForm: EditAccessoryForm) {
    this.isLoading = true;
    this.activeCategory = editAccessoryForm.categoryId;
    this.activeAccessory = editAccessoryForm.accessoryId;
    this.accessoriesService.editAccessory(editAccessoryForm).subscribe(() => {
      this.onSuccessEditingAccessory();
    }, (error) => {
      this.onErrorEditingAccessory(error);
    });
  }

  deleteCategory(id: string) {
    this.isLoading = true;
    this.accessoryCategoriesService.deleteAccessoryCategory(id).subscribe(() => {
        this.onSuccessDeletingCategory();
      },
      (error) => {
        this.onErrorDeletingCategory(error);
      });
    this.activeCategory = null;
  }

  deleteAccessory() {
    this.isLoading = true;
    // must retrieve related category for accessoryToDelete
    this.activeCategory = this.accessories.find(acc => acc.id === this.accessoryToDelete).category;
    this.accessoriesService.deleteAccessory(this.selectedRange.id, this.accessoryToDelete).subscribe(() => {
      this.onSuccessDeletingAccessory();
    }, (error) => {
      this.onErrorDeletingAccessory(error);
    });
    this.activeAccessory = null;
  }

  addAccessoryValue(editAccessoryValueForm: EditAccessoryValueForm) {
    this.isLoading = true;
    // must retrieve related category for accessoryToDelete
    this.activeCategory = this.accessories.find(acc => acc.id === editAccessoryValueForm.accessoryId).category;
    this.activeAccessory = editAccessoryValueForm.accessoryId;
    this.accessoriesService.addAccessoryValue(editAccessoryValueForm).subscribe(() => {
      this.onSuccessEditingAccessory();
    }, (error) => {
      this.onErrorEditingAccessory(error);
    });
  }

  editAccessoryValue(editAccessoryValueForm: EditAccessoryValueForm) {
    this.isLoading = true;
    // must retrieve related category for accessoryToDelete
    this.activeCategory = this.accessories.find(acc => acc.id === editAccessoryValueForm.accessoryId).category;
    this.activeAccessory = editAccessoryValueForm.accessoryId;
    this.accessoriesService.editAccessoryValue(editAccessoryValueForm).subscribe(() => {
      this.onSuccessEditingAccessory();
    }, (error) => {
      this.onErrorEditingAccessory(error);
    });
  }

  deleteAccessoryValue() {
    this.isLoading = true;
    // must retrieve related category for accessoryToDelete
    this.activeCategory = this.accessories.find(acc => acc.id === this.accessoryValueToDelete.accessoryId).category;
    this.activeAccessory = this.accessoryValueToDelete.accessoryId;
    this.accessoriesService.deleteAccessoryValue(this.accessoryValueToDelete.accessoryId, this.accessoryValueToDelete.accessoryValueId).subscribe(() => {
      this.onSuccessDeletingAccessoryValue();
    }, (error) => {
      this.onErrorDeletingAccessoryValue(error);
    });
  }

  returnOnOfferSelection() {
    this.clickBack.emit();
  }

  onClickAddCategoryButton() {
    this.addCategoryModal.show();
  }

  onClickAddAccessoryButton() {
    const languages = this.localization.langs.map(lang => lang.language);
    this.addAccessoryModal.showAddMode(this.selectedRange, this.accessoryCategories, languages);
  }

  onClickEditCategory(event: EditAccessoryCategory) {
    this.isLoading = true;
    this.translationService.setLocalesOfCountryOfKey(event.nameKey, event.translationByLanguage).subscribe(result => {
        if (result) {
          this.customTranslateLoader.getTranslation(this.user.preferredLanguage, true).subscribe(res =>
            this.translateService.setTranslation(this.user.preferredLanguage, res, false));
        }
        this.onSuccessEditingCategory();
      },
      (error => this.onErrorEditingCategory(error)))
  }

  onClickEditAccessoryButton(accessory: AccessoryCharacteristics) {
    const languages = this.localization.langs.map(lang => lang.language);
    this.editAccessoryModal.showEditMode(accessory, this.accessoryCategories, languages);
  }

  onClickDeleteAccessoryButton(accessory: AccessoryCharacteristics) {
    this.accessoryToDelete = accessory.id;
    this.deleteAccessoryModal.show();
  }

  onClickAddAccessoryValueButton() {
    this.addAccessoryValueModal.showAddMode(this.accessories, this.selectedRange);
  }

  onClickEditAccessoryValueButton(event: { accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue }) {
    this.editAccessoryValueModal.showEditMode(this.selectedRange, event.accessory, event.accessoryValue);
  }

  onClickDeleteAccessoryValueButton(event: {
    accessory: AccessoryCharacteristics,
    accessoryValue: CharacteristicValue
  }) {
    this.accessoryValueToDelete = {
      accessoryId: event.accessory.id,
      accessoryValueId: event.accessoryValue.id
    }
    this.deleteAccessoryValueModal.show();
  }

  onChangeAccessoryOrder(event: { accessoryId: string, orderIndex: number }) {
    this.isLoading = true;
    this.activeCategory = this.accessories.find(acc => acc.id === event.accessoryId).category;
    this.activeAccessory = null;
    this.accessoriesService.editAccessoryOrder(event.accessoryId, event.orderIndex).subscribe(() => {
      this.onSuccessEditingAccessory();
    }, (error) => {
      this.onErrorDeletingAccessoryValue(error);
    })
  }

  onClickMoveDownAccessoryValue(event: { accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue }) {
    this.isLoading = true;
    this.activeCategory = this.accessories.find(acc => acc.id === event.accessory.id).category;
    this.activeAccessory = event.accessory.id;
    this.accessoriesService.editAccessoryValueOrder(event.accessory.id, event.accessoryValue.id, event.accessoryValue.orderIndex + 1).subscribe(() => {
      this.onSuccessEditingAccessory();
    }, (error) => {
      this.onErrorDeletingAccessoryValue(error);
    })
  }

  onClickMoveUpAccessoryValue(event: { accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue }) {
    this.isLoading = true;
    this.activeCategory = this.accessories.find(acc => acc.id === event.accessory.id).category;
    this.activeAccessory = event.accessory.id;
    this.accessoriesService.editAccessoryValueOrder(event.accessory.id, event.accessoryValue.id, event.accessoryValue.orderIndex - 1).subscribe(() => {
      this.onSuccessEditingAccessory();
    }, (error) => {
      this.onErrorDeletingAccessoryValue(error);
    })
  }

  onSuccessEditingCategory() {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('T_INFO'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_EDITING_CATEGORY'),
    });

    this.customTranslateLoader.getTranslation(this.user.preferredLanguage, true).subscribe(res => {
      this.translateService.setTranslation(this.user.preferredLanguage, res, false);
      this.getCategories();
    });
  }

  onErrorEditingCategory(error) {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_ERROR'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_EDITING_CATEGORY') + ': ' + error,
    });
    this.getCategories();
  }


  onSuccessEditingAccessory() {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('T_INFO'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_EDITING_ACCESSORY'),
    });

    this.customTranslateLoader.getTranslation(this.user.preferredLanguage, true).subscribe(res => {
      this.translateService.setTranslation(this.user.preferredLanguage, res, false);
      this.getAccessories();
    });
  }

  onErrorEditingAccessory(error) {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_ERROR'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_EDITING_ACCESSORY') + ': ' + error,
    });
    this.getAccessories();
  }

  onSuccessDeletingCategory() {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('T_INFO'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_DELETING_CATEGORY'),
    });
    this.getCategories();
  }

  onErrorDeletingCategory(error) {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_ERROR'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_DELETING_CATEGORY') + ': ' + error,
    });
    this.getCategories();
  }

  onSuccessDeletingAccessory() {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('T_INFO'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_DELETING_ACCESSORY'),
    });
    this.accessoryToDelete = null;
    this.getAccessories();
  }

  onErrorDeletingAccessory(error) {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_ERROR'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_DELETING_ACCESSORY') + ': ' + error,
    });
    this.accessoryToDelete = null;
    this.getAccessories();
  }

  onSuccessDeletingAccessoryValue() {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('T_INFO'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_DELETING_ACCESSORY_VALUE'),
    });
    this.accessoryValueToDelete = null;
    this.getAccessories();
  }

  onErrorDeletingAccessoryValue(error) {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_INFO'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_DELETING_ACCESSORY_VALUE') + ': ' + error,
    });
    this.accessoryValueToDelete = null;
    this.getAccessories();
  }

  showAccessoryButtons() {
    return this.selectedRange.rangeType !== RangeType.IMPORTED;
  }
}
