/* Angular modules */
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
/* 3rd parties libraries */
import {isNullOrUndefined} from 'util';
/* app modules */
import {NavigationStep} from '../../shared/guards/navigationStep-enum';
import {Item, Project, Status} from '../../project/shared/project-model';
import {ItemService} from '../../project/shared/item.service';
import {Documentation} from '../../shared/documentation/documentation.model';
import {
  DocumentationCategory,
  DocumentationHelperService,
  DocumentationService,
  DocumentExtension,
  DocumentInformations,
  DocumentLanguageCode,
  StaticDocumentationService
} from '../shared';
import {Localization} from '../../shared/model/localization.model';
import {TranslateService} from '@ngx-translate/core';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {LoggerService} from '../../shared/logging/logger.service';
import {BomMailModalComponent} from '../../bom';
import {RangeType} from '../../shared/model/range-type';
import {Partner} from '../../shared/partner/partner';
import {Observable} from 'rxjs';
import {DatasheetsService} from '../../shared/datasheets/datasheets.service';
import {Subject} from 'rxjs/Rx';
import {ProjectService} from '../../project/shared/project.service';
import {RightsService} from '../../shared/rights/rights.service';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.less']
})
export class DocumentationComponent implements OnInit, OnDestroy {

  @ViewChild('docInfoModal')
  docInfoModal: CommonModalComponent;

  @ViewChild('orderEmailModal')
  orderEmailModal: BomMailModalComponent;

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  user;

  @SessionStorage()
  localization: Localization;

  @SessionStorage()
  documentInformations: DocumentInformations;

  @SessionStorage()
  partner: Partner;

  @SessionStorage()
  noItemNavigationStep;

  dynamicDocumentation: Array<Map<string, Documentation>> = [];
  quoteDocumentation: Array<Map<string, Documentation>> = [];
  dynamicProjectDocumentation: Array<Map<string, Documentation>> = [];
  staticPlans: Array<Map<string, Documentation>> = [];
  staticDatasheets: Array<Map<string, Documentation>> = [];
  staticManualDocuments: Array<Map<string, Documentation>> = [];
  referencesOfSelectedCubicles: Array<string> = [];
  cadFiles: Array<Map<string, Documentation>> = [];
  environment: Array<Map<string, Documentation>> = [];
  catalogs: Array<Map<string, Documentation>> = [];
  promotionMaterials: Array<Map<string, Documentation>> = [];
  technicalPublications: Array<Map<string, Documentation>> = [];
  applicationSolutions: Array<Map<string, Documentation>> = [];
  whitePapers: Array<Map<string, Documentation>> = [];
  certificates: Array<Map<string, Documentation>> = [];
  specifications: Array<Map<string, Documentation>> = [];
  training: Array<Map<string, Documentation>> = [];

  // True is we are on the project documentation page, False if we are on the configuration documentation page
  isProjectDocumentation: boolean;

  /* the current range type */
  documentChosenLanguage: string;
  isLoading = true;
  status: { isopen: boolean } = {isopen: false};

  hasDynamicDocumentation = false;
  hasDynamicProjectDocumentation = false;

  readonly NO_LANGAGE = 'NONE';
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private staticDocService: StaticDocumentationService,
              private logger: LoggerService,
              private datasheetsService: DatasheetsService,
              private itemService: ItemService,
              private projectService: ProjectService,
              private translateService: TranslateService,
              private documentationHelperService: DocumentationHelperService,
              private documentationService: DocumentationService,
              private rightsService: RightsService) {
  }

  public ngOnInit(): void {
    this.logger.debug('DocumentationComponent init()');

    // The language is the language chosen on 'Settings' tab
    this.documentChosenLanguage = this.user.preferredLanguage;
    this.isProjectDocumentation = !this.currentItemct;

    if (this.isProjectDocumentation) {
      this.noItemNavigationStep = NavigationStep.PROJECT_DOCUMENTATION;
    } else {
      this.itemService.setItemNavigationStep(NavigationStep.EXPORT, this.user, this.unsubscribe$);
    }

    // Create documentInformation if it does not exist and save it on the server
    if (this.documentInformations == null) {
      this.getOrCreateDocumentInformation(this.currentProject, this.currentItemct)
        .then(documentInformation => this.documentInformations = documentInformation);
    }

    const afterDocumentsProcessing = () => {
      this.isLoading = false;
      if ((this.hasDynamicDocumentation || this.hasDynamicProjectDocumentation) &&
        (!this.documentInformations || this.isEmpty(this.documentInformations.projectName) || this.isEmpty(this.documentInformations.endUserName))) {
        this.docInfoModal.show();
      }
    };

    this.processServerDocuments()
      .then(afterDocumentsProcessing, afterDocumentsProcessing);
  }

  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    // unsubscribe from the subject itself:
    this.unsubscribe$.unsubscribe();
  }

  isProjectEmpty() {
    return isNullOrUndefined(this.currentProject) ||
      isNullOrUndefined(this.currentProject.itemCT) ||
      this.currentProject.itemCT.length === 0;
  }

  change(value: boolean): void {
    this.status.isopen = value;
  }

  /**
   * return if button Name should be show
   */
  showDocInfoModalButton(): boolean {
    return (this.hasDynamicDocumentation || this.hasDynamicProjectDocumentation);
  }

  showDocInfoModal() {
    this.docInfoModal.show();
  }

  /**
   * display the mail modal
   */
  showOrderEmailModal() {
    this.orderEmailModal.show();
  }

  /**
   * define wich is current item range
   * @param {RangeType} rangeType
   * @returns {boolean}
   */
  isRange(rangeType: RangeType): boolean {
    if (this.isProjectDocumentation) {
      return false;
    }
    return !!this.currentItemct.range && this.currentItemct.range.rangeType === rangeType;
  }

  isActivatedDemoMode(): boolean {
    return this.rightsService.isActivatedDemo();
  }

  private getOrCreateDocumentInformation(project: Project, item: Item): Promise<DocumentInformations> {
    const documentationId = (item ? item.id : project.id);
    return this.documentationService.getDocumentInformations(documentationId)
      .takeUntil(this.unsubscribe$)
      .toPromise()
      .then(docInfos => {
        if (isNullOrUndefined(docInfos.itemId)) {
          const documentInformations = new DocumentInformations();
          documentInformations.itemId = item ? item.id : project.id;
          documentInformations.projectName = project.name;
          documentInformations.author = this.user.id.toString();
          documentInformations.downloadedDoc = [];
          documentInformations.version = 1;

          return this.documentationService.updateDocumentInformations(documentInformations)
            .takeUntil(this.unsubscribe$)
            .toPromise();
        } else {
          return Promise.resolve(docInfos);
        }
      });
  }

  private processServerDocuments(): Promise<any> {
    const documents: Array<{ item: Item , documentation: Documentation}> = [];
    const serverDocumentsPromises = [];

    const getProjectItems = this.isProjectDocumentation ? this.itemService.getItems(this.currentProject.id) : Observable.of([this.currentItemct]);

    return getProjectItems
      .takeUntil(this.unsubscribe$)
      .toPromise()
      .then(projectItems => {
        projectItems.forEach(item => {
          // BSL FILES
          const bslItemDocPromise = this.staticDocService.retrieveBslDocumentationByItem(item.id)
            .takeUntil(this.unsubscribe$)
            .toPromise()
            .then(bslFiles => {
              bslFiles.forEach((doc: Documentation) => {
                if (doc != null && doc.category === DocumentationCategory.CAD && !doc.id) {
                  doc.language = this.NO_LANGAGE;
                  documents.push( {item: item, documentation: doc});
                }
              });
            });
          serverDocumentsPromises.push(bslItemDocPromise);

          item.components.forEach(
            component => {
              component.documents.forEach(
                doc => {
                  documents.push( {item: item, documentation: doc});
                }
              );
              if (component.functionalUnits !== undefined && component.functionalUnits !== null && component.functionalUnits.length > 0) {
                component.functionalUnits.forEach(
                  fu => {
                    if (fu.transformer !== undefined && fu.transformer !== null && fu.transformer.documents !== undefined && fu.transformer.documents !== null) {
                      fu.transformer.documents.forEach(
                        doc => {
                          documents.push( {item: item, documentation: doc});
                        }
                      );
                    }
                  }
                );
              }
          });

          // we add datasheets for services selected
          if (item.selectedServices !== undefined && item.selectedServices !== null) {
            item.selectedServices.forEach(service => {
              const serviceDatasheetPromise = this.datasheetsService.getReferenceDatasheetUrl(service.reference.ref, this.documentChosenLanguage)
                .takeUntil(this.unsubscribe$)
                .toPromise()
                .then( doc => {
                    documents.push({item: item, documentation: doc});
                }
                );
              serverDocumentsPromises.push(serviceDatasheetPromise);
              }
            );
          }

          const staticDocsPromise = this.staticDocService.retrieveBslDocumentationByItem(item.id)
            .takeUntil(this.unsubscribe$)
            .toPromise()
            .then(docsByRange => {
              docsByRange.forEach(
                doc => {
                  documents.push( {item: item, documentation: doc});
                }
              );
            });

          serverDocumentsPromises.push(staticDocsPromise);
        });
      })
      .then(() => Promise.all(serverDocumentsPromises))
      .then(() => {
        this.filterDocumentationByCategory(documents);
        this.hasDynamicDocumentation = (!this.isProjectDocumentation && this.dynamicDocumentation.length > 0);
        this.hasDynamicProjectDocumentation = (this.isProjectDocumentation && this.dynamicProjectDocumentation.length > 0);
      });
  }


  /**
   * Allow to dispatch documents by category between arrays
   * @param documents
   */
  private filterDocumentationByCategory(documents: Array<{ item: Item , documentation: Documentation}>) {
    const dynamicDocumentation = [];
    const dynamicProjectDocumentation = [];
    const quoteDocumentation: Array<{ item: Item , documentation: Documentation}> = [];
    const staticPlans = [];
    const staticDatasheets = [];
    const staticManualDocuments = [];
    const cadFiles = [];
    const environment = [];
    const catalogs = [];
    const promotionMaterials = [];
    const technicalPublications = [];
    const applicationSolutions = [];
    const whitePapers = [];
    const certificates = [];
    const specifications = [];
    const training = [];


    documents.forEach(
      documentItem => {
        const document = documentItem.documentation;
        if (document !== null && document !== undefined) {
          switch (document.category) {
            case DocumentationCategory.COMMERCIAL:
            case DocumentationCategory.TECHNICAL:
            case DocumentationCategory.COMMERCIAL_TECHNICAL:
            case DocumentationCategory.COMMERCIAL_TECHNICAL_BSL:
            case DocumentationCategory.CGV:
              dynamicDocumentation.push(document);
              break;
            case DocumentationCategory.TECHNICAL_DOC:
            case DocumentationCategory.TECHNICAL_PUBLICATIONS:
              technicalPublications.push(document);
              break;
            case DocumentationCategory.PROJECT_COMMERCIAL:
              dynamicProjectDocumentation.push(document);
              break;
            case DocumentationCategory.ORDER:
              quoteDocumentation.push(documentItem);
              break;
            case DocumentationCategory.PLANS:
              // Added form ODM
              if (document.references === undefined || document.references === null) {
                staticPlans.push(document);
              } else {
                document.references.forEach(ref =>{
                  if (this.referencesOfSelectedCubicles.includes(ref)) {
                    staticPlans.push(document);
                  }
                })
              }
              if (this.referencesOfSelectedCubicles.length === 0) {
                staticPlans.push(document);
              }
              break;
            case DocumentationCategory.ENVIRONMENT:
              staticPlans.push(document);
              break;
            case DocumentationCategory.DATASHEETS:
              staticDatasheets.push(document);
              break;
            case DocumentationCategory.USER_GUIDE:
              staticManualDocuments.push(document);
              break;
            case DocumentationCategory.CAD:
              cadFiles.push(document);
              break;
            case DocumentationCategory.CATALOGS:
              catalogs.push(document);
              break;
            case DocumentationCategory.PROMOTIONAL_MATERIALS:
              promotionMaterials.push(document);
              break;
            case DocumentationCategory.APPLICATIONS_SOLUTIONS:
              applicationSolutions.push(document);
              break;
            case DocumentationCategory.WHITE_PAPERS:
              whitePapers.push(document);
              break;
            case DocumentationCategory.CERTIFICATES:
              certificates.push(document);
              break;
            case DocumentationCategory.SPECIFICATIONS:
              specifications.push(document);
              break;
            case DocumentationCategory.TRAINING:
              training.push(document);
              break;
          }
        }
      });

    this.dynamicDocumentation = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(dynamicDocumentation, this.dynamicDocumentation);
    this.dynamicProjectDocumentation = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(dynamicProjectDocumentation, this.dynamicProjectDocumentation);
    this.quoteDocumentation = this.documentationHelperService.mergeOrderDocuments(quoteDocumentation);
    this.staticPlans = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(staticPlans, this.staticPlans);
    this.staticDatasheets = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(staticDatasheets, this.staticDatasheets);
    this.staticManualDocuments = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(staticManualDocuments, this.staticManualDocuments);
    this.cadFiles = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(cadFiles, this.cadFiles);
    this.environment = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(environment, this.environment);
    this.catalogs = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(catalogs, this.catalogs);
    this.promotionMaterials = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(promotionMaterials, this.promotionMaterials);
    this.technicalPublications = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(technicalPublications, this.technicalPublications);
    this.applicationSolutions = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(applicationSolutions, this.applicationSolutions);
    this.whitePapers = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(whitePapers, this.whitePapers);
    this.certificates = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(certificates, this.certificates);
    this.specifications = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(specifications, this.specifications);
    this.training = this.documentationHelperService.mergeDocumentsByTitleAndLanguage(training, this.training);
  }


  private isEmpty(field: string): boolean {
    return field === null || field === undefined || field === '';
  }

  /**
   * Method to know if it is a CPQ project
   * @returns {boolean}
   */
  private isCpqProject(): boolean {
    return this.projectService.isCpqProject(this.currentProject);
  }
}
