import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import constant from "../../../../constant.json";

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {

  @Input()
  hasConnectionError;

  @Output() onClickRelogin = new EventEmitter<string>();

  commercialVersion: string;

  constructor() { }

  ngOnInit(): void {
    this.commercialVersion = constant.version;
  }

  public relogin () {
    this.onClickRelogin.emit();
  }

}
