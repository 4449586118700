import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {Documentation} from "../../../../shared/documentation/documentation.model";
import {CommonModalComponent} from "../../../../shared/common-modal/common-modal.component";
import {ReferenceRangePayload} from "../../../../shared/model/referenceRangePayload.model";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-documentation-link-ref-modal',
  templateUrl: './documentation-link-ref-modal.component.html',
  styleUrls: ['./documentation-link-ref-modal.component.less']
})
export class DocumentationLinkRefModalComponent {

  @ViewChild('linkRefModal', {static: false}) linkRefModal: CommonModalComponent;
  @ViewChild('dt') dt: any;

  @Output()
  clickedSaveLinkedRefs: EventEmitter<Documentation> = new EventEmitter<Documentation>();

  constructor(
    private translateService: TranslateService,) {
  }

  loading: boolean;
  currentDocumentation: Documentation;
  selectedRefs: string[];
  availableRefs: ReferenceRangePayload[];
  searchTerm: string = '';

  show(documentation: Documentation, refRangePayloadList: ReferenceRangePayload[]) {
    this.currentDocumentation = documentation;
    this.currentDocumentation.references ? this.selectedRefs = this.currentDocumentation.references : this.selectedRefs = [];
    this.availableRefs = refRangePayloadList;
    this.availableRefs = this.availableRefs.map(ref => {
      ref.rangeNameTranslated = this.translateService.instant(ref.rangeName);
      return ref;
    })
    this.clearSearch();
    this.linkRefModal.show();
  }

  isSelectedRef(refRange: ReferenceRangePayload) {
    return this.selectedRefs.includes(refRange.reference);
  }

  clickOnRefCheckbox(refRange: ReferenceRangePayload) {
    if (this.isSelectedRef(refRange)) {
      this.selectedRefs = this.selectedRefs.filter(ref => ref !== refRange.reference);
    } else {
      this.selectedRefs.push(refRange.reference);
    }
  }

  clickOnCancelLinkRef() {
    this.linkRefModal.hide();
  }

  clickOnSaveLinkRef() {
    this.currentDocumentation.references = this.selectedRefs;
    this.clickedSaveLinkedRefs.emit(this.currentDocumentation);
    this.linkRefModal.hide();
  }

  clearSearch() {
    this.searchTerm = '';
    this.dt.filterGlobal('', 'contains');
  }

  onSearchInput(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchTerm = input.value;
    this.dt.filterGlobal(this.searchTerm, 'contains');
  }
}
