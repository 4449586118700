import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Range, RangeStatus} from '../../../../shared/model/range-model';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {CommonModalComponent} from '../../../../shared/common-modal/common-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {RangeType} from '../../../../shared/model/range-type';
import {
  EditRangeInfoPayload,
  OdmRangeManagementModalComponent
} from '../../shared/odm-range-management-modal/odm-range-management-modal.component';
import {
  OdmTransformToSelectorRangeModalComponent,
  TransformRangeInfoPayload
} from "../../shared/odm-transform-to-selector-range-modal/odm-transform-to-selector-range-modal.component";

@Component({
  selector: 'app-odm-list-offers',
  templateUrl: './odm-list-offers.component.html',
  styleUrls: ['./odm-list-offers.component.less']
})
export class OdmListOffersComponent {

  @Input()
  rangesObservable: Range[];

  @Input()
  titleHeader: string;

  // Allow user to reorder the ranges with drag and drop
  @Input()
  orderList: boolean;

  @Input()
  showImportExportButtons: boolean;

  @Input()
  loadingImportExport: boolean;

  @Input()
  isBudetaryQuote: boolean;

  @Input()
  loading: boolean;

  @Output()
  onClickOnComponentManagement: EventEmitter<Range> = new EventEmitter<Range>();

  @Output()
  onChangeRangeOrder: EventEmitter<Range[]> = new EventEmitter<Range[]>();

  @Output()
  onClickOnRangeAddition: EventEmitter<Range> = new EventEmitter<Range>();

  @Output()
  onClickOnAccessoriesManagement: EventEmitter<Range> = new EventEmitter<Range>();

  @Output()
  onClickOnOptionsManagement: EventEmitter<Range> = new EventEmitter<Range>();

  @Output()
  onDecommissionRange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onClickRangeExport: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onClickRangeImport: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  saveRangeInfo: EventEmitter<EditRangeInfoPayload> = new EventEmitter<EditRangeInfoPayload>();

  @Output()
  transformRangeInfo: EventEmitter<TransformRangeInfoPayload> = new EventEmitter<TransformRangeInfoPayload>();

  @Output()
  onTransformImportedRange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onPublishRange: EventEmitter<string> = new EventEmitter<string>();

  ranges: Range[];
  initialized = false;

  rangeToAction: string;

  @ViewChild('decommissionRangeModal') decommissionRangeModal: CommonModalComponent;
  @ViewChild('rangeManagementModal') rangeManagementModal: OdmRangeManagementModalComponent;
  @ViewChild('transformToSelectorRangeModal') transformToSelectorRangeModal: OdmTransformToSelectorRangeModalComponent;
  @ViewChild('transformToImportedRangeModal') transformToImportedRangeModal: CommonModalComponent;
  @ViewChild('publishRangeModal') publishRangeModal: CommonModalComponent;

  constructor(
    private readonly translateService: TranslateService,
  ) {
  }

  rangeExport(rangeId: string) {
    this.onClickRangeExport.emit(rangeId)
  }

  rangeImport(event: any) {
    this.onClickRangeImport.emit(event);
  }

  /**
   * Range components have been selected
   * @param range
   */
  goToComponentManagement(range: Range) {
    this.onClickOnComponentManagement.emit(range);
  }

  /**
   * Range accessories has been selected
   * @param {Range} range the selected range for accessory management
   */
  goToAccessoriesManagement(range: Range) {
    this.onClickOnAccessoriesManagement.emit(range);
  }

  /**
   * Range options has been selected
   * @param {Range} range the selected range for accessory management
   */
  goToOptionsManagement(range: Range) {
    this.onClickOnOptionsManagement.emit(range);
  }

  /**
   * Range accessories has been selected
   * @param {Range} range the selected range for accessory management
   */
  goToRangeAddition(range: Range) {
    this.onClickOnRangeAddition.emit(range);
  }

  /**
   * Method to reorder a list when a user drops an item
   * @param event
   */
  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.rangesObservable, event.previousContainer.data, event.container.data);
    this.onChangeRangeOrder.emit(this.rangesObservable);
  }

  getActionsMenu(range: Range) {
    let listItemsAction = [
      {key: 'T_OFFER_DATA_MANAGEMENT_ADD_RANGE_TITLE', id: 'addRange'},
      {key: 'T_OFFER_DATA_MANAGEMENT_COMPONENT_MANAGEMENT_TITLE', id: 'componentManagement'},
      {key: 'T_OFFER_DATA_MANAGEMENT_ACCESSORY_MANAGEMENT_TITLE', id: 'accessoryManagement'},
      {key: 'T_OFFER_DATA_MANAGEMENT_OPTIONS_MANAGEMENT_TITLE', id: 'optionsManagement'},
      {key: 'T_OFFER_DATA_MANAGEMENT_RANGE_MANAGEMENT_BUTTON', id: 'rangeManagement'},
      {key: 'T_OFFER_DATA_MANAGEMENT_TRANSFORM_TO_IMPORTED_RANGE', id: 'transformToImportedRange'},
      {key: 'T_OFFER_DATA_MANAGEMENT_TRANSFORM_TO_SELECTOR_RANGE', id: 'transformToSelectorRange'},
      {key: 'T_OFFER_DATA_MANAGEMENT_PUBLISH_RANGE', id: 'publishRange'},
      {key: 'T_PRICE_EXPORT', id: 'exportRange'},
      {key: 'T_OFFER_DATA_MANAGEMENT_DECOMMISSION_RANGE', id: 'decommissionRange'}
    ];
    if (range.status === RangeStatus.PUBLISHED) {
      switch (range.rangeType) {
        case RangeType.TRANSFORMER:
          listItemsAction = listItemsAction.filter(item => item.id !== 'addRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'transformToSelectorRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'publishRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'exportRange');
          if (!range.importedRange) {
            listItemsAction = listItemsAction.filter(item => item.id !== 'transformToImportedRange');
          }
          break;

        case RangeType.IMPORTED:
          listItemsAction = listItemsAction.filter(item => item.id !== 'addRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'publishRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'transformToImportedRange');
          break;

        case RangeType.BUDJETARY_QUOTE:
          listItemsAction = listItemsAction.filter(item => item.id !== 'addRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'transformToSelectorRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'publishRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'transformToImportedRange');
          break;

        case RangeType.ACCESSORIES:
        case RangeType.FAKE:
        case RangeType.SWITCHBOARD:
        default:
          listItemsAction = listItemsAction.filter(item => item.id !== 'addRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'transformToSelectorRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'publishRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'exportRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'transformToImportedRange');
          break;
      }
    }else if (range.status === RangeStatus.CONFIGURED || range.status === RangeStatus.TEST) {
      if (range.importedRange) {
        listItemsAction = listItemsAction.filter(item => item.id !== 'addRange');
        listItemsAction = listItemsAction.filter(item => item.id !== 'transformToSelectorRange');
        if (range.rangeType === RangeType.BUDJETARY_QUOTE) {
          listItemsAction = listItemsAction.filter(item => item.id !== 'publishRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'transformToImportedRange');
          if (range.status === RangeStatus.CONFIGURED) {
            listItemsAction = listItemsAction.filter(item => item.id !== 'optionsManagement');
            listItemsAction = listItemsAction.filter(item => item.id !== 'rangeManagement');
          }
        } else {
          // We are in case of a transformed transformer range
          listItemsAction = listItemsAction.filter(item => item.id !== 'exportRange');
        }
      } else {
        if (range.status === RangeStatus.TEST) {
          listItemsAction = listItemsAction.filter(item => item.id !== 'addRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'transformToSelectorRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'publishRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'exportRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'transformToImportedRange');
        } else {
          listItemsAction = listItemsAction.filter(item => item.id !== 'addRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'accessoryManagement');
          listItemsAction = listItemsAction.filter(item => item.id !== 'optionsManagement');
          listItemsAction = listItemsAction.filter(item => item.id !== 'rangeManagement');
          listItemsAction = listItemsAction.filter(item => item.id !== 'transformToSelectorRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'transformToImportedRange');
          listItemsAction = listItemsAction.filter(item => item.id !== 'publishRange');
          if (range.rangeType !== RangeType.BUDJETARY_QUOTE) {
            listItemsAction = listItemsAction.filter(item => item.id !== 'exportRange');
          }
        }
      }
    }else if (range.status === RangeStatus.AVAILABLE) {
      listItemsAction = listItemsAction.filter(item => item.id !== 'componentManagement');
      listItemsAction = listItemsAction.filter(item => item.id !== 'accessoryManagement');
      listItemsAction = listItemsAction.filter(item => item.id !== 'optionsManagement');
      listItemsAction = listItemsAction.filter(item => item.id !== 'rangeManagement');
      listItemsAction = listItemsAction.filter(item => item.id !== 'transformToSelectorRange');
      listItemsAction = listItemsAction.filter(item => item.id !== 'transformToImportedRange');
      listItemsAction = listItemsAction.filter(item => item.id !== 'publishRange');
      listItemsAction = listItemsAction.filter(item => item.id !== 'decommissionRange');
    } else if (range.status === RangeStatus.IMPORTED) {
      listItemsAction = listItemsAction.filter(item => item.id !== 'componentManagement');
      listItemsAction = listItemsAction.filter(item => item.id !== 'accessoryManagement');
      listItemsAction = listItemsAction.filter(item => item.id !== 'optionsManagement');
      listItemsAction = listItemsAction.filter(item => item.id !== 'rangeManagement');
      listItemsAction = listItemsAction.filter(item => item.id !== 'transformToSelectorRange');
      listItemsAction = listItemsAction.filter(item => item.id !== 'transformToImportedRange');
      listItemsAction = listItemsAction.filter(item => item.id !== 'publishRange');
    }
    return [{
      label: 'Actions',
      items: listItemsAction.map(action => ({
        label: this.translateService.instant(action.key),
        id: action.id
      }))
    }];
  }

  clickedOnActionMenu(action: string, range: Range) {
    switch (action) {
      case 'componentManagement':
        this.goToComponentManagement(range);
        break;
      case 'accessoryManagement':
        this.goToAccessoriesManagement(range);
        break;
      case 'optionsManagement':
        this.goToOptionsManagement(range);
        break;
      case 'rangeManagement':
        this.showRangeManagementModal(range);
        break;
      case 'addRange':
        this.goToRangeAddition(range);
        break;
      case 'decommissionRange':
        this.showDecommissionRangeModal(range.id);
        break;
      case 'exportRange':
        this.rangeExport(range.id);
        break;
      case 'transformToSelectorRange':
        this.showTransformToSelectorRangeModal(range);
        break;
      case 'transformToImportedRange':
        this.showTransformToImportedRangeModal(range.id);
        break;
      case 'publishRange':
        this.showPublishRangeModal(range.id);
        break;
      default:
        break;
    }
  }

  showDecommissionRangeModal(rangeId: string) {
    this.rangeToAction = rangeId;
    this.decommissionRangeModal.show();
  }

  showRangeManagementModal(range: Range) {
    this.rangeManagementModal.show(range);
  }

  showTransformToSelectorRangeModal(range: Range) {
    this.transformToSelectorRangeModal.show(range);
  }

  showTransformToImportedRangeModal(rangeId: string) {
    this.rangeToAction = rangeId;
    this.transformToImportedRangeModal.show();
  }

  showPublishRangeModal(rangeId: string) {
    this.rangeToAction = rangeId;
    this.publishRangeModal.show();
  }

  decommissionRange(rangeId: string) {
    this.onDecommissionRange.emit(rangeId);
  }

  onTransformRangeImported(rangeId: string) {
    this.onTransformImportedRange.emit(rangeId);
  }

  publishRange(rangeId: string) {
    this.onPublishRange.emit(rangeId);
  }

  onSaveRangeInfo(rangeInfo: EditRangeInfoPayload) {
    this.saveRangeInfo.emit(rangeInfo);
  }

  onTransformRangeSelector(transformRange: TransformRangeInfoPayload) {
    this.transformRangeInfo.emit(transformRange);
  }

  getTitleTooltip() {
    if (this.showImportExportButtons) {
      return this.translateService.instant('T_OFFER_DATA_MANAGEMENT_IMPORT_PACKAGE_TOOLTIP');
    }
    if (this.titleHeader === 'T_MY_CURRENT_RANGES') {
      return this.translateService.instant('T_OFFER_STATUS_CONFIGURED_HELP_MESSAGE')
        + '. ' + this.translateService.instant('T_OFFER_STATUS_TEST_HELP_MESSAGE')
        + '. ' + this.translateService.instant('T_OFFER_STATUS_PUBLISHED_HELP_MESSAGE')
        + '. ';
    }
    return null;
  }

  getTranslation(key: string) {
    return this.translateService.instant(key);
  }
}
