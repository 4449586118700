
<app-spinner *ngIf="rangeLoading" [sizeClass]="'large'"><span class="waiting-message" translate>T_OFFER_DATA_MANAGEMENT_LOADING_TIME</span></app-spinner>
<!-- List of ranges cards -->
<app-odm-offer-selection *ngIf="!selectedRange"
     [ranges]="ranges"
     [availableRanges]="availableRanges"
     [loadingImportExport]="loadingImportExport"
     [odmLoading]="odmLoading"
     [bslLoading]="bslLoading"
     (clickOnComponentManagement)="goToComponentManagement($event)"
     (clickOnRangeAddition)="goToRangeConfiguration($event)"
     (clickOnAccessoriesManagement)="goToAccessoriesManagement($event)"
     (clickOnOptionsManagement)="goToOptionsManagement($event)"
     (onChangeRangeOrder)="updateRangeOrder($event)"
     (onDecommissionRange)="decommissionRange($event)"
     (onClickRangeImport)="rangeImport($event)"
     (onClickRangeExport)="rangeExport($event)"
     (saveRangeInfo)="saveRangeInfo($event)"
     (transformRangeInfo)="transformRangeSelector($event)"
     (onTransformImportedRange)="transformRangeImported($event)"
     (onPublishRange)="publishRange($event)">
</app-odm-offer-selection>

<!-- List of references -->
<app-offer-data-management-list-references *ngIf="openReferenceList()"
                                           [selectedRange]="selectedRange"
                                           (saveRangeInfo)="reloadRanges()"
                                           (clickBack)="returnOnOfferSelection()">
</app-offer-data-management-list-references>

<!-- List of accessories -->
<app-odm-manage-accessories *ngIf="selectedRange && currentPage === 'AccessoriesManagement'"
                            [selectedRange]="selectedRange"
                            (clickBack)="returnOnOfferSelection()">
</app-odm-manage-accessories>

<!-- List of accessories -->
<app-odm-manage-options *ngIf="selectedRange && currentPage === 'OptionsManagement'"
                            [selectedRange]="selectedRange"
                            [isOption]="true"
                            (clickBack)="returnOnOfferSelection()">
</app-odm-manage-options>

<!--Add offer-->
<app-odm-add-offer *ngIf="openAddOffer()"
                                     [selectedRange]="selectedRange"
                                     (clickBackFromConf)="returnOnOfferSelection()"
                                     (rangeIsPublished)="updateCurrentRange($event)">
</app-odm-add-offer>
<!-- Confirm dialog -->
<app-common-modal #addConfigurationModal [title]="'T_OFFER_DATA_MANAGEMENT_ADD_CONF_TITLE' | translate" [name]="'T_OFFER_DATA_MANAGEMENT_ADD_CONF_TITLE'"
                  [description]="'T_OFFER_DATA_MANAGEMENT_ADD_CONF_MESSAGE' | translate" (onClickYesButton)="configurationSteps()" [size]="'modal-default'">
</app-common-modal>


