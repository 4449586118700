<div>
  <div class="reference-title">
    <h4>{{ reference }}</h4>
    <button (click)="onClickOpenReferenceButton()"
            class="se-icon reference-open-button">{{ isExpanded ? "arrow2_down" : "arrow2_right" }}
    </button>
  </div>


  <div cdkDropList *ngIf="isExpanded" (cdkDropListDropped)="onDragAndDropOptions($event,options)">
    <div cdkDrag *ngFor="let option of options">
      <app-odm-manage-option
        [option]="option"
        [showOnPopUpStatus]="showOnPopUpStatus"
        [isExpanded]="isExpanded && (option.name === activeOption)"
        (clickEditOptionButton)="onClickEditOptionButton($event)"
        (clickCopyOptionButton)="onClickCopyOptionButton($event)"
        (clickDeleteOptionButton)="onClickDeleteOptionButton($event)"
        (clickEditOptionValueButton)="onClickEditOptionValueButton($event)"
        (clickDeleteOptionValueButton)="onClickDeleteOptionValueButton($event)"
      >
      </app-odm-manage-option>
    </div>
  </div>

</div>
