import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SessionStorage} from 'ngx-webstorage';
import {Item} from '../../project/shared/project-model';
import {TreeNode} from "primeng/api";
import {UtilService} from '../../shared/util/util.service';
import {RangeType} from '../../shared/model/range-type';
import {MySEStatusEnum} from '../../shared/model/mySEStatusEnum';
import {ComponentType} from '../../configuration/shared/model/component-type';
import * as _ from 'lodash';
import {LoggerService} from '../../shared/logging/logger.service';
import {DeliveryPolicy} from '../../shared/model/deliveryPolicy';
import {ReferenceBom} from './bom-model';
import {BomService} from './bom.service';
import {SwitchBoardComponent} from '../../configuration/shared/model/component';
import {SimplifiedRange} from '../../shared/model/simplifiedRange-model';
import {IProduct} from '../../configuration/shared/model/IProduct';
import {OfferService} from '../../offers/shared/offer.service';
import {Range} from '../../shared/model/range-model';

@Injectable()
export class BomTreeDataHelper {

  @SessionStorage()
  localization;

  constructor(private bomService: BomService,
              private logger: LoggerService,
              private offerService: OfferService,
              private translateService: TranslateService) {
  }

  private static createTreeNode(category): TreeNode {
    return {
      data: {category: category, totalPrice: null, totalNetPrice: null, discountedPrice: null, discountedNetPrice: null, mySEStatus: MySEStatusEnum.NOT_APPLICABLE},
      children: []
    };
  }

  /**
   * Create a TreeNode on BOM from the item
   * @param item the itemCT
   * @param ranges list of known ranges for offer
   */
  extractReferencesToTree(item: Item, ranges: Range[]): TreeNode[] {
    this.logger.info('Bom extractReferencesToTree()');
    const myItem = UtilService.clone(item);
    const references: TreeNode[] = [];

    if (!!myItem.range && myItem.range.rangeType === RangeType.TRANSFORMER && myItem.components && myItem.components.length > 0) {
      const transformerNode: TreeNode = BomTreeDataHelper.createTreeNode(this.translateService.instant('T_BOM_TRANSFORMER'));
      const quantity =  myItem.components[0].quantity ? myItem.components[0].quantity : 1;
      references.push(transformerNode);
      this.extractReferenceToTreeNode(transformerNode, myItem.components[0], item.discount, myItem.deliveryPolicy, item, ranges);
      this.setExtractPrices(transformerNode, myItem.components[0], quantity, item.discount);

      // Add tree node for transformer options
      const options: TreeNode = BomTreeDataHelper.createTreeNode(this.translateService.instant('T_BOM_TRANSFORMER_OPTIONS'));
      transformerNode.children.push(options);

      this.extractOptionsToTreeNode(myItem.deliveryPolicy, transformerNode, options, myItem.components[0], item, ranges);
      // If we have no tranfo options, we remove the node
      if (options.children.length === 0) {
        const index = transformerNode.children.findIndex(elem => elem.data.category === options.data.category);
        transformerNode.children.splice(index, 1);
      }

    } else if (!!myItem.packageOfferId || myItem.range.rangeType === RangeType.SWITCHBOARD || myItem.range.rangeType === RangeType.BUDJETARY_QUOTE) {
      let componentIdx = 0;
      myItem.components.forEach(component => {
        componentIdx++;
        const quantity = component.quantity ? component.quantity : 1;
        const cubicleNode: TreeNode = BomTreeDataHelper.createTreeNode(this.getTitleItemBom(myItem.range, component, componentIdx));
        references.push(cubicleNode);

        this.extractReferenceToTreeNode(cubicleNode, component, item.discount, myItem.deliveryPolicy, item, ranges);
        this.setExtractPrices(cubicleNode, component, quantity, item.discount);

        const options: TreeNode = BomTreeDataHelper.createTreeNode(this.translateService.instant('T_BOM_CUBICLE_OPTIONS'));
        cubicleNode.children.push(options);
        if (component.type === ComponentType.CUBICLE) {
          component.functionalUnits.forEach(functionalUnit => {
              const optionFunction: TreeNode = BomTreeDataHelper.createTreeNode(
                'Function ' + this.translateService.instant(functionalUnit['name'])
              );
              cubicleNode.children.push(optionFunction);
              if (functionalUnit.transformer) {
                const transformerNode: TreeNode = BomTreeDataHelper.createTreeNode(
                  this.translateService.instant('T_BOM_TRANSFORMER') + ' ' +
                  this.translateService.instant(functionalUnit.transformer.name));
                this.extractReferenceToTreeNode(transformerNode, functionalUnit.transformer,
                  item.discount, myItem.deliveryPolicy, item, ranges);
                references.push(transformerNode);
                const transformerQuantity = functionalUnit.transformer.quantity ? functionalUnit.transformer.quantity : 1;

                this.setExtractPrices(transformerNode, functionalUnit.transformer, transformerQuantity, item.discount);

                // Add tree node for transformer options
                const transformerOptions: TreeNode = BomTreeDataHelper.createTreeNode(
                  this.translateService.instant('T_BOM_TRANSFORMER_OPTIONS'));
                transformerNode.children.push(transformerOptions);

                this.extractOptionsToTreeNode(myItem.deliveryPolicy, transformerNode, transformerOptions, functionalUnit.transformer, item, ranges);
                // If we have no tranfo options, we remove the node
                if (transformerOptions.children.length === 0) {
                  const index = transformerNode.children.findIndex(elem => elem.data.category === transformerOptions.data.category);
                  transformerNode.children.splice(index, 1);
                }
              }

              if (!optionFunction.children || optionFunction.children.length === 0) {
                cubicleNode.children.pop();
              } else {
                if(optionFunction.data.totalPrice != null){
                  cubicleNode.data.totalPrice += optionFunction.data.totalPrice;
                }
                if(optionFunction.data.totalNetPrice != null) {
                  cubicleNode.data.totalNetPrice += optionFunction.data.totalNetPrice;
                }
                this.setExtractDiscountedPrices(cubicleNode, item.discount);
              }
            }
          );
        }

        this.extractOptionsToTreeNode(myItem.deliveryPolicy, cubicleNode, options, component, item, ranges);

        if (options.children.length === 0) {
          const index = cubicleNode.children.findIndex(elem => elem.data.category === options.data.category);
          cubicleNode.children.splice(index, 1);
        }
      });
    }

    const accessories: TreeNode = BomTreeDataHelper.createTreeNode(this.translateService.instant('T_BOM_ACCESSORIES'));
    references.push(accessories);

    let mySENetStatusGlobalAccessories = MySEStatusEnum.NOT_APPLICABLE;
    myItem.accessories.forEach(accessory => {
      this.extractReferenceToTreeNode(accessories, accessory, item.discount, myItem.deliveryPolicy, item, ranges);
      this.setExtractPrices(accessories, accessory, accessory.quantity, item.discount, accessories.data.totalPrice, accessories.data.totalNetPrice);

      if (!UtilService.isValidMySEPriceFromStatus(accessories.data.mySeNetPriceStatus)) {
        mySENetStatusGlobalAccessories = accessories.data.mySeNetPriceStatus;
      }
      accessories.data.mySeNetPriceStatus = mySENetStatusGlobalAccessories;
    });

    if (accessories.children.length === 0) {
      const index = references.findIndex(elem => elem.data.category === accessories.data.category);
      references.splice(index, 1);
    }

    if (item.selectedServices.length > 0) {
      const csNode: TreeNode = BomTreeDataHelper.createTreeNode(this.translateService.instant('T_BOM_SERVICES'));
      references.push(csNode);
      item.selectedServices.forEach(servRef => {
        const extractedRef: ReferenceBom = new ReferenceBom();
        extractedRef.ref = servRef.reference.ref;
        extractedRef.count = servRef.quantity;
        extractedRef.deliveryTime = servRef.deliveryTime;
        extractedRef.description = this.translateService.instant(servRef.reference.description);
        extractedRef.price = servRef.reference.price;
        extractedRef.netPrice = servRef.reference.netPrice;
        extractedRef.totalPrice = servRef.reference.price  * servRef.quantity;
        extractedRef.totalNetPrice = servRef.reference.netPrice * servRef.quantity;
        extractedRef.discount = item.discount;
        extractedRef.mySeNetPriceStatus = servRef.reference.mySeNetPriceStatus;
        const discountUnitPrice = UtilService.applyDiscountPrice(servRef.reference.price, extractedRef.discount);
        extractedRef.discountedPrice = discountUnitPrice * extractedRef.count;
        const discountUnitNetPrice = UtilService.applyDiscountPrice(servRef.reference.netPrice, extractedRef.discount);
        extractedRef.discountedNetPrice = discountUnitNetPrice * extractedRef.count;
        extractedRef.discountUnitPrice = discountUnitPrice;

        const child = {data: extractedRef, children: []};
        csNode.children.push(child);

        csNode.data.totalPrice += extractedRef.ref ? extractedRef.price * extractedRef.count : null;
        csNode.data.totalNetPrice += extractedRef.ref ? extractedRef.netPrice * extractedRef.count : null;
        this.setExtractDiscountedPrices(csNode, item.discount);

        csNode.data.mySeNetPriceStatus = extractedRef.mySeNetPriceStatus;
      });
    }
    return references;
  }

  /**
   *  Method  to get the title of a BOM item
   * @param range of item
   * @param component one of component of the item
   * @param index
   */
  private getTitleItemBom(range: SimplifiedRange, component: SwitchBoardComponent, index: number): string {
    if (!!range && range.switchboardLimit.max === 1) {
      return this.translateService.instant(range.nameKey);
    } else {
      return index + ' - ' + this.translateService.instant(component.name);
    }
  }
  /**
   * Transfer references to TreeNode
   * @param references
   * @param itemValue
   * @param discount
   * @param deliveryPolicy
   * @param item
   * @param ranges
   */
  private extractReferenceToTreeNode(references: TreeNode, itemValue, discount: number, deliveryPolicy: DeliveryPolicy, item: Item, ranges: Range[]) {
    let extractedRef: ReferenceBom;
    if (itemValue.rangeId !== undefined) {
      this.createNode(references, itemValue, discount, item, ranges);
    } else if (itemValue.reference) {
      // For cubicles options and accessories
      extractedRef = BomService.convertReferenceToReferenceBom(this.translateService, itemValue.reference, itemValue, discount, item.range, itemValue.quantity);
      const child = {data: extractedRef, children: []};
      references.children.push(child);
    } else if (itemValue.referenceBom) {
      extractedRef = this.bomService.extractReference(itemValue, discount, deliveryPolicy);
      const child = {data: extractedRef, children: []};
      references.children.push(child);
    }
  }

  /**
   * Allow to display options of a cubicle or transformer component on the BOM array
   * @param {DeliveryPolicy} deliveryPolicy
   * @param {TreeNode} parentNode represents cubicle or transformer
   * @param {TreeNode} options list of component options
   * @param {SwitchBoardComponent} component cubicle or transformer
   * @param {Item} item
   * @param ranges list of known range
   */
  private extractOptionsToTreeNode(deliveryPolicy: DeliveryPolicy, parentNode: TreeNode, options: TreeNode,
                                   component: SwitchBoardComponent,
                                   item: Item,
                                   ranges: Range[]) {
    component.options.forEach(option => {
      option.values.forEach(
        optValue => {
          // copy original value to not impact DB store item
          const copyOptValue = { ...optValue };
          if (copyOptValue.selected && copyOptValue.reference) {
            if (!copyOptValue.quantity) {
              copyOptValue.quantity = component.quantity;
            } else {
              copyOptValue.quantity = component.quantity * copyOptValue.quantity;
            }
            this.extractReferenceToTreeNode(options, copyOptValue, item.discount, deliveryPolicy, item, ranges);
            // Report quantity from the component to calculated the BOM
            this.setExtractPrices(options, copyOptValue, copyOptValue.quantity, item.discount, options.data.totalPrice, options.data.totalNetPrice);
            this.setExtractPrices(parentNode, copyOptValue, copyOptValue.quantity, item.discount, parentNode.data.totalPrice, parentNode.data.totalNetPrice, false);
            // For parent node, we update status just if it is an invalid status
            if (!UtilService.isValidMySEPriceFromStatus(options.data.mySeNetPriceStatus)) {
              parentNode.data.mySeNetPriceStatus = options.data.mySeNetPriceStatus;
            }
          }
        }
      );
    });
  }

  /**
   * Create a node ( a row and bom array )
   * @param {TreeNode} racine the first node with only the name of the component or product
   * @param product
   * @param {number} discount
   * @param {Item} item
   * @param ranges list of known range
   */
  private createNode (racine: TreeNode, product: IProduct, discount: number, item: Item, ranges: Range[]) {
    let extractedRef: ReferenceBom;
    if (item.range === null && item.selectedPackage) {
      let range;
      let switchBoardComponent = item.selectedPackage.switchboardComponents.find(value => value.rangeId !== null && value.rangeId !== undefined);
      if (switchBoardComponent !== null && switchBoardComponent !== undefined) {
         range = ranges.find(r => r.id.toString() === switchBoardComponent.rangeId[0]);
      }
      if (range !== null && range !== undefined) {
        item.range= range;
      }

    }
    extractedRef = BomService.convertComponentToReferenceBom(this.translateService, product, discount, item);
    const child = {data: extractedRef, children: []};
    racine.children.push(child);
  }

  /**
   * Set prices: total, net, discounted, discounted net and mySE status to node
   * @param transformerNode the node where we set prices
   * @param component component containing reference
   * @param quantity
   * @param discount
   * @param previousTotalPrice
   * @param previousNetPrice
   * @param updateMyseStatus
   */
  private setExtractPrices(node: TreeNode, component: any, quantity: number, discount: number, previousTotalPrice?: number, previousNetPrice?: number,
                           updateMyseStatus: boolean = true) {
    if(previousTotalPrice != undefined){
      node.data.totalPrice = previousTotalPrice;
    }
    if(previousNetPrice != undefined){
      node.data.totalNetPrice = previousNetPrice;
    }
    const reference = component.reference ? component.reference : component.referenceBom;
    if(reference && reference.price != null && node.data.totalPrice != 'containsNullPrices'){
      node.data.totalPrice += reference.price * quantity;
    }else if (reference && reference.price == null){
      node.data.totalPrice = 'containsNullPrices';
    }
    if(reference && reference.netPrice != null && node.data.totalNetPrice != 'containsNullPrices'){
      node.data.totalNetPrice += reference.netPrice * quantity;
    }else if (reference && reference.netPrice == null){
      node.data.totalNetPrice = 'containsNullPrices';
    }

    this.setExtractDiscountedPrices(node, discount);
    if (updateMyseStatus) {
      this.setExtractMyseStatus(node, component);
    }
  }

  /**
   * Set discounted prices and discounted net prices to node
   * @param node
   * @param discount
   */
  private setExtractDiscountedPrices(node: TreeNode, discount: number) {
    node.data.discountedPrice = UtilService.applyDiscountPrice(node.data.totalPrice, discount);
    node.data.discountedNetPrice = UtilService.applyDiscountPrice(node.data.totalNetPrice, discount);
  }

  /**
   * Set mySE status to node
   * @param node
   * @param component
   */
  private setExtractMyseStatus(node: TreeNode, component: any) {
    node.data.mySeNetPriceStatus = UtilService.getMySEStatusFromObjectWithReference(component);
  }
}
