<section class="container-fluid">
  <div class="odm-left-column scrollable scrollable-max-height">
    <app-odm-characteristics [bslCharacteristicsMap]="bslCharacteristicsMap"
                             [maxNumberOfChoices]="maxNumberOfChoices"
                             [tooltipMessage]="'T_OFFER_DATA_MANAGEMENT_CHARACTERISTIC_DISABLED_TOOLTIP'"
                             [displayProperty]="'name'"
                             (onSelectionChange)="chosenCategoriesChanged($event)">
    </app-odm-characteristics>
  </div>
  <div class="odm-right-column">
    <div class="right-column-title"><span translate>T_OFFER_DATA_MANAGEMENT_SELECT_FILTERS_PREVIEW</span></div>
    <div class="right-column-detail">
      <div class="right-column-label"><span translate>T_OFFER_DATA_MANAGEMENT_MAIN_CHARACTERISTICS_PREVIEW</span></div>
      <!-- [ngClass] used instead of *ngIf as if causes visual glitch -->
      <div class="app-card-preview-container" [ngClass]="{'hidden': isNothingSelected()}">
        <div class="app-card-space"></div>
        <div class="app-card">
          <div class="app-card-overlay"></div>
          <app-card [component]="component"
                    [addingTransformerInfos]="addingTransformerInfos"
                    (selectedCard)="mockedFunction()"
                    (addComponentEvent)="mockedFunction()"></app-card>
        </div>
        <div class="app-card-space"></div>
      </div>
    </div>
    <p class="app-card-info-center" *ngIf="isNothingSelected()" translate>T_OFFER_DATA_MANAGEMENT_MAIN_CHARACTERISTICS_SELECTION_INFO</p>
  </div>
</section>
