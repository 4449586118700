/* angular modules */
import {Injectable} from '@angular/core';
import 'rxjs/add/operator/zip';
/* ngx modules */
import {SessionStorageService} from 'ngx-webstorage';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs/Observable';
/* app modules */
import {UserService} from '../user/user.service';
import {PartnerService} from '../partner/partner.service';
import {RightsService} from '../rights/rights.service';
import {LoggerService} from '../logging/logger.service';
import {LoginEventDatalayerService} from '../google-analytics/login-event-datalayer.service';


@Injectable()
export class ApplicationService {

    constructor(private sessionStorageService: SessionStorageService,
                private translateService: TranslateService,
                private logger: LoggerService,
                private userService: UserService,
                private rightsService: RightsService,
                private partnerService: PartnerService,
                private loginEventDatalayerService: LoginEventDatalayerService) {
    }

    init(loadingDone: Function) {
        this.logger.debug('ApplicationService init()');
        return Observable.zip(this.userService.getUser(), this.userService.getLocalization(), this.partnerService.getPartner(),
            (user, localization, partner) => {
                // Set user preferred language if this language is used for the current user's localisation
                let myLang = localization.langs[0].language;
                localization.langs.forEach(lang => {
                    if (user.preferredLanguage === lang.language) {
                        myLang = lang.language;
                    }
                });
                user.preferredLanguage = myLang;
                // user
                this.sessionStorageService.store('user', user);
                // this language will be used as a fallback when a translation isn't found in the current language
                this.translateService.setDefaultLang('en');

                // localization : languages, currency symbol, etc...
                this.sessionStorageService.store('localization', localization);

                // partner
                this.sessionStorageService.store('partner', partner);
                this.rightsService.addRight(user.role);


                // the lang to use, if the lang isn't available, it will use the current loader to get them
                this.translateService.use(user.preferredLanguage).subscribe(() => {
                    // loading done
                    loadingDone();
                });
                this.loginEventDatalayerService.LoginEvent(user);
                // manage demo mode
                if (!this.rightsService.isActivatedDemo() || !this.rightsService.isDeactivatedDemo()) {
                    this.sessionStorageService.store('isDemoMode', false);
                }
            }
        );
    }
}
